import * as React from "react";
import {View} from "../controls/View";
import {RootStore} from "../../stores/RootStore";

export namespace RegistrationCompleteView {
    export interface Props {
        active: boolean;
        store: RootStore;
    }
    export interface State {

    }
}

export class RegistrationCompleteView extends React.Component<RegistrationCompleteView.Props, RegistrationCompleteView.State> {

    constructor(props: RegistrationCompleteView.Props) {
        super(props);
    }

    render() {
        return (
            <View className="clesy-registration-complete-view" active={this.props.active}>

            </View>
        );
    }
}
