import * as React from "react";

export class ItemTitle extends React.Component<{}, {}> {
    render() {
        return (
            <div className="title">
                {this.props.children}
            </div>
        );
    }
}