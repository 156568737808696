import {computed, reaction} from "mobx";
import {observer} from "mobx-react";
import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Order} from "../../model/Order";
import {RootStore} from "../../stores/RootStore";
import {StorageUtil} from "../../util/StorageUtil";
import {StringUtil} from "../../util/StringUtil";
import {Button} from "../controls/Button";
import {Card, CardActions, CardContent} from "../controls/Card";
import {Spinner} from "../controls/Spinner";
import {View, ViewContent, ViewHeader, ViewMain} from "../controls/View";
import {NotificationDialog} from "../dialogs/NotificationDialog";
import {SuitBagDialog} from "../dialogs/SuitBagDialog";
import {OrderPinCardContent, OrderStatusCardHeader} from "./OrderCommon";

interface OrderListProps {
    onCreateOrder: () => void;
    onPayOrder: (order: Order) => void;
    onOpenOrder: (order: Order) => void;
    active: boolean;
    store: RootStore;
    intl:any;
}

interface OrderListState {
    notificationsActive: boolean;
    suitBagDialogActive?: boolean;
}

@observer
export class OrderList extends React.Component<OrderListProps, OrderListState> {

    constructor(props: OrderListProps) {
        super(props);
        this.state = {
            notificationsActive: true,
        };

        reaction(() => this.needsSuitBagOrder, needsSuitBagOrder => {
            if (needsSuitBagOrder && this.state.suitBagDialogActive === undefined) {
                this.setState({ suitBagDialogActive: true });
            }
        });
    }

    private static readonly READ_NOTIFICATION_KEY = "READ_NOTIFICATIONS";

    private getReadNotifications(): number[] {
        return StorageUtil.readArray(OrderList.READ_NOTIFICATION_KEY) || [];
    }

    @computed
    get unreadNotifications() {
        const currentNotifications = this.props.store.cart?.deliveryDates?.value?.notifications || [];
        const read = this.getReadNotifications();
        return currentNotifications.filter(n => read.indexOf(n.id) === -1);
    }

    @computed
    get needsSuitBagOrder() {
        return !!this.props.store.cart.items.filter(item => item.item.minQuantity > 0).pop();
    }

    private handleNotificationsClose = () => {
        StorageUtil.writeArray(OrderList.READ_NOTIFICATION_KEY, [
            ...this.getReadNotifications(),
            ...this.unreadNotifications.map(n => n.id)
        ]);
        this.setState({
            notificationsActive: false
        });
    };

    render() {
        const {intl, store, active, onCreateOrder, onPayOrder, onOpenOrder} = this.props;
        const {suitBagDialogActive, notificationsActive} = this.state;
        const unreadNotifications = this.unreadNotifications;
        const orders = store.orderList.orders;
        const state = store.orderList.fetchState;
        return (
            <View className="clesy-order-list" active={active}>
                <ViewMain>
                    <ViewHeader title={intl.formatMessage({id:"orderList.title"})}>
                        <Button icon="add" primary onClick={onCreateOrder}>
                            <FormattedMessage id="orderList.buttonAdd"/>
                        </Button>
                    </ViewHeader>
                    <ViewContent>
                        { orders.length > 0 && (
                            <div className="orders">
                                { orders.map(order => (
                                    <OrderCard key={order.orderId}
                                               order={order}
                                               onPayOrder={onPayOrder}
                                               onOpenOrder={onOpenOrder} />
                                    ))}
                            </div>
                        )}
                        { state && state.pending && (
                            <Spinner/>
                        )}
                        { state && state.fulfilled && orders.length == 0 && (
                            <div className="no-orders">
                                <FormattedMessage id="orderList.noOrdersDiv"/> <a onClick={onCreateOrder}><FormattedMessage id="orderList.orderCreateLink"/></a>
                            </div>
                        )}
                    </ViewContent>
                </ViewMain>
                {
                    unreadNotifications.length > 0 &&
                    <NotificationDialog active={notificationsActive}
                                        notifications={unreadNotifications}
                                        onClose={this.handleNotificationsClose}/>
                }
                <SuitBagDialog active={suitBagDialogActive} intl={intl} onShowOrderCreate={this.handleSuitBagCreateOrder}/>
            </View>
        );
    }

    private handleSuitBagCreateOrder = () => {
        this.setState({suitBagDialogActive: false});
        this.props.onCreateOrder();
    }
}

interface OrderCardProps {
    order: Order;
    onPayOrder: (order: Order) => void;
    onOpenOrder: (order: Order) => void;
}

@observer
class OrderCard extends React.Component<OrderCardProps> {
    render() {
        const {order} = this.props;
        return (
            <Card className="loaded">
                <OrderStatusCardHeader order={order}/>
                { order.isAwaitingCubeAction() && <OrderPinCardContent order={order}/> }
                <CardContent className="items-and-total">
                    <div className="order-items">
                        {order.items.map((item, index) => <span key={index} dangerouslySetInnerHTML={{ __html: item.quantity + ' ' + item.name }}/>)}
                    </div>
                    <div className="order-total">
                        <div className="label"><FormattedMessage id="orderList.orderTotal"/></div>
                        <div className="value">{StringUtil.formatCurrency(order.total)}</div>
                    </div>
                </CardContent>
                <CardActions>
                    { order.needsPayment() && (
                        <Button primary={true} onClick={this.handlePayOrder}><i>payment</i>
                            <FormattedMessage id="orderList.buttonNeedsPayment"/></Button>
                    )}
                    <Button primary={false} onClick={this.handleOpenOrder}> <FormattedMessage id="orderList.buttonToCreate"/></Button>
                </CardActions>
            </Card>
        )
    }

    private handlePayOrder = () => this.props.onPayOrder(this.props.order);
    private handleOpenOrder = () => this.props.onOpenOrder(this.props.order);
}

export default injectIntl(OrderList);