import * as React from "react";

export interface ClassNameProps {
    className?:string;
}

export class ClassList {
    classList:string[];

    constructor(...classNames:string[]) {
        this.classList = [];
        if (classNames) {
            for (let className of classNames) {
                if (className) {
                    this.classList.push(...className.split(' '));
                }
            }
        }
    }

    public add(className:string) {
        this.classList.push(className);
    }

    public toString():string {
        return this.classList.join(' ');
    }


    public static compute(...classNames:string[]):string {
        return new ClassList(...classNames).toString();
    }
}

export class DivWrapper extends React.Component<ClassNameProps, undefined> {
    render(baseClassName?:string) {
        return DivWrapper.render(new ClassList(baseClassName, this.props.className), this.props.children);
    }

    public static render(classes:ClassList, children:React.ReactNode) {
        return (
            <div className={classes.toString()}>
                {children}
            </div>
        )
    }
}

