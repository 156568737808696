import * as React from "react";
import {View, ViewMain} from "../controls/View";
import {RootStore} from "../../stores/RootStore";
import {Card, CardContent, CardHeader} from "../controls/Card";
import {observer} from "mobx-react";
import {Button} from "../controls/Button";
import {TextField} from "../controls/TextField";
import {action} from "mobx";
import {FormattedMessage, injectIntl} from 'react-intl';


@observer
export class ReferralView extends React.Component<ReferralView.Props, undefined> {

    constructor(props:ReferralView.Props) {
        super(props);
    }

    @action
    private handleEmailChange(e:React.ChangeEvent<HTMLInputElement>, index:number) {
        this.props.store.referral.setEmail(index, e.target.value);
    }

    private handleSubmit(e:React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        this.props.store.referral.submit();
    }

    render() {
        let referral = this.props.store.referral;
        let processing = referral.state && referral.state.pending;

        return (
            <View className="clesy-referral" active={this.props.active}>
                <ViewMain>
                    <div className="content">
                        <Card processing={processing}>
                            <CardHeader>
                                <h2><FormattedMessage id="referralView.cardHead"/></h2>
                            </CardHeader>
                            { this.renderContent() }
                        </Card>
                    </div>
                </ViewMain>
            </View>
        );
    }

    renderContent() {
        let referral = this.props.store.referral;
        let error = referral.state && referral.state.rejected && referral.state.reason;
        let fulfilled = referral.state && referral.state.fulfilled;

        const {intl} = this.props;
        if (fulfilled) {
            return (
                <CardContent>
                    <p><FormattedMessage id="referralView.cardContent"/></p>
                    <p><FormattedMessage id="referralView.cardContentTwo"/></p>
                    <div className="buttons">
                        <Button primary={true} label={intl.formatMessage({id:"referralView.buttonRecommendation"})} onClick={e => referral.clear() } />
                    </div>
                </CardContent>
            );
        }
        else {
            return (
                <CardContent>
                    <form onSubmit={e => this.handleSubmit(e)}>
                        <p>
                            <FormattedMessage id="referralView.cardContentP1"/>
                        </p>
                        <p>
                            <FormattedMessage id="referralView.cardContentP2"/>
                        </p>
                        { [...referral.emails, ''].map((email:string, index:number) =>
                            <TextField type="email" key={index} label={'E-Mail ' + (index+1)} value={email || ''} onChange={e => this.handleEmailChange(e, index)} />
                        )}

                        <p>
                            <FormattedMessage
                                id="referralView.cardContentP3"
                                values={{url:<a href="http://www.clesyclean.com/agbs-kwk.html" target="_blank">
                                    <FormattedMessage id="referralView.agbLink"/></a>}}/>
                        </p>

                        { error && <div className="error-message">{error.toString()}</div> }

                        <div className="buttons">
                            <Button primary={true} label={intl.formatMessage({id:"referralView.buttonSend"})} />
                        </div>
                    </form>
                </CardContent>
            )
        }
    }
}

export namespace ReferralView {
    export interface Props {
        active:boolean;
        store:RootStore;
        intl:any;
    }
}
export default injectIntl(ReferralView)

