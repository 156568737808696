export enum SignupOrder {
    WelcomePackage = "WelcomePackage",
    SuitBag = "SuitBag"
}

export interface Signup {
    firstName: string;
    lastName: string;
    salutation: string;
    title: string;
    street: string;
    houseNo: string;
    zip: string;
    city: string;
    country: string;
    company?: string;
    email: string;
    phone: string;
    password: string;
    passwordConfirm: string;
    promoCode: string;
    acceptTerms?: boolean;
    comment: string;
    siteId: number;
    accountNumber: string;
    accountHolder: string;
    acceptDirectDebit: boolean;
    order?: SignupOrder;
}