import * as React from "react";
import {Item} from "./Item";
import {ItemTitle} from "./ItemTitle";
import * as Model from "../../model/Item";
import {ItemDescription} from "./ItemDescription";
import {ItemPricing, ItemUnit} from "../../model/Item";
import {ClassList} from "./Base";
import {StringUtil} from "../../util/StringUtil";
import {FormattedMessage, injectIntl} from "react-intl"
import {Clickable} from "../controls/Clickable";

export namespace ArticleItem {
    export interface Props {
        article:Model.Item;
        iconRight?:string;
        onClick:(e:React.SyntheticEvent<HTMLElement>) => void;
    }
    export interface State {
    }
}

export class ArticleItem extends React.Component<ArticleItem.Props, ArticleItem.State> {
    render() {
        let item = this.props.article;
        let message = ItemPricing.label(item.type);
        let priceLabel = message !== null ? <FormattedMessage id={message}/> : message;

        return (
            <Item iconRight={' '} className={ClassList.compute('clesy-article-item', ItemPricing.className(item.type))}>
                <div className="content">
                    <ItemTitle><div dangerouslySetInnerHTML={{ __html: item.name }}/></ItemTitle>
                    <ItemDescription>
                        <div className="category">{item.category}</div>
                        <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                    </ItemDescription>
                </div>
                { item.hasPrice && (
                    <div className="price">
                        { priceLabel && <div className="label">{priceLabel}</div> }
                        <div className="amount">{StringUtil.formatCurrency(item.price)}</div>
                        <div className="unit">pro <FormattedMessage id={ItemUnit.format(item.unit)}/></div>
                    </div>
                )}
                <div className="icon-right">
                  <Clickable onClick={e => this.handleClick(item)} key={item.itemId}>
                  <i>{this.props.iconRight}</i>
                </Clickable>
                </div>
            </Item>
        );
    }
    private handleClick(e:any):void {
        this.props.onClick(e);
    }
}
