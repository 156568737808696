import * as React from "react";

export class Logo extends React.Component<{}, {}> {
    render() {
        return (
            <div className="clesy-logo">
                ClesyClean
            </div>
        )
    }
}

