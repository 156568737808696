import {PromiseState, StoreBase} from "og-spa-state";
import {ClesyService} from "../services/ClesyService";
import {computed, observable} from "mobx";

export interface PasswordResetProps {
    username:string;
    token:string;
    newPassword:string;
    newPasswordConfirmation:string;
}

export interface PasswordResetState {
    state:PromiseState<void>;
}

export const Valid = '';

export class PasswordResetStore extends StoreBase<PasswordResetProps, PasswordResetState> {

    constructor(private clesyService:ClesyService) {
        super();
    }

    @observable
    readonly username = '';

    @observable
    readonly token = '';

    @observable
    readonly newPassword = '';

    @observable
    readonly newPasswordConfirmation = '';

    /**
     * Returns a message indicating why the new password is not valid,
     * or an empty string if the password is valid
     * @returns {string} password validity message
     */
    @computed
    get newPasswordConfirmationValidity(): string {
        return (this.newPassword != this.newPasswordConfirmation)
            ? "passwordResetStore.passwordFieldMessage"
            : Valid;
    }

    @observable
    readonly state:PromiseState<void>;

    submit() {
        this.setState({
            state: new PromiseState(this.clesyService.resetPassword(this.username, this.token, this.newPassword))
        });
    }
}