let memoryStorage = require("localStorage");
const storageAvailable = require("storage-available");

function getStorage(): Storage {
    if (storageAvailable("localStorage")) {
        return window.localStorage;
    }
    else if (storageAvailable("sessionStorage")) {
        return window.sessionStorage;
    }
    else {
        return memoryStorage;
    }
}

const storage = getStorage();

export class StorageUtil {

    public static readNumber(key: string) {
        const value = StorageUtil.readJson(key);
        if (typeof value === "number") {
            return value as number;
        }
        return undefined;
    }

    public static writeNumber(key: string, value: number) {
        StorageUtil.writeJson(key, value);
    }

    public static readJson<T = any>(key: string) {
        const serialized = storage.getItem(key);
        if (serialized) {
            try {
                return JSON.parse(serialized) as T;
            }
            catch (error) {
                // ignore JSON parsing errors
            }
        }
        return undefined;
    }

    public static writeJson(key: string, value: any) {
        try {
            storage.setItem(key, JSON.stringify(value));
        }
        catch (error) {
            // ignore error
        }
    }

    public static readArray<T>(key: string): T[] | undefined {
        const value = StorageUtil.readJson(key);
        if (Array.isArray(value)) {
            return value;
        }
        return undefined;
    }

    public static writeArray<T>(key: string, value: T[]) {
        StorageUtil.writeJson(key, value);
    }
}