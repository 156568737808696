import * as React from "react";

export namespace Clickable {
    export interface Props {
        onClick:(e:React.SyntheticEvent<HTMLElement>) => void;
    }

    export interface State {
        mouseDown:boolean,
        mouseUp:boolean,
        rippleProps:React.CSSProperties
    }
}

export class Clickable extends React.Component<Clickable.Props, Clickable.State> {

    constructor(props:Clickable.Props) {
        super(props);

        this.state = {
            mouseDown:false,
            mouseUp:false,
            rippleProps: {
                left:0,
                top:0,
                //width:0,
                //height:0
            }
        };
    }

    private handleClick(e:React.MouseEvent<HTMLDivElement>):void {
        // console.log('handleClick');
        this.props.onClick(e);
    }

    private handleMouseDown(e:React.MouseEvent<HTMLDivElement>):void {
        this.setState({...this.state, mouseDown: true, mouseUp: false, rippleProps: this.buildMouseRippleProps(e)});
    }

    private handleTouchStart(e:React.TouchEvent<HTMLDivElement>):void {
        this.setState({...this.state, mouseDown: true, mouseUp: false, rippleProps: this.buildTouchRippleProps(e)});
    }

    private handleMouseLeave():void {
        this.setState({...this.state, mouseDown: false, mouseUp: false});
    }

    private handleMouseMove(e:React.MouseEvent<HTMLDivElement>):void {
        this.setState({...this.state, rippleProps: this.buildMouseRippleProps(e)});
    }

    private handleTouchMove(e:React.TouchEvent<HTMLDivElement>):void {
        this.setState({...this.state, rippleProps: this.buildTouchRippleProps(e)});
    }

    private handleEnd(e:React.SyntheticEvent<HTMLElement>):void {
        this.setState({...this.state, mouseDown: false, mouseUp: true});
        // disabled custom computation of click event:
        // this.props.onClick(e);
    }

    private buildMouseRippleProps(e:React.MouseEvent<HTMLDivElement>):React.CSSProperties {
        let target = e.currentTarget;
        return {
            left: e.pageX - target.offsetLeft,
            top: e.pageY - target.offsetTop
        };
    }

    private buildTouchRippleProps(e:React.TouchEvent<HTMLDivElement>):React.CSSProperties {
        if (e.targetTouches.length > 0) {
            let touch = e.targetTouches.item(0);
            return {
                left: touch.clientX,
                top: touch.clientY
            }
        }
        return {
            left: null,
            top: null
        };
    }

    render() {

        let classes = ['clesy-clickable'];
        if (this.state.mouseDown) {
            classes.push('mouse-down');
        }
        if (this.state.mouseUp) {
            classes.push('mouse-up');
        }

        return (
            <div className={classes.join(' ')}
                 onClick={e => this.handleClick(e)}
                 onMouseDown={e => this.handleMouseDown(e)}
                 onMouseMove={e => this.handleMouseMove(e)}
                 onMouseLeave={e => this.handleMouseLeave()}
                 onMouseUp={e => this.handleEnd(e)}
                 onTouchStart={e => this.handleTouchStart(e)}
                 onTouchMove={e => this.handleTouchMove(e)}
                 onTouchEnd={e => this.handleEnd(e)}>
                <div className="background" />
                {this.props.children}
                <div className="ripple" style={this.state.rippleProps} />
            </div>
        )
    }
}
