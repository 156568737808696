import * as React from 'react';
import {Field} from "./Field";
import {ClassList} from "./Base";

export namespace CheckboxField {
    export interface Props {
        name?:string;
        className?:string;
        required?:boolean;
        defaultChecked?:boolean;
        onChange?:(e:React.ChangeEvent<HTMLInputElement>) => void;
        onChangeValue?:(name:string, checked:boolean) => void;
        checked?:boolean;
        intl: any;
    }
}


export class CheckboxField extends React.Component<CheckboxField.Props, undefined> {

    private handleChange(e:React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onChangeValue) {
            this.props.onChangeValue(this.props.name, e.target.checked);
        }
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        return <Field className={ClassList.compute('checkbox-field', 'right-label', this.props.className)}>
            <label htmlFor={this.props.name}>
                <input
                    type="checkbox"
                    id={this.props.name}
                    name={this.props.name}
                    defaultChecked={this.props.defaultChecked}
                    onChange={e => this.handleChange(e)}
                    checked={this.props.checked}
                    required={this.props.required}
                />
                {this.props.children}
                {this.props.required && <abbr className="required" title={this.props.intl.formatMessage({id:"checkBoxField.titleRequired"})}>*</abbr>}
            </label>
        </Field>

    }
}