import * as React from 'react';
import {Field} from "./Field";
import {ClassList} from "./Base";
import {bool, func, string} from "prop-types";

export namespace RadioField {
    export interface Props {
        name?:string;
        className?:string;
        required?:boolean;
        onChangeValue?:(name:string, value:string) => void;
    }
    export interface Context {
        name: string;
        required: boolean;
        onChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
    }
}


export class RadioField extends React.Component<RadioField.Props, undefined> {

    public static childContextTypes = {
        name:  string,
        onChange: func,
        required: bool
    };

    render() {
        return (
            <Field className={ClassList.compute('radio-field', 'right-label', this.props.className)}>
                {this.props.children}
            </Field>
        );
    }

    getChildContext():RadioField.Context {
        return {
            name: this.props.name,
            required: this.props.required,
            onChange: (e:React.ChangeEvent<HTMLInputElement>) => this.handleChange(e),
        };
    }

    handleChange(e:React.ChangeEvent<HTMLInputElement>) {
        this.props.onChangeValue(this.props.name, e.target.value);
    }
}

export namespace RadioField {

    export namespace Option {
        export interface Props {
            value:string;
            checked?:boolean;
            defaultChecked?:boolean;
        }
    }

    export class Option extends React.Component<RadioField.Option.Props, undefined> {

        static contextTypes = RadioField.childContextTypes;

        constructor(props:Option.Props, context:any) {
            super(props, context);
        }

        render() {
            let id = (this.context.name || '') + '-' + this.props.value;
            return (
                <label htmlFor={id}>
                    <input type="radio"
                           id={id}
                           name={this.context.name}
                           required={this.context.required}
                           value={this.props.value}
                           checked={this.props.checked}
                           onChange={this.context.onChange}
                           defaultChecked={this.props.defaultChecked} />
                    {this.props.children}
                </label>
            );
        }
    }
}