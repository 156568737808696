import * as React from "react";

interface AlertProps {
    type: "success"|"warning"|"error";
}

export class Alert extends React.Component<AlertProps> {
    render() {
        const {type, children} = this.props;
        return (
            <div className={"clesy-alert-" + type}>
                {children}
            </div>
        )
    }
}