import {action, computed, observable} from "mobx";
import {Item} from "../model/Item";
import {ArticlesResponse} from "../model/transfer"
import {Category} from "../model/Category"
import {DocumentIndex} from "ndx";
import {PromisedValue, StoreBase} from "og-spa-state";
import {Site} from "../model/Site";
import {Account} from "../model/Account";

export interface ArticlesProps {
    filterTerm:string;
}

interface ArticlesState {
    state:PromisedValue<void>;
}

export interface ArticlesService {
    fetchArticles(account:Account, station:Site):Promise<ArticlesResponse>;
}

export class ArticlesStore extends StoreBase<ArticlesProps, ArticlesState> {

    @observable
    private items:Map<string, Item> = new Map<string, Item>();

    @observable
    public categories:Category[] = [];

    private index:DocumentIndex<string, Item>;

    @observable
    readonly filterTerm:string;

    constructor(private service:ArticlesService) {
        super();

        this.index = new DocumentIndex<string, Item>();
        this.index.addField('name', { boost: 10 });
        this.index.addField('category', { boost: 5 });
        this.index.addField('description');
    }

    @action
    public fetch(account:Account, site:Site) {
        if (account && site) {
            let promise = this.service.fetchArticles(account, site)
                .then(items => this.applyItems(items));

            this.setState({
                state: new PromisedValue(promise)
            });
        }
        else {
            this.setState({
                state: null
            });
            this.applyItems({
                categories: [],
                plainItems: []
            });
        }
    }

    @action
    private applyItems(items:ArticlesResponse) {
        const {plainItems, categories} = items;
        let newItems = new Map<string, Item>();
        plainItems.forEach(item => newItems.set(item.itemId.toString(), item));

        let newItemIds = new Set<string>(newItems.keys());
        let oldItemIds = new Set<string>(this.items.keys());

        // removals
        oldItemIds.forEach(itemId => {
            if (!newItemIds.has(itemId)) {
                // remove from index
                this.index.remove(itemId);
                // remove from items
                this.items.delete(itemId);
            }
        });

        // additions
        newItemIds.forEach(itemId => {
            if (!oldItemIds.has(itemId)) {
                let item = newItems.get(itemId);
                // add to index
                this.index.add(itemId, item);
                // add to items
                this.items.set(itemId, item);
            }
        });

        // changes
        oldItemIds.forEach(itemId => {
            if (newItemIds.has(itemId)) {
                let oldItem = this.items.get(itemId);
                let newItem = newItems.get(itemId);
                // assign item values
                oldItem.assign(newItem);
            }
        });

        this.categories = categories.map(c => new Category(c))
    }

    @computed
    public get filtered():Item[] {
        if (this.filterTerm) {
            return this.index.search(this.filterTerm).map(result => this.items.get(result.docId));
        }
        else {
            return Array.from(this.items.values()).sort((a, b) => b.relevance - a.relevance);
        }
    }

    @computed
    public get constraint():Item[] {
        return Array.from(this.items.values()).filter(item => item.minQuantity || item.maxQuantity);
    }
}
