
import * as React from "react";

export namespace List {
    export interface Props {
        className?: string;
    }

    export interface State {
    }
}

export class List extends React.Component<List.Props, List.State> {
    render() {
        return (
            <div className={(this.props.className) ? this.props.className + " clesy-list" : "clesy-list"}>
                {this.props.children}
            </div>
        )
    }
}