import {observer} from "mobx-react";
import * as React from "react";
import {stylesheet} from "typestyle";
import {Notification} from "../../model/DeliveryDates";
import {Button} from "../controls/Button";
import {Dialog, DialogContent, DialogFooter, DialogHeader} from "../controls/Dialog";
import {Item} from "../controls/Item";
import {List} from "../controls/List";
import {FormattedMessage} from "react-intl";

interface NotificationDialogProps {
    active: boolean;
    notifications: Notification[];
    onClose: () => void;
}

@observer
export class NotificationDialog extends React.Component<NotificationDialogProps, {}> {

    render() {
        const {active, onClose, notifications} = this.props;
        return (
            <Dialog active={active}>
                <DialogHeader className={styles.header}>
                    <h2><FormattedMessage id="orderCommon.notification.title"/></h2>
                </DialogHeader>
                <DialogContent>
                    <List>
                        {notifications.map((n, index) => (
                            <Item key={index}>
                                <div>
                                    {n.vacation.start && n.vacation.end && (
                                        <h3>
                                            <FormattedMessage id="orderCreate.vacation" values={{
                                                fromStart: n.vacation.formatStart(),
                                                fromEnd: n.vacation.formatEnd()
                                            }}/>
                                        </h3>
                                    )}
                                    <div dangerouslySetInnerHTML={{__html: n.message}}/>
                                </div>
                            </Item>
                        ))}
                    </List>
                </DialogContent>
                <DialogFooter className={styles.footer}>
                    <Button icon="close" primary onClick={onClose}>
                        <FormattedMessage id="orderCommon.notification.closeBtn"/>
                    </Button>
                </DialogFooter>
            </Dialog>
        );
    }
}

const styles = stylesheet({
    header: {
        padding: "0 1em",
    },
    footer: {
        textAlign: "center",
        padding: "1em",
    }
});
