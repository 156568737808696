import * as React from "react";
import {FormattedMessage} from "react-intl";
import {style} from "typestyle";
import {Account} from "../../model/Account";
import {AccountItem} from "../controls/AccountItem";
import {Button} from "../controls/Button";
import {Clickable} from "../controls/Clickable";
import {Dialog, DialogContent, DialogHeader} from "../controls/Dialog";
import {List} from "../controls/List";

export interface SelectAccountDialogProps {
    active:boolean;
    onSelect:(account:Account) => void;
    onClose:() => void;
    accounts:Account[];
}

export class SelectAccountDialog extends React.Component<SelectAccountDialogProps> {

    render() {
        const {active, onSelect, onClose, accounts} = this.props;
        return (
            <Dialog active={active}>
                <DialogHeader className={style({padding: "1em"})}>
                    <h2><FormattedMessage id="selectAccountDialog.header"/></h2>
                    <Button onClick={onClose}>
                        <FormattedMessage id="selectAccountDialog.closeButton"/>
                    </Button>
                </DialogHeader>
                <DialogContent>
                    <List>
                        {accounts && accounts.map(a => (
                            <Clickable onClick={() => onSelect(a)} key={a.accountId}>
                                <AccountItem account={a} iconRight="chevron_right"/>
                            </Clickable>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        )
    }
}