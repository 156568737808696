import {observer} from "mobx-react";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Person} from "../model/Person";
import {RootStore, View} from "../stores/RootStore";
import {ClassList} from "./controls/Base";
import {Logo} from "./controls/Logo";
import {Nav} from "./controls/Nav";
import {ConfirmEmailView} from "./views/ConfirmEmailView";
import Login from "./views/Login";
import LostPasswordView from "./views/LostPasswordView";
import NoMatch from "./views/NoMatch";
import OrderClearing from "./views/OrderClearing";
import OrderClearingFailure from "./views/OrderClearingFailure";
import OrderCreate from "./views/OrderCreate";
import OrderList from "./views/OrderList";
import {OrderView} from "./views/OrderView";
import {PublicBadgeView} from "./views/PublicBadgeView";
import {UserBadgeView} from "./views/UserBadgeView";
import ReferralView from "./views/ReferralView";
import {RegistrationCompleteView} from "./views/RegistrationCompleteView";
import ResetPasswordView from "./views/ResetPasswordView";
import Settings from "./views/SettingsView";
import SignupView from "./views/SignupView";

interface AppProps {
    store:RootStore;
}

@observer
export class App extends React.Component<AppProps, {}> {

    constructor(props) {
        super(props);
    }

    private handleLogout() {
        this.props.store.auth.logout();
        this.props.store.showIndex();
    }

    render() {
        let store = this.props.store;
        let authenticated = store.auth.authenticated;

        let classList = new ClassList('clesy-app');
        if (store.navOpen) {
            classList.add('nav-open');
        }

        return (
            <div className={classList.toString()}>

                <div className="clesy-app-main" id="clesy-app-main">

                    { authenticated && (
                        <div className="nav-toggle" onClick={e => store.toggleNav()}>
                            <i>menu</i>
                            <div className="clesy-logo">ClesyClean</div>
                        </div>
                    )}

                    <div className="view-container">

                        { authenticated && (
                            <UserBadgeView
                                active={store.view == View.UserBadge}
                                store={store}
                                onCancel={() => store.showOrderList()}
                            />
                          )
                        }
                        { authenticated && (
                            <OrderCreate
                                active={store.view == View.OrderCreate}
                                store={store}
                                onOrderCreated={order => store.showOrder(order.orderId.toFixed(0), null)}
                                onCancel={() => store.showOrderList()}
                            />
                        )}

                        { authenticated && (
                            <OrderList
                                active={store.view == View.OrderList}
                                onCreateOrder={() => store.showOrderCreate()}
                                onPayOrder={order => store.showOrderClearing(order.orderId.toString())}
                                onOpenOrder={order => store.showOrder(order.orderId.toString())}
                                store={store}
                            />
                        )}

                        { authenticated && (
                            <OrderView
                                active={store.view == View.Order}
                                store={store}
                                onPayOrder={order => store.showOrderClearing(order.orderId.toString())}
                                onShowOrder={orderId => store.showOrder(orderId.toString())}
                                onCancel={() => store.showOrderList()}
                            />
                        )}

                        { authenticated && (
                            <OrderClearing
                                active={store.view == View.OrderClearing}
                                store={store}
                                onShowOrders={() => store.showOrderList()}
                                onShowOrder={(orderId) => store.showOrder(orderId.toString())}
                            />
                        )}

                        { authenticated && (
                            <OrderClearingFailure
                                active={store.view == View.OrderClearingFailure}
                                store={store}
                                onPayOrder={order => store.showOrderClearing(order.orderId.toString())}
                                onCancel={() => store.showOrderList()}
                            />
                        )}

                        { authenticated && (
                            <Settings
                                active={store.view == View.Settings}
                                store={store}
                            />
                        )}

                        { authenticated && (
                            <ReferralView
                                active={store.view == View.Referral}
                                store={store}
                            />
                        )}

                        <Login
                            active={store.needsLogin && !authenticated}
                            store={store}
                            onRegister={ e => store.showRegister() }
                        />

                        <SignupView
                            active={store.view == View.Signup}
                            store={store}
                            onCancel={() => store.showIndex()}
                        />

                        <ConfirmEmailView
                            active={store.view == View.ConfirmEmail}
                            store={store}
                        />

                        <RegistrationCompleteView
                            active={store.view == View.SignupComplete}
                            store={store}
                        />

                        <LostPasswordView
                            active={store.view == View.PasswordLost}
                            store={store}
                        />

                        <ResetPasswordView
                            active={store.view == View.PasswordReset}
                            store={store}
                        />

                        {store.view == View.PublicBadge && (
                            <PublicBadgeView store={store} />
                        )}

                        <NoMatch active={store.view == View.NoMatch} />
                    </div>

                </div>

                { authenticated && (
                    <Nav>
                        <Logo />
                        <div className="user">
                            <i>account_circle</i>
                            <div className="label"><FormattedMessage id="app.label"/></div>
                            { this.props.store.auth.authenticated &&
                                <div className="name">
                                    { Person.formatPerson(this.props.store.auth.authenticated.person) }
                                </div>
                            }
                        </div>
                        <div className="nav-items">
                            <Nav.Item current={store.view == View.OrderCreate}><a onClick={e => store.showOrderCreate()}><i>shopping_cart</i> <FormattedMessage id="app.shoppingCart"/></a></Nav.Item>
                            <Nav.Item current={store.view == View.OrderList || store.view == View.Order || store.view == View.OrderClearing}>
                                <a onClick={e => store.showOrderList()}><i>folder</i> <FormattedMessage id="app.orderList"/></a>
                            </Nav.Item>
                            <Nav.Item current={store.view == View.Referral}><a onClick={e => store.showReferral() }><i>card_giftcard</i> <FormattedMessage id="app.giftCard"/></a></Nav.Item>
                            <Nav.Item current={store.view == View.Settings}><a onClick={e => store.showSettings() }><i>settings</i> <FormattedMessage id="app.settings"/></a></Nav.Item>
                            <Nav.Item current={store.view == View.UserBadge}><a onClick={e => store.showPreviewBadge()}><i>contact_phone</i> <FormattedMessage id="app.previewBadge"/></a></Nav.Item>
                            <Nav.Item><a onClick={e => this.handleLogout()}><i>exit_to_app</i> <FormattedMessage id="app.logOut"/></a></Nav.Item>
                        </div>
                        <div className="copyright">
                            <div>&copy; <FormattedMessage id="app.copy"/></div>
                            <div>
                                <a href="https://www.clesyclean.com/kontakt/" target="_blank"><FormattedMessage id="app.linkToContacts"/></a>
                                <a href="https://www.clesyclean.com/agb/" target="_blank"><FormattedMessage id="app.agb"/></a>
                            </div>
                        </div>
                    </Nav>
                )}
            </div>
        );
    }

}
