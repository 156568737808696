import {Address, AddressData} from "./Address";
import {ClearingOptionData} from "./ClearingOptions";
import {Person, PersonData} from "./Person";
import {Site, SiteData} from "./Site";

export interface AccountData {
    accountId: number;
    company: string;
    email: string;
    address: AddressData;
    person: PersonData;
    sites: SiteData[];
    legacySepaDebit?: ClearingOptionData;
    customerReference: string;
    phone: string;
    companyAccount: boolean;
}

export class Account implements AccountData {

    accountId: number;
    company: string;
    email: string;
    address: Address;
    person: Person;
    sites: Site[];
    legacySepaDebit?: ClearingOptionData;
    customerReference: string;
    phone: string;
    companyAccount: boolean;

    constructor(accountData?: AccountData) {
        if (accountData) {
            Object.assign(this, accountData);

            this.person = new Person(accountData.person);
            this.address = new Address(accountData.address);
            this.phone = accountData.phone;
            if (accountData.sites) {
                this.sites = accountData.sites.map(siteData => new Site(siteData));
            }
        }
    }

}
