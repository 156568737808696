import {PersonData} from "../model/Person";
import {StoreBase} from "og-spa-state";
import {observable} from "mobx";

export class PersonStore extends StoreBase<PersonData, {}> {
    @observable
    readonly firstname:string;

    @observable
    readonly lastname:string;

    @observable
    readonly salutation:string;

    @observable
    readonly title:string;

    reset() {
        this.setState({
            firstname:'',
            lastname:'',
            salutation:'',
            title:''
        });
    }
}