

export class StringUtil {

    private static readonly CurrencyNumberFormat = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    public static formatDateShort(timestamp:number) {
        try {
            return new Date(timestamp).toLocaleDateString();
        } catch (e) {
            return null;
        }
    }

    public static formatNumber(value:number):string {
        return StringUtil.CurrencyNumberFormat.format(value);
    }

    public static formatCurrency(value:number):string {
        return '€ ' + StringUtil.formatNumber(value);
    }
}