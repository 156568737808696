import {observer} from "mobx-react";
import {PromisedValue} from "og-spa-state";
import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Account} from "../../model/Account";
import {DeliveryDates} from "../../model/DeliveryDates";
import {ItemUnit} from "../../model/Item";
import {Order} from "../../model/Order";
import {Site} from "../../model/Site";
import {CartItemStore, CartStore, VoucherItem} from "../../stores/CartStore";
import {RootStore} from "../../stores/RootStore";
import {StringUtil} from "../../util/StringUtil";
import {AccountItem} from "../controls/AccountItem";
import {Button} from "../controls/Button";
import {Card, CardContent, CardHeader} from "../controls/Card";
import {CheckboxField} from "../controls/CheckboxField";
import {Clickable} from "../controls/Clickable";
import {Field} from "../controls/Field";
import {FormRow} from "../controls/FormRow";
import {SiteItem} from "../controls/SiteItem";
import {TextareaField} from "../controls/TextareaField";
import {TextField} from "../controls/TextField";
import {View, ViewMain} from "../controls/View";
import {AddArticleDialog} from "../dialogs/AddArticleDialog";
import {SelectAccountDialog} from "../dialogs/SelectAccountDialog";
import {SelectSiteDialog} from "../dialogs/SelectSiteDialog";
import {SuitBagDialog} from "../dialogs/SuitBagDialog";

interface OrderCreateProps {
    active: boolean;
    store: RootStore;
    onOrderCreated: (order: Order) => void;
    onCancel: () => void;
    intl: any;
}

interface OrderCreateState {
    selectAccountActive: boolean;
    selectSiteActive: boolean;
    addArticleActive: boolean;
    suitBagDialogActive?: boolean;
    textError?: string;
}

@observer
export class OrderCreate extends React.Component<OrderCreateProps, OrderCreateState> {

    constructor(props: OrderCreateProps) {
        super(props);

        this.state = {
            selectAccountActive: false,
            selectSiteActive: false,
            addArticleActive: false
        };
    }

    showTextError(text: string) {
        this.setState({...this.state, textError: text});
    }

    setSelectAccountActive(active: boolean) {
        this.setState({...this.state, selectAccountActive: active});
    }

    setSelectSiteActive(active: boolean) {
        this.setState({...this.state, selectSiteActive: active});
    }

    setAddArticleActive(active: boolean) {
        this.setState({...this.state, addArticleActive: active});
    }

    selectSite(site: Site) {
        this.props.store.cart.setProps({site: site});
        this.setSelectSiteActive(false);
    }

    selectAccount(account: Account) {
        this.props.store.cart.setProps({account: account});
        this.setSelectAccountActive(false);
        this.detectDefaultSite();
    }

    detectDefaultSite() {
        const availableSites = this.props.store.cart.availableSites;
        const cart = this.props.store.cart;

        const localStorageSite = parseInt(localStorage["clesy_shop/stores/Store/site"]);
        for (const site of availableSites) {
            if (site.siteId === localStorageSite) {
                cart.setProps({site: site});
                return;
            }
        }

        for (const site of availableSites) {
            if (!site.publicSite) {
                cart.setProps({site: site});
                return;
            }
        }

        const firstSite = availableSites[0];
        cart.setProps({site: firstSite});
    }

    private createOrder() {
        if (this.state.textError !== "") {
            this.showTextError("");
        }
        this.props.store.cart.order()
            .then(orderId => this.checkResponse(orderId))
            .catch(reason => console.log(reason));
    }

    private checkResponse(responseOrder: any) {
        if (typeof responseOrder === "number") {
            this.props.store.showOrder(responseOrder);
        }
        else {
            this.showTextError(responseOrder.message);
        }
    }

    render() {
        const {intl, store} = this.props;
        const {cart} = store;
        const {items, voucherItems, availableVouchers, availableAccounts, availableSites} = cart;
        const hasAvailableVouchers = availableVouchers && availableVouchers.length > 0;
        const cartHasItems = items && items.length > 0;
        const cartHasVouchers = voucherItems && voucherItems.length > 0;
        const cartEmpty = !cartHasItems && !cartHasVouchers;
        return (
            <View className="clesy-order-create" active={this.props.active}>
                <ViewMain>
                    <Card processing={cart.orderState && cart.orderState.pending}>
                        <CardHeader>
                            <h2><FormattedMessage id="orderCreate.h2"/></h2>
                            <Button onClick={this.props.onCancel}><FormattedMessage
                                id="orderCreate.buttonCancel"/></Button>
                        </CardHeader>
                        <CardContent>
                            {availableAccounts && availableAccounts.length > 1 && (
                                <div className="account">
                                    <h3><FormattedMessage id="orderCreate.accountH3"/></h3>
                                    <Field>
                                        <Clickable onClick={() => this.setSelectAccountActive(true)}>
                                            <AccountItem account={cart.account} iconRight="settings"
                                                         labelRight={intl.formatMessage({id: "orderCreate.labelRight"})}/>
                                        </Clickable>
                                    </Field>
                                </div>
                            )}

                            {availableSites && availableSites.length >= 1 && (
                                <div className="site">
                                    <h3><FormattedMessage id="orderCreate.siteH3"/></h3>
                                    <Field>
                                        <Clickable onClick={() => this.setSelectSiteActive(true)}>
                                            <SiteItem site={cart.site} iconRight="settings"
                                                      labelRight={intl.formatMessage({id: "orderCreate.labelRight"})}/>
                                        </Clickable>
                                    </Field>
                                    {cart.deliveryDates && this.renderDeliveryDates(cart.deliveryDates)}
                                </div>
                            )}

                            <h3><FormattedMessage id="orderCreate.articleH3"/></h3>
                            <Field>
                                <div className="cart-contents">
                                    {cartEmpty && this.renderEmptyCart()}
                                    {!cartEmpty && this.renderNonEmptyCart(cart)}
                                </div>
                                <div className="cart-actions">
                                    <Button onClick={e => this.setAddArticleActive(true)} name="add-article"
                                            primary={cart.itemCount == 0}
                                            label={intl.formatMessage({id: "orderCreate.buttonAddArticle"})}
                                            icon="add"/>
                                </div>
                            </Field>

                            {hasAvailableVouchers && (
                                <div>
                                    <h3><FormattedMessage id="orderCreate.voucherH3"/></h3>
                                    <p><FormattedMessage id="orderCreate.voucherP"/></p>
                                    <Field>
                                        <div className="available-vouchers">
                                            {availableVouchers.map(voucher => (
                                                <div className="voucher" key={voucher.code}
                                                     onClick={e => cart.addVoucher(voucher)}>
                                                    <i>card_giftcard</i> {voucher.code} {StringUtil.formatCurrency(voucher.value)}
                                                </div>
                                            ))}
                                        </div>
                                    </Field>
                                </div>
                            )}

                            <FormRow>
                                <TextareaField label={intl.formatMessage({id: "orderCreate.textArea"})} name="comment"
                                               onChangeValue={(name, value) => cart.setProps({comment: value})}/>
                            </FormRow>

                            <FormRow>
                                <TextField label={intl.formatMessage({id: "orderCreate.textField"})}
                                           name="customerOrderId"
                                           onChangeValue={(name, value) => cart.setProps({customerOrderId: value})}/>
                            </FormRow>

                            <FormRow>
                                <CheckboxField
                                    name="termsAndConditionsAccepted"
                                    onChangeValue={(name, value) => cart.setProps({
                                        termsAndConditionsAccepted: value,
                                        sepaAccepted: value
                                    })}
                                    checked={cart.termsAndConditionsAccepted}
                                    intl={intl}
                                    required>

                                    <FormattedMessage
                                        id="orderCreate.terms"
                                        values={{
                                            linkAgb: <a href="https://www.clesyclean.com/agb/" target="_blank">AGBs</a>,
                                            revocation: <a
                                                href="https://www.clesyclean.com/wp-content/uploads/2017/05/widerrufsrecht.pdf"
                                                target="_blank"><FormattedMessage id="orderCreate.linkPdfName"/></a>,
                                            needsSepa: cart.needsSepaAcceptance &&
                                                <FormattedMessage id="orderCreate.needsSepa"/>,
                                            earlyStart: <a
                                                href="https://www.clesyclean.com/wp-content/uploads/2017/05/aufforderung_vorzeitiger_beginn.pdf"
                                                target="_blank">
                                                <FormattedMessage id="orderCreate.linkPdf2Name"/></a>
                                        }}/>
                                </CheckboxField>
                            </FormRow>


                            <div style={{color: "red"}}>
                                <p>{this.state.textError}</p>
                            </div>

                            <div className="submit-button">
                                <Button name="order" primary={cart.itemCount > 0}
                                        label={intl.formatMessage({id: "orderCreate.buttonOrderCreate"})}
                                        onClick={e => this.createOrder()} disabled={!cart.isValid}/>
                            </div>
                        </CardContent>
                    </Card>
                </ViewMain>

                <SelectAccountDialog
                    active={this.state.selectAccountActive}
                    accounts={cart.availableAccounts}
                    onSelect={a => this.selectAccount(a)}
                    onClose={() => this.setSelectAccountActive(false)}/>
                <SelectSiteDialog
                    active={this.state.selectSiteActive}
                    sites={cart.availableSites}
                    onSelect={s => this.selectSite(s)}
                    onClose={() => this.setSelectSiteActive(false)}/>
                <AddArticleDialog
                    active={this.state.addArticleActive}
                    store={store}
                    onAdd={a => cart.addItem(a)}
                    onClose={() => this.setAddArticleActive(false)}/>
                <SuitBagDialog
                    active={this.state.suitBagDialogActive}
                    intl={intl}
                    onShowOrderCreate={this.handleSuitBagDialogClose}/>
            </View>

        );
    }

    private handleSuitBagDialogClose = () => this.setState({suitBagDialogActive: false});

    private renderDeliveryDates(promisedDeliveryDates: PromisedValue<DeliveryDates>) {
        return (
            <div className="delivery-dates">
                {promisedDeliveryDates.pending && <p><FormattedMessage id="orderCreate.pending"/></p>}
                {promisedDeliveryDates.rejected && <p>{promisedDeliveryDates.reason}</p>}
                {promisedDeliveryDates.fulfilled && promisedDeliveryDates.value && (
                    <div className="loaded">
                        <div className="dates">
                            <div className="delivery-date">
                                <div className="label"><FormattedMessage id="orderCreate.dateDeliveryWait"/></div>
                                <div className="value">{promisedDeliveryDates.value.formatExpectedDelivery()}</div>
                            </div>
                            <div className="delivery-date">
                                <div className="label"><FormattedMessage id="orderCreate.dateDeliveryGua"/></div>
                                <div className="value">{promisedDeliveryDates.value.formatGuaranteedDelivery()}</div>
                            </div>
                        </div>
                        <div className="attention">
                            Das garantierte Lieferdatum können wir nur einhalten, wenn sie die Kleidung bis spätestens
                            09:00 an der Servicestation abgeben.
                        </div>
                        <div className="notifications">
                            {promisedDeliveryDates.value.notifications.map((notification, index) => (
                                <div className="notification" key={index}>
                                    {notification.vacation.isValid() &&
                                    <div className="vacation"><FormattedMessage id="orderCreate.vacation" values={{
                                        fromStart: notification.vacation.formatStart(),
                                        fromEnd: notification.vacation.formatEnd()
                                    }}/></div>}
                                    <div dangerouslySetInnerHTML={{__html: notification.message}}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private renderEmptyCart() {
        return (
            <div className="empty-cart">
                <FormattedMessage id="orderCreate.nothingSelectedMessage"/>
            </div>
        );
    }

    private renderNonEmptyCart(cart: CartStore) {
        let hasRemainingVoucher = cart.remainingVoucherTotal > 0;
        return (
            <div className="non-empty-cart">
                <div className="items">
                    {cart.items.map((item, index) => this.renderCartItem(cart, item))}
                    {cart.voucherItems.map((item, index) => this.renderVoucherItem(cart, item))}
                    {hasRemainingVoucher && this.renderRemainingVoucherItem(cart)}
                </div>
                <div className="totals">
                    <div className="total item">
                        <div className="description">
                            <FormattedMessage id="orderCreate.totalItem"/>
                        </div>
                        <div className="line-total">
                            {StringUtil.formatCurrency(cart.total)}
                        </div>
                    </div>
                    <div className="tax item">
                        <div className="description">
                            <FormattedMessage id="orderCreate.taxItem"/>
                        </div>
                        <div className="line-total">
                            {StringUtil.formatCurrency(cart.totalTax)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCartItem(cart: CartStore, item: CartItemStore) {
        return (
            <div className="item" key={item.item.itemId}>

                <div className="description">
                    <div className="name" onClick={!item.canDecrementQuantity ? () => this.setState({suitBagDialogActive: true}) : undefined}>
                        <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                        {" "}
                        {!item.canDecrementQuantity && (
                            <i style={{fontSize: "1.25em", lineHeight: 1}}>info_outline</i>
                        )}
                    </div>
                    <div className="options">
                        {item.item.options.map(option => (
                            <CheckboxField
                                key={option.itemOptionId}
                                intl={this.props.intl}
                                checked={item.isOptionSelected(option)}
                                onChangeValue={(name, value) => item.setOptionSelected(option, value)}>
                                {option.name} (+ {StringUtil.formatCurrency(option.price)})
                            </CheckboxField>
                        ))}
                    </div>
                </div>

                <div className="quantity">
                    <button onClick={e => cart.removeItem(item.item)} disabled={!item.canDecrementQuantity}>
                        <i>remove_circle_outline</i>
                    </button>
                    <span className="amount">{item.quantity} <FormattedMessage id={ItemUnit.format(item.unit)}/></span>
                    <button onClick={e => cart.addItem(item.item)} disabled={!item.canIncrementQuantity}>
                        <i>add_circle_outline</i>
                    </button>
                </div>

                <div className="line-total">
                    {item.hasSubtotal && <span>{StringUtil.formatCurrency(item.subtotal)}</span>}
                </div>
            </div>
        );
    }

    renderVoucherItem(cart: CartStore, item: VoucherItem) {
        return <div className="item voucher" key={item.voucher.code}>

            <div className="description">
                {item.description}: {item.code}
            </div>

            <div className="remove" onClick={e => cart.removeVoucher(item.voucher)}>
                <i>remove_circle_outline</i>
                <span><FormattedMessage id="orderCreate.remove"/></span>
            </div>

            <div className="line-total">- {StringUtil.formatCurrency(item.amount)}</div>
        </div>;
    }

    renderRemainingVoucherItem(cart: CartStore) {
        return <div className="item remaining-voucher" key="remaining-voucher">

            <div className="description">
                <FormattedMessage id="orderCreate.remainingCredit"/>
            </div>

            <div className="quantity">
            </div>

            <div className="line-total">{StringUtil.formatCurrency(cart.remainingVoucherTotal)}</div>
        </div>;
    }
}

export default injectIntl(OrderCreate);
