import * as React from "react";
import {FormattedMessage} from "react-intl";
import {style} from "typestyle";
import {Site} from "../../model/Site";
import {Button} from "../controls/Button";
import {Clickable} from "../controls/Clickable";
import {Dialog, DialogContent, DialogHeader} from "../controls/Dialog";
import {List} from "../controls/List";
import {SiteItem} from "../controls/SiteItem";

    export interface SelectSiteDialogProps {
        active: boolean;
        onSelect: (site: Site) => void;
        onClose: () => void;
        sites?: Site[];
    }

export class SelectSiteDialog extends React.Component<SelectSiteDialogProps> {

    render() {
        const {active, onClose, sites, onSelect} = this.props;
        return (
            <Dialog active={active}>
                <DialogHeader className={style({padding: "1em"})}>
                    <h2><FormattedMessage id="selectSiteDialog.header"/></h2>
                    <Button onClick={onClose}>
                        <FormattedMessage id="selectSiteDialog.closeButton"/>
                    </Button>
                </DialogHeader>
                <DialogContent>
                    <List>
                        {sites && sites.map(s => (
                            <Clickable onClick={() => onSelect(s)} key={s.siteId}>
                                <SiteItem site={s} iconRight="chevron_right"/>
                            </Clickable>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        );
    }
}