import * as moment from "moment";
import {StringUtil} from "../util/StringUtil";

export interface TimeSpanData {
    start:number;
    end:number;
}

export interface NotificationData {
    vacation:TimeSpanData;
    message:string;
}

export interface DeliveryDatesData {
    expectedDelivery:number;
    guaranteedDelivery:number;
    notifications:NotificationData[];
}

export class TimeSpan implements TimeSpanData {

    constructor(data?:TimeSpanData) {
        if (data) {
            Object.assign(this, data);
        }
    }

    start:number;
    end:number;

    isValid() {
        return this.start || this.end;
    }

    formatStart() {
        return StringUtil.formatDateShort(this.start);
    }

    formatEnd() {
        return StringUtil.formatDateShort(this.end);
    }
}

export class Notification implements NotificationData {
    constructor(data?:NotificationData) {
        if (data) {
            Object.assign(this, data);
            this.vacation = new TimeSpan(data.vacation);
        }
    }

    id:number;
    vacation:TimeSpan;
    message:string;
}

export class DeliveryDates implements DeliveryDatesData {
    constructor(data?:DeliveryDatesData) {
        if (data) {
            Object.assign(this, data);

            this.notifications = data.notifications.map(notificationData => new Notification(notificationData));
        }
    }

    expectedDelivery:number;
    guaranteedDelivery:number;
    notifications:Notification[];

    formatExpectedDelivery() {
        return StringUtil.formatDateShort(moment(this.expectedDelivery).toDate().getTime());
    }

    formatGuaranteedDelivery() {
        return StringUtil.formatDateShort(moment(this.guaranteedDelivery).toDate().getTime());
    }
}