
import {PromisedValue, StoreBase} from "og-spa-state";
import {User} from "../model/User";
import {action, computed, observable} from "mobx";
import {Account} from "../model/Account";
import {clesyService} from "../services/ClesyService";

export interface AuthStoreProps {
    username:string;
    password:string;
}

interface AuthStoreState {
    user?:User;
    loginState:PromisedValue<void>;
    logoutState:PromisedValue<void>;
    unauthorized:boolean;
    account:Account;
}

export class AuthStore extends StoreBase<AuthStoreProps, AuthStoreState> {

    constructor() {
        super();

        this.updateUser();

        clesyService.addEventListener("unauthorized", () => {
            this.setState({
                user: undefined,
                unauthorized: true
            });
        })
    }

    @observable
    readonly username:string = '';

    @observable
    readonly password:string = '';

    @observable
    readonly user?:User;

    @observable
    readonly loginState:PromisedValue<User>;

    @observable
    readonly logoutState:PromisedValue<User>;

    @observable
    readonly unauthorized:boolean = false;

    @computed
    get authenticated():User {
        return this.user;
    }

    @computed
    get sessionExpired():boolean {
        const didLogin = this.loginState && this.loginState.fulfilled;
        const didLogout = this.logoutState && this.logoutState.fulfilled;
        return didLogin && !didLogout && this.unauthorized;
    }

    @action
    login() {
        let promise = clesyService.login(this.username, this.password)
            .then(() => this.updateUser())
            .catch(reason => { throw 'Anmeldung fehlgeschlagen' });

        this.setState({
            loginState: new PromisedValue(promise),
            logoutState: null
        });
        this.setProps({
            password:''
        });
    }

    @action
    logout() {
        let promise = clesyService.logout()
            .then(() => this.setState({ user: undefined }));
        this.setState({
            loginState: null,
            logoutState: new PromisedValue(promise)
        });
    }

    @action
    setAccount(account:Account) {
        this.setState({
            account
        });
    }

    public async updateUser() {
        const user = await clesyService.user();
        this.setState({
            user: user,
        });
    }
}