import * as React from "react";
import {Component} from "react";
import {FormattedMessage} from "react-intl";
import {Account} from "../../model/Account";
import {Site} from "../../model/Site";
import {User} from "../../model/User";
import {AccountItem} from "../controls/AccountItem";
import {Card, CardContent, CardHeader} from "../controls/Card";
import {Clickable} from "../controls/Clickable";
import {Field} from "../controls/Field";
import {NameBadge} from "../controls/NameBadge";
import {SiteItem} from "../controls/SiteItem";
import {View, ViewMain} from "../controls/View";
import {SelectAccountDialog} from "../dialogs/SelectAccountDialog";
import {SelectSiteDialog} from "../dialogs/SelectSiteDialog";
import InjectedIntl = ReactIntl.InjectedIntl;

interface BadgeViewProps {
    active: boolean;
    user: User;
    account: Account;
    site: Site;
    availableAccounts: Account[];
    availableSites: Site[];
    onChangeAccount: (account: Account) => void;
    onChangeSite: (site: Site) => void;
    enableFormats?: boolean;
    intl: InjectedIntl;
}

interface BadgeViewState {
    accountSelectionOpen: boolean;
    siteSelectionOpen: boolean;
}

export class BadgeView extends Component<BadgeViewProps, BadgeViewState> {

    constructor(props: BadgeViewProps) {
        super(props);
        this.state = {
            accountSelectionOpen: false,
            siteSelectionOpen: false,
        }
    }

    render() {
        const {active, user, account, site, availableAccounts, availableSites, intl, enableFormats} = this.props;
        const {accountSelectionOpen, siteSelectionOpen} = this.state;

        return (
            <View active={active}>
                <ViewMain>
                    <Card>
                        <CardHeader>
                            <h2><FormattedMessage id="badge.header"/></h2>
                        </CardHeader>
                        <CardContent>
                            {availableAccounts && availableAccounts.length > 1 && (
                                <div>
                                    <h3><FormattedMessage id="orderCreate.accountH3"/></h3>
                                    <Field>
                                        <Clickable onClick={this.handleOpenAccountSelection}>
                                            <AccountItem account={account} iconRight="settings"
                                                         labelRight={intl.formatMessage({id: "orderCreate.labelRight"})}/>
                                        </Clickable>
                                    </Field>
                                </div>
                            )}
                            {availableSites && availableSites.length >= 1 && (
                                <div>
                                    <h3 className='badge-button_subheader'>
                                        <FormattedMessage id="badge.select-site"/>
                                    </h3>
                                    <Field>
                                        <Clickable onClick={this.handleOpenSiteSelection}>
                                            <SiteItem site={site} iconRight="settings"
                                                      labelRight={intl.formatMessage({id: "orderCreate.labelRight"})}/>
                                        </Clickable>
                                    </Field>
                                </div>
                            )}
                            <h3>
                                <FormattedMessage id={"badge.header.preview"}/>
                            </h3>
                            {account && site && user && (
                                <NameBadge user={user} site={site} account={account} enableFormats={enableFormats} intl={intl} />
                            )}
                        </CardContent>
                    </Card>

                </ViewMain>

                <SelectAccountDialog active={accountSelectionOpen}
                                     onSelect={this.handleSelectAccount}
                                     onClose={this.handleCloseAccountSelection}
                                     accounts={availableAccounts}/>

                <SelectSiteDialog
                    active={siteSelectionOpen}
                    sites={availableSites}
                    onSelect={this.handleSelectSite}
                    onClose={this.handleCloseSiteSelection}/>
            </View>
        )
    }

    private handleSelectAccount = (account: Account) => {
        this.props.onChangeAccount(account);
        this.handleCloseAccountSelection();
    };

    private handleSelectSite = (site: Site) => {
        this.props.onChangeSite(site);
        this.handleCloseSiteSelection();
    };

    private handleOpenAccountSelection = () => this.setState({accountSelectionOpen: true});
    private handleOpenSiteSelection = () => this.setState({siteSelectionOpen: true});

    private handleCloseAccountSelection = () => this.setState({ accountSelectionOpen: false });
    private handleCloseSiteSelection = () => this.setState({ siteSelectionOpen: false });
}