import {Account, AccountData} from "./Account";
import {Person, PersonData} from "./Person";
import {Phone} from "./transfer";

export interface UserData {
    userId: number;
    person: PersonData;
    email: string;
    username: string;
    accounts: AccountData[];
    customerReference: string;
    phone: Phone;
}

export class User implements UserData {

    public userId: number;
    public person: Person;
    public email: string;
    public username: string;
    public accounts: Account[];
    public customerReference: string;
    public phone: Phone;

    constructor(user?: UserData) {
        if (user) {
            Object.assign(this, user);
            if (user.accounts) {
                this.accounts = user.accounts.map(accountData => new Account(accountData));
            }
            this.person = new Person(user.person);
            this.phone = user.phone;
        }
    }

    format(): string {
        if (this.person.format) {
            return this.person.format();
        }
        else {
            return "cannot format person!";
        }
    }
}
