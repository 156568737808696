
export interface AddressData {
    street:string;
    houseNo:string;
    zip:string;
    city:string;
    country:string;
}

export class Address implements AddressData {

    constructor(addressData?:AddressData) {
        if (addressData) {
            Object.assign(this, addressData);
        }
    }

    street:string;
    houseNo:string;
    zip:string;
    city:string;
    country:string;

    public format():string[] {
        if (this.street && this.zip && this.city) {
            return [
                this.street + ' ' + this.houseNo,
                this.country + '-' + this.zip + ' ' + this.city
            ];
        }
        else {
            return [];
        }
    }

}