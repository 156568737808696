import * as React from "react";
import {DivWrapper, ClassList} from "./Base";

export class Nav extends React.Component<Nav.Props, Nav.State> {
    render() {
        let classes = new ClassList('clesy-nav');
        if (this.props.active) {
            classes.add('active');
        }
        return (
            <div className={classes.toString()}>
                {this.props.children}
            </div>
        )
    }
}

export namespace Nav {

    export interface Props {
        active?:boolean;
    }

    export interface State {
    }

    export namespace Item {
        export interface Props {
            current?:boolean;
        }
    }

    export class Item extends React.Component<Item.Props, undefined> {
        render() {
            let classes = new ClassList('item');
            if (this.props.current) {
                classes.add('current');
            }
            return DivWrapper.render(classes, this.props.children);
        }
    }
}

