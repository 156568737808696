export default {
    "welcome": "Welcome",
    "login.errorMessage":"Login failed.",
    "login.logoutMessage":"Logout successful.",
    "login.sessionTimeout":"You session has expired.",
    "login.register.text":"Sign-up now!",
    "login.button.submit":"Login",
    "login.button.forgotPassword":"Lost your password?",
    "login.button.register":"Sign-up",
    "login.textField.username":"Username (e-mail)",
    "login.textField.password":"Password",

    "lostPassword.form.h2":"Reset password",
    "lostPassword.successMessage":"We have received your request! You will receive an e-mail with further instructions shortly.",
    "lostPassword.form.p":"Please provide your username or e-mail address.  We will send you an e-mail\n" +
    "with instructions to reset your password.",
    "lostPassword.form.mailLabel":"Username or e-mail",
    "lostPassword.button":"Submit",
    "lostPassword.backButton": "Back",

    "noMatch.code":"Nothing interesting to be found here",
    "noMatch.title":"Sorry nothing found",

    "orderClearing.clearingSuccess.h2":"Thank you very much!",
    "orderClearing.clearingSuccess.p":"Your order was paid.",
    "orderClearing.clearingSuccess.button":"Back to orders",
    "orderClearing.clearingSuccess.cardHeader":"loading...",
    "orderClearing.clearingSuccess.cardHeaderOther":"No order selected",
    "orderClearing.content.h2":"Payment",
    "orderClearing.content.label":"Stored",
    "orderClearing.sepaFields.sepaAccountIbanLabel":"IBAN",
    "orderClearing.sepaFields.sepaAccountBicLabel":"BIC",
    "orderClearing.sepaFields.sepaAccountHolderLabel":"Account-holder",
    "orderClearing.validateCretidCard.demoCard":"Wirecard Demo Card",
    "orderClearing.validateCretidCard.dateFormat":"Unsupported date format",
    "orderClearing.validateCretidCard.dateOk":"Karte abgelaufen",
    "orderClearing.validateCretidCard.creditCardNumber":"Card expired",
    "orderClearing.textFields.creditCardNumber":"Card number",
    "orderClearing.textFields.creditCardHolder":"Card-holder",
    "orderClearing.textFields.creditCardExpiry":"Valid until (MM/JJJJ)",
    "orderClearing.numberState":"Invalid card-number",
    "orderClearing.demoCreditCard":"Wirecard Demo Card",
    "orderClearing.validationFormat":"Invalid format",
    "orderClearing.validationCart":"Card expired",

    "orderClearingFailure.orderStatus.errorMessage":"Payment failed. Please try again later. If the problem\n" +
    "persists please contact clesyclean support.",
    "orderClearingFailure.orderStatus.button":"Try again",
    "orderClearingFailure.orderStatus.a":"View invoice",
    "orderClearingFailure.pendingHeader":"Order loading",
    "orderClearingFailure.fulfilled":"Order {orderNumber}",
    "orderClearingFailure.buttonCancel":"Back to overview",


    "orderCommon.pinLabel":"PIN:",
    "orderCommon.pinInfo":"To deposit your clothes please type the PIN code on the display of your nearest clesyclean service site.",
    "orderCommon.pinInfoOther":"Type the PIN code on the display of your clesyclean service site.\n" +
    "The locker with your clean clothes will automatically open.",
    "orderCommon.newerVersion.message":"Warning: This is not the most recent version of your order.",
    "orderCommon.newerVersion.button":"Open most recent",
    "orderCommon.orderDetails.table.name":"Name",
    "orderCommon.orderDetails.table.quantity":"Quantity",
    "orderCommon.orderDetails.table.total":"Total",
    "orderCommon.orderDetails.table.voucher":"Vouchers",
    "orderCommon.orderDetails.table.sum":"Total:",
    "orderCommon.orderDetails.table.tax":"incl.",
    "orderCommon.orderDetails.table.vat":"VAT:",
    "orderCommon.orderDetails.table.net":"Total excl. VAT:",
    "orderCommon.orderDetails.date":"Expected delivery date",
    "orderCommon.orderDetails.dateTwo":"Guaranteed delivery date",
    "orderCommon.orderDetails.attentionMessage":"The guaranteed delivery date only holds if you deposit your clothes until 09:00 on your clesyclean service site.",
    "orderCommon.orderDetails.customerOrderId":"Order number / sign",
    "orderCommon.orderDetails.supportComment":"Info from clesyclean Support",
    "orderCommon.orderDetails.customerComment":"Your order comments",
    "orderCommon.reOrder":"Replentishment order",
    "orderCommon.notification.title":"Notifications",
    "orderCommon.notification.closeBtn":"Close",

    "orderCreate.h2":"New order",
    "orderCreate.buttonCancel":"Back to overview",
    "orderCreate.labelRight":"Change",
    "orderCreate.accountH3":"Account",
    "orderCreate.siteH3":"Service site",
    "orderCreate.articleH3":"Item",
    "orderCreate.buttonAddArticle":"Add item",
    "orderCreate.voucherH3":"Redeem voucher",
    "orderCreate.voucherP":"Click a voucher to reedeem it with this order.",
    "orderCreate.textArea":"Comment",
    "orderCreate.textField":"Your order-number / your sign",
    "orderCreate.needsSepa":" and clearing of this order via SEPA-deposit",
    "orderCreate.linkPdfName":"the regulations of the distance selling act",
    "orderCreate.linkPdf2Name":"prematurely start with service",
    "orderCreate.terms":"I accept the {linkAgb}{needsSepa}. " +
    "furthermore I accept {revocation}. " +
    "I ask clesyclean to {earlyStart}.",
    "orderCreate.buttonOrderCreate":"Place order",
    "orderCreate.vacation":"Vacation from {fromStart} to {fromEnd}",
    "orderCreate.pending":"loading...",
    "orderCreate.dateDeliveryWait":"Expected delivery date:",
    "orderCreate.dateDeliveryGua":"Guaranteed delivery date:",
    "orderCreate.nothingSelectedMessage":"Nothing selected.",
    "orderCreate.totalItem":"Total",
    "orderCreate.taxItem":"incl. 20% VAT:",
    "orderCreate.remove":"Remove",
    "orderCreate.remainingCredit":"Remaining credit",

    "orderList.title":"My orders",
    "orderList.buttonAdd":"New order",
    "orderList.noOrdersDiv":"You don't have any orders yet.",
    "orderList.orderCreateLink":"Order now!!",
    "orderList.orderTotal":"Order total",
    "orderList.buttonToCreate":"Open",
    "orderList.buttonNeedsPayment":"Requires payment",
    "orderList.payment":"Payment",

    "orderView.viewHeader":"Order loading",
    "orderView.viewHeaderOrderNumber":"Order",
    "orderView.viewHeaderButtonCancel":"Back to overview",
    "orderView.cardLink":"View invoice",
    "orderView.buttonPay":"Pay",
    "orderView.buttonRepeatOrder":"Reorder items",
    "orderView.requirePaymentHint": "Your order requires payment to be picked up. Please press the \"Pay now\" button below to continue.",

    "referralView.cardHead":"Incentives",
    "referralView.cardContent":"Thanks for your recommendation.",
    "referralView.cardContentTwo":"You will receive an e-mail as soon as your incentive is available.",
    "referralView.buttonRecommendation":"Send another recommendation.",
    "referralView.cardContentP1":"Use our «Kunde wirbt Kunde» marketing campaign and secure a free incentive for you and a friend!",
    "referralView.cardContentP2":"Please input the e-mail address of your friend or colleauge:",
    "referralView.cardContentP3":"By clicking send you accept {url}.",
    "referralView.agbLink":"our terms for «Kunde wirbt Kunde» marketing",
    "referralView.buttonSend":"Send",

    "resetPasswordView.cardHeader":"Reset password",
    "resetPasswordView.buttonCancel":"To login",
    "resetPasswordView.successMessage":"Thank you! Your password was changed.",
    "resetPasswordView.buttonLogin":"Login now",
    "resetPasswordView.hundleSubmit":"Please provide your new password:",
    "resetPasswordView.fieldPasswordOne":"New password",
    "resetPasswordView.fieldPasswordTwo":"Repeat password",
    "resetPasswordView.buttonSubmit":"Change password",

    "settingsView.viewHeader":"Settings",
    "settingsView.viewContentHeader":"User-data",
    "settingsView.viewContentName":"Name",
    "settingsView.viewContentMail":"E-mail",
    "settingsView.viewContentPhone":"Phone",
    "settingsView.viewContentAddress":"Address",
    "settingsView.cardHeader":"Change password",
    "settingsView.p1":"Please provide your current password:",
    "settingsView.p2":"Please provide your new password and repeat it:",
    "settingsView.oldPassword":"Old password",
    "settingsView.newPasswordOne":"New password",
    "settingsView.newPasswordTwo":"Repeat password",
    "settingsView.successChangeMessage":"Thank you, your password was changed.",
    "settingsView.successUserDataChangeMessage":"Thank you, your user data was changed. Please print again the Name Badge.",
    "settingsView.successAccountIbanChangeMessage":"Thank you, your account IBAN was changed.",
    "settingsView.button":"Change password",
    "settingsView.changeData":"Change data",
    "settingsView.changeBankAccount":"Change bank account",
    "settingsView.back":"Back",
    "settingsView.paymentCheckbox":"I accept that my monthly invoice is processed via SEPA-mandate.",
    "settingsView.fieldIban":"IBAN",
    "settingsView.fieldAccountHolder":"Account-owner",

    "signUpView.confirmValid":"Password and confirmation do not match.",
    "signUpView.info":"Please fill in the following form to sign up for clesyclean premium dry-cleaning.",
    "signUpView.helloText":"Salutation",
    "signUpView.female":"Mrs.",
    "signUpView.male":"Mr.",
    "signUpView.fieldTitle":"Title",
    "signUpView.fieldFirstName":"Firstname",
    "signUpView.fieldSurname":"Lastname",
    "signUpView.fieldMail":"E-mail",
    "signUpView.fieldPhone":"Phone",
    "signUpView.fieldCompany":"Company",
    "signUpView.fieldStreet":"Street",
    "signUpView.fieldNumber":"No.",
    "signUpView.fieldPostcode":"ZIP",
    "signUpView.fieldPlace":"City",
    "signUpView.fieldPassword":"Password",
    "signUpView.fieldPasswordConfirm":"Confirm password",
    "signUpView.messagePasswordInvalid":"Password and confirmation do not match.",
    "signUpView.cardHeaderReg":"Sign-up",
    "signUpView.cardRegInfo":"Please fill in the following form to sign up for clesyclean premium dry-cleaning.",
    "signUpView.personalDataHeader":"Personal data",
    "signUpView.contactHeader":"Contact information",
    "signUpView.passwordHeader":"Password",
    "signUpView.placeHeader":"Nearest clesyclean site",
    "signUpView.bankDetailsHeader":"Bank account",
    "signUpView.paymentDetails":"For customers at company sites we allow for convenient monthly payment\n" +
    "via SEPA mandate. This has been proven in practice for years. We use strong SSL encryption and guarantee for flawless processing.",
    "signUpView.paymentCheckbox":"I accept that my monthly invoice is processed via SEPA-mandate.",
    "signUpView.fieldIban":"IBAN",
    "signUpView.fieldAccountHolder":"Account-owner",
    "signUpView.bagHeader":"Suit-bag",
    "signUpView.bagInfo":"Your suit-bag is a mandatory component of clesyclean logistics. Please chose which package you want to purchase:",
    "signUpView.welcomePageMain":"1 Pcs. Welcome Package for € 22,50 incl. VAT",
    "signUpView.welcomePageSubField":"includes 1 Pcs. clesyclean suit-bag PRO and\n" +
    "a voucher for dry-cleaning services valued € 15,-",
    "signUpView.suitBagField":"1 Pcs. clesyclean suit-bag PRO for € 17,50 incl. VAT",
    "signUpView.promoCodeHeader":"Promo-code",
    "signUpView.promoCodePage":"You were invited by a colleague or have another promocode? Please input your «Kunde wirbt Kunde»-, voucher- or " +
    "promo-code and it will be processed during sign-up",
    "signUpView.promoCodeField":"Promo-code",
    "signUpView.termsOfServiceHeader":"Terms and conditions",
    "signUpView.checkBoxFieldAccept":"I accept the {agbUrl} of clesygroup GmbH.",
    "signUpView.textFieldComment":"Comment",
    "signUpView.submitButton":"Sign-up",
    "signUpView.renderCompleteHeader":"Thanks for sign-up",
    "signUpView.completeText":"Customers at clesyclean company sites are eligible to some special benefits e.g. regular " +
    "discounts and the option to use monthly payment via debit mandate. This is why we need to check your registration manually. " +
    "We come back to you immediately after everything is set up.",
    "signUpView.completeTextPin":"Your personal suit-bag is delivered to you via mai. If your company is equipped with a " +
    "clesy cube you will receive a PIN-code for picking up your suit-bag shortly",
    "signUpView.successMessage":"Thanks! We have successfully processed your sign-up.",
    "signUpView.emailConfirmation":"Please confirm your e-mail address by opening the link we have sent you.",
    "signUpView.continueToShop":"Continue to shop",
    "signUpView.suitBagHeaderSpan":"May we introduce?",
    "signUpView.suitBagHeader":"Your suit-bag",
    "signUpView.suitBagImgAlt":"Image of a clesyclean suit-bag",
    "signUpView.suitBagIntroTextOne":"Your personal suit-bag is waiting for you! From now on it is your personal " +
    "companion for your favourite pieces. It makes sure that your clothes remain in mint condition during transport and handling.",
    "signUpView.suitBagIntroTextTwo":"Your clesyclean suit-bag is a mandatory item for flawless logistics processing. " +
    "Just put your clothes into the clesyclean service site clearly marked with your name. We will ship it back in your new suit-bag.",
    "signUpView.textCenter":"We are looking forward to doing business with you!",
    "signUpView.signature":"Yours' clesyclean",
    "signUpView.ps":"PS: Your suit-bag is also invaluable while traveling!",
    "signUpView.buttonAdd":"Order now!",
    "signUpView.public":"I would like to use clesyclean on service hubs or public sites.",
    "signUpView.publicSub":"e.g. DC Tower, shopping centers",
    "signUpView.myCompaniesOption":"I would also like to use clesyclean at my company.",
    "signUpView.companiPlace":"Your company must have a clesyclean site.",
    "signUpView.companyHeader":"Company",
    "signUpView.companyAddress":"Please provide your company address:",
    "signUpView.companyNameLabel":"Company",
    "signUpView.companyLocation":"Office-center",
    "signUpView.buttonCancel":"To Login",
    "signupView.passwordFieldMessage":"Password confirmation does not match password",
    "signUpView.selectSite": "Select service station",
    "signUpView.selectSiteInfo": "Please select the location where you would like to use clesyclean. Use search terms like the company name, office building name, shopping center name, street, zip or city.",
    "signUpView.tooManyResults": "Too many results found. Please state your search terms for precisely.",
    "signUpView.selectSiteSearchLabel": "Search location",
    "signUpView.serviceStation": "Service station",
    "signUpView.publicSitePaymentMethodInfo": "At this location payment is due after return delivery to the clesy cube with the following payment methods:",

    "passwordResetStore.passwordFieldMessage":"Password confirmation does not match password",

    "userDataStore.requiredFieldMessage":"All fields with (*) must be filled.",

    "orderModel.format.Ordered":"Ordered",
    "orderModel.format.Cleaning":"Currently cleaning",
    "orderModel.format.Returned":"Returned",
    "orderModel.format.Billed":"Payment required",
    "orderModel.format.Cleared":"Ready to pickup",
    "orderModel.format.Canceled":"Canceled",

    "orderModel.formatOffice.Returned":"Processing",
    "orderModel.formatOffice.Billed":"Billed",
    "orderModel.formatOffice.Cleared":"Paid",
    "orderModel.formatOffice.Canceled":"Canceled",

    "itemModel.unitType.Piece":"Pc.",
    "itemModel.unitType.Kilogram":"kg",
    "itemModel.unitType.UnitType":"m²",
    "itemModel.unitType.Pair":"pair",
    "itemModel.unitType.Set":"set",
    "itemModel.unitType.Hours":"h",

    "itemModel.itemPricing.Highlight":"Promo",
    "itemModel.itemPricing.PermanentAgreement":"Permanent",
    "itemModel.itemPricing.PromoDiscount":"Power-Promo",
    "itemModel.itemPricing.Incentive":"Incentive",

    "clearingOptions.paymentTypeCard":"Credit-card",
    "clearingOptions.paymentTypeSepa":"SEPA-Debit",
    "clearingOptions.paymentTypePayPal":"PayPal",
    "clearingOptions.paymentTypeSofort":"Sofortüberweisung",

    "app.label":"Logged in as:",
    "app.shoppingCart":"Order",
    "app.previewBadge":"Name Badge",
    "app.giftCard":"Incentive",
    "app.settings":"Settings",
    "app.logOut":"Logout",
    "app.linkToContacts":"Imprint",
    "app.agb":"Terms",
    "app.orderList":"My orders",
    "app.copy":"2020 clesygroup GmbH",

    "selectSiteDialog.header":"Select site",
    "selectSiteDialog.closeButton":"Cancel",

    "selectAccountDialog.header":"Select account",
    "selectAccountDialog.closeButton":"Cancel",

    "bicField.inValidMessage":"Invalid format",
    "bicField.moreChars":"More signs ...",

    "checkBoxField.titleRequired":"Mandatory",

    "badge.header":"Print Name Badge",
    "badge.field":"Name badge",
    "badge.button.print":"Print",
    "badge.select-site":"Please select a site",
    "badge.mobile":"Mobile:",
    "badge.number":"Cr.№",
    "badge.header.preview":"Preview",

    "confirmEmail.error": "Error confirming e-mail address.",
    "confirmEmail.success": "Your e-mail address was verified.",
    "confirmEmail.continueToShop": "Continue to shop",

    "order.payment.start": "Pay now",
}
