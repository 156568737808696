
export interface PersonData {
    firstname:string;
    lastname:string;
    salutation:string;
    title:string;
}

export class Person implements PersonData {
    constructor(personData?:PersonData) {
        if (personData) {
            Object.assign(this, personData);
        }
    }

    firstname:string;
    lastname:string;
    salutation:string;
    title:string;

    format():string {
        return Person.formatPerson(this);
    }

    static formatPerson(person:PersonData):string {
        return `${person.title} ${person.firstname} ${person.lastname}`.trim();
    }
}