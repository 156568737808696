
import * as React from "react";
import {TextField} from "./TextField";
import * as IbanValidator from "iban";


namespace IbanField {
    export interface State {
        valid?:boolean;
        potentiallyValid?:boolean;
        message?:string;
    }
}

export class IbanField extends React.Component<TextField.Props, IbanField.State> {

    constructor(props:TextField.Props) {
        super(props);
        this.state = {};
    }

    render() {
        return <TextField
            {...this.props}
            onChange={e => this.handleChange(e)}
            onBlur={e => this.handleBlur(e)}
            message={this.state.message}
            messageIcon={ this.state.valid ? null : <i>error_outline</i> }
        />;
    }

    private validateIban(target:HTMLInputElement, strict:boolean) {

        let message = null;

        // remove all invalid chars
        target.value = target.value.replace(/[^A-Z0-9]/g, '');

        let isValid = IbanValidator.isValid(target.value);
        let isPotentiallyValid = false;

        if (!isValid) {
            if (strict) {
                message = 'Ungültiger IBAN';
            }
            else {
                let countryCode = target.value.substr(0, 2);

                let spec = IbanValidator.countries[countryCode];
                if (spec) {
                    if (target.value.length < spec.length) {
                        message = 'Mehr Zeichen...';
                        isPotentiallyValid = true;
                    }
                    else {
                        message = 'Ungültiges Format';
                    }
                }
                else {
                    message = 'Ungültiger Ländercode';
                }
            }
        }

        target.value = IbanValidator.printFormat(target.value);

        target.setCustomValidity(!isValid ? message : '');
        this.setState({...this.state, valid: isValid, potentiallyValid: isPotentiallyValid, message: message })
    }

    private handleChange(e:React.ChangeEvent<HTMLInputElement>):void {
        this.validateIban(e.currentTarget, false);

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    private handleBlur(e:React.FocusEvent<HTMLInputElement>):void {
        this.validateIban(e.currentTarget, true);
    };
}