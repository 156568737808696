import * as React from "react";
import {Alert} from "../controls/Alert";
import {Button} from "../controls/Button";
import {View, ViewMain} from "../controls/View";
import {Card, CardContent, CardHeader} from "../controls/Card";
import {RootStore} from "../../stores/RootStore";
import {TextField} from "../controls/TextField";
import {observer} from "mobx-react";
import {Logo} from "../controls/Logo";
import {FormattedMessage, injectIntl} from 'react-intl';
import {ReferralView} from "./ReferralView";


export interface LostPasswordViewProps {
    active: boolean;
    store: RootStore;
    intl: any;
}
export interface LostPasswordViewState {
}

@observer
export class ResetPasswordView extends React.Component<LostPasswordViewProps, LostPasswordViewState> {

    private passwordConfirmationInput: HTMLInputElement;

    private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.store.passwordReset.submit();
    };

    private handleLoginClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.props.store.showOrderList();
    };

    componentDidUpdate() {
        this.passwordConfirmationInput.setCustomValidity(this.props.store.passwordReset.newPasswordConfirmationValidity);
    }


    render() {
        const {intl} = this.props;
        return (
            <View className="clesy-reset-password-view" active={this.props.active}>
                <ViewMain className="reset-password-form">
                    <Logo />
                    <Card>
                        <CardHeader>
                            <h2><FormattedMessage id="resetPasswordView.cardHeader"/></h2>
                        </CardHeader>
                        <CardContent>
                            { this.renderContent() }
                        </CardContent>
                    </Card>
                    <div className="cancel-button">
                        <Button label={intl.formatMessage({id:"resetPasswordView.buttonCancel"})} onClick={this.handleLoginClick} />
                    </div>
                </ViewMain>
            </View>
        );
    }

    private renderContent() {
        let passwordResetStore = this.props.store.passwordReset;

        if (passwordResetStore.state && passwordResetStore.state.fulfilled) {
            return this.renderSuccess();
        }
        else {
            return this.renderForm();
        }
    }


    private renderSuccess() {
        const {intl} = this.props;
        return (
            <div className="success">
                <Alert type="success">
                    <FormattedMessage id="resetPasswordView.successMessage"/>
                </Alert>
                <div className="buttons">
                    <Button label={intl.formatMessage({id:"resetPasswordView.buttonLogin"})} onClick={this.handleLoginClick} />
                </div>
            </div>
        )
    }


    private renderForm() {
        let passwordResetStore = this.props.store.passwordReset;
        const {intl} = this.props;
        return (
            <form onSubmit={this.handleSubmit}>
                <p><FormattedMessage id="resetPasswordView.hundleSubmit"/></p>

                { passwordResetStore.state && passwordResetStore.state.rejected && (
                    <Alert type="error">
                        {passwordResetStore.state.reason.toString()}
                    </Alert>
                )}

                <div className="row">
                    <TextField label={intl.formatMessage({id:"resetPasswordView.fieldPasswordOne"})} type="password" required
                               value={passwordResetStore.newPassword}
                               onChange={e => passwordResetStore.setProps({newPassword: e.target.value})}/>
                    <TextField label={intl.formatMessage({id:"resetPasswordView.fieldPasswordTwo"})} type="password" required
                               value={passwordResetStore.newPasswordConfirmation}
                               message={passwordResetStore.newPasswordConfirmationValidity !== '' ? intl.formatMessage({id:"passwordResetStore.passwordFieldMessage"}) :  passwordResetStore.newPasswordConfirmationValidity}
                               onChange={e => passwordResetStore.setProps({newPasswordConfirmation: e.target.value})}
                               input={input => this.passwordConfirmationInput = input}/>
                </div>

                <div className="buttons">
                    <Button label={intl.formatMessage({id:"resetPasswordView.buttonSubmit"})}/>
                </div>
            </form>
        )
    }
}

export default injectIntl(ResetPasswordView)