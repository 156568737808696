import {PromisedValue, StoreBase} from "og-spa-state";
import {ClearingOptions, ClearingOptionsData} from "../model/ClearingOptions";
import {action, observable, reaction} from "mobx";
import {AuthStore} from "./AuthStore";

export interface OrderClearingProps {
}

interface OrderClearingState {
    orderId:number;
    authKey:string;
    clearing:PromisedValue<ClearingOptions>;
}

export interface OrderClearingService {
    initClearing(orderId:number, authKey?:string):Promise<ClearingOptionsData>;
    performClearing(id:string, clearingOptionUuid:string):Promise<string>;
}

export class OrderClearingStore extends StoreBase<OrderClearingProps, OrderClearingState> {

    constructor(private service:OrderClearingService, private auth:AuthStore) {
        super();

        reaction(() => this.auth.authenticated, authenticated => { this.initClearing(this.orderId, this.authKey) });
    }

    @observable
    private readonly orderId:number;

    @observable
    private readonly authKey:string;

    @observable
    readonly clearing:PromisedValue<ClearingOptions>;

    @action
    public initClearing(orderId:number, authKey?:string) {
        let promisedOrder = null;
        if (orderId) {
            if (this.auth.authenticated) {
                let promise = this.service.initClearing(orderId, authKey)
                    .then(clearingOptionsData => new ClearingOptions(clearingOptionsData));

                promisedOrder = new PromisedValue(promise);
            }
        }
        this.setState({orderId: orderId, authKey: authKey, clearing: promisedOrder});
    }

    @action
    public perform(clearingOptionUuid:string):Promise<string> {
        if (clearingOptionUuid && this.clearing.fulfilled) {
            return this.service.performClearing(this.clearing.value.clearingUuid, clearingOptionUuid)
        }
        // TODO: proper handling of async state, errors, maybe even redirect here
    }
}