import {observer} from "mobx-react";
import * as React from "react";
import {Category} from "../../model/Category";
import {Item} from "../../model/Item";
import {RootStore} from "../../stores/RootStore";
import {StringUtil} from "../../util/StringUtil";
import {ArticleItem} from "../controls/ArticleItem";
import {Button} from "../controls/Button";
import {Collapsible} from "../controls/Collapsible";
import {Dialog, DialogContent, DialogFooter, DialogHeader} from "../controls/Dialog";
import {Item as ItemComponent} from "../controls/Item";
import {List} from "../controls/List";

export namespace AddArticleDialog {
    export interface Props {
        active:boolean;
        onAdd:(article:Item) => void;
        onClose:() => void;
        store:RootStore;
    }

    export interface State {
        searchActive:boolean;
        searchCategories:boolean;
        selectedCategory:number;
    }
}

@observer
export class AddArticleDialog extends React.Component<AddArticleDialog.Props, AddArticleDialog.State> {

    private searchTerm:HTMLInputElement;

    constructor(props:AddArticleDialog.Props) {
        super(props);
        this.state = {
            searchActive: false,
            searchCategories: true,
            selectedCategory: null
        };
    }

    private toggleSearchActive() {
        this.setState({
            ...this.state,
            searchActive: !this.state.searchActive,
            searchCategories: false
        });
        this.searchTerm.value = ''
        this.updateFilter();
    }

    private toggleCategories() {
          if(!this.state.searchCategories) {
            this.setState({
                ...this.state,
                searchCategories: !this.state.searchCategories,
                selectedCategory: null,
                searchActive: false
            });
            this.searchTerm.value = '';
            this.updateFilter();
          }
    }

    componentDidUpdate() {
        if (this.state.searchActive) {
            this.searchTerm.focus();
        }
    }

    private handleSearchTermChange(e:React.ChangeEvent<HTMLInputElement>) {
        if (this.searchTerm.value.length === 0)
            this.toggleCategories();
        else {
          this.setState({
              ...this.state,
              searchActive: true,
              searchCategories: false
          });
          this.updateFilter();
        }
    }

    private updateFilter() {
        this.props.store.cart.articles.setProps({
            filterTerm: this.state.searchActive ? this.searchTerm.value : null
        });
    }

    private handleArticleClick(a:Item) {
        this.props.store.cart.addItem(a);

        document.querySelector('.shopping-bag').classList.add('shopping-bag--animated')
        setTimeout(() => document.querySelector('.shopping-bag').classList.remove('shopping-bag--animated'), 500)
    }

    private handleClearClick() {
        this.toggleCategories();
    }

    private selectCategory(c:Category) {
        const {selectedCategory} = this.state
        if (c.categoryId === selectedCategory) {
            this.setState({selectedCategory: null})
        } else {
            this.setState({selectedCategory: c.categoryId})
        }
    }

    render() {
        return (
            <Dialog className="add-article-dialog" active={this.props.active}>
                <DialogHeader className='search-active'>
                    <div className="search">
                        <i>search</i>
                    </div>
                    <div className="search-term">
                        <input type="text" placeholder="Suchbegriff eingeben" ref={input => this.searchTerm = input} name="search_term" onChange={e => this.handleSearchTermChange(e)} />
                        <i className="clear" onClick={() => this.handleClearClick()}>close</i>
                    </div>
                    <Button onClick={this.props.onClose}>Weiter</Button>
                </DialogHeader>
                <DialogContent>
                    <List>
                        {this.renderItems()}
                    </List>
                </DialogContent>
                <DialogFooter>
                    <div className="shopping-bag" onClick={this.props.onClose}>
                        <i>shopping_cart</i>
                    </div>
                    <div className="item-count">{this.props.store.cart.itemCount}</div>
                    <div className="total-amount">
                        <span className="amount">{StringUtil.formatCurrency(this.props.store.cart.total)}</span>
                    </div>
                </DialogFooter>
            </Dialog>
        )
    }

    renderItems() {
        const {searchCategories, selectedCategory} = this.state;
        let {filtered: items, categories} = this.props.store.cart.articles;

        if (searchCategories) {
            return categories.map(c => (
                <div key={c.categoryId}>
                    <ItemComponent
                        className={selectedCategory === c.categoryId ? 'clesy-article-category-item--selected' : 'clesy-article-category-item'}
                        onClick={e => this.selectCategory(c)}
                        iconRight={selectedCategory === c.categoryId ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                    >
                        <div className='clesy-article-category-item-title'>{c.name}</div>
                    </ItemComponent>
                    <Collapsible expanded={selectedCategory === c.categoryId}>
                      {c.items.map((a:Item) => (
                        <ArticleItem key={a.itemId} article={a} iconRight={"add"} onClick={e => this.handleArticleClick(a)}/>
                      ))}
                    </Collapsible>
                </div>
            ))
        } else if (items.length) {
            return items.map(a => (
              <ArticleItem key={a.itemId} onClick={e => this.handleArticleClick(a)} article={a} iconRight={"add"}/>
            ))
        } else {
            return (
                <div className="empty-message">
                    Keine Artikel gefunden.
                </div>
            )
        }
    }
}
