import * as React from "react";

export namespace Button {

    export interface Props {
        name?: string;
        type?: "button"|"submit"|"reset";
        label?: string;
        primary?: boolean;
        link?: boolean;
        icon?: string;
        onClick?: React.MouseEventHandler<HTMLButtonElement>;
        disabled?: boolean;
    }

    export interface State {
    }
}

export class Button extends React.Component<Button.Props, Button.State> {

    public static defaultProps: Button.Props = {
        name: "",
        label: "",
        primary: false,
        icon: ""
    };

    render() {
        const {name = "", label = "", primary = false, icon = "", link, onClick, type, children, disabled} = this.props;
        let classes = ["button"];
        if (primary) {
            classes.push("primary");
        }
        if (link) {
            classes.push("link");
        }
        return (
            <button name={name} className={classes.join(" ")} onClick={onClick}
                    disabled={disabled} type={type}>
                {icon && <i>{icon}</i>}
                {label}
                {children}
            </button>
        );
    }
}