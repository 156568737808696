import {observer} from "mobx-react";
import * as React from "react";
import {InjectedIntl, injectIntl} from "react-intl";
import {Account} from "../../model/Account";
import {Site} from "../../model/Site";
import {RootStore} from "../../stores/RootStore";
import {BadgeView} from "./BadgeView";

export interface UserBadgeViewProps {
    active: boolean;
    store: RootStore;
    onCancel: () => void;
    intl: InjectedIntl;
}

@observer
class _UserBadgeView extends React.Component<UserBadgeViewProps> {

    render() {
        const {intl, store, active} = this.props;
        const {cart, auth} = store;
        const {account, site, availableAccounts, availableSites} = cart;
        const {user} = auth;

        return (
            <BadgeView active={active}
                       user={user}
                       account={account}
                       site={site}
                       availableAccounts={availableAccounts}
                       availableSites={availableSites}
                       onChangeAccount={this.handleChangeAccount}
                       onChangeSite={this.handleChangeSite}
                       intl={intl}/>
        );
    }

    private handleChangeAccount = (account: Account) => this.props.store.cart.setProps({account});
    private handleChangeSite = (site: Site) => this.props.store.cart.setProps({site});
}

export const UserBadgeView = injectIntl(_UserBadgeView);
