import * as React from 'react';
import {Field} from "./Field";
import {ClassList} from "./Base";

export namespace InputField {
    export interface Props {
        name?:string;
        label?:string;
        required?:boolean;
        className?:string;
        message?:string;
        messageIcon?:JSX.Element;
    }
    export interface State {
    }
}

export class InputField extends React.Component<InputField.Props, InputField.State> {

    render() {
        return <Field className={ClassList.compute('floating-label', this.props.className)}>
            {this.props.children}
            <span className="bar" />
            <label htmlFor={this.props.name}>{this.props.label} {this.props.required && <abbr className="required" title="Pflichtfeld">*</abbr>}</label>
            {this.props.message && <div className="message">{this.props.messageIcon} {this.props.message}</div>}
        </Field>
    }
}