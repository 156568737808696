export default {
    "welcome": "Welcome",
    "login.errorMessage":"Anmeldung fehlgeschlagen.",
    "login.logoutMessage":"Sie wurden erfolgreich abgemeldet.",
    "login.sessionTimeout":"Ihre Sitzung ist abgelaufen.",
    "login.register.text":"Jetzt Kunde werden",
    "login.button.submit":"Anmelden",
    "login.button.forgotPassword":"Passwort vergessen?",
    "login.button.register":"Registrieren",
    "login.textField.username":"Benutzername (E-Mail)",
    "login.textField.password":"Passwort (ehem. PLZ)",

    "lostPassword.form.h2":"Passwort zurücksetzen",
    "lostPassword.successMessage":"Vielen Dank! Sie erhalten in Kürze eine E-Mail mit weiteren Schritten.",
    "lostPassword.form.p":"Bitte geben Sie Ihren Benutzernamen bzw. Ihre E-Mail-Adresse ein. Wir senden Ihnen\n" +
    "eine E-Mail mit den weiteren Schritten zum Zurücksetzen Ihres Passworts.",
    "lostPassword.form.mailLabel":"Benutzername / E-Mail",
    "lostPassword.button":"Absenden",
    "lostPassword.backButton": "Zurück",

    "noMatch.code":"Hier gibt's nichts zu sehen",
    "noMatch.title":"Leider nichts gefunden",

    "orderClearing.clearingSuccess.h2":"Vielen Dank!",
    "orderClearing.clearingSuccess.p":"Ihre Bestellung ist bezahlt.",
    "orderClearing.clearingSuccess.button":"Zurück zur Übersicht",
    "orderClearing.clearingSuccess.cardHeader":"wird geladen...",
    "orderClearing.clearingSuccess.cardHeaderOther":"Keine Bestellung ausgewählt",
    "orderClearing.content.h2":"Bezahlung",
    "orderClearing.content.label":"Gespeicherte",
    "orderClearing.sepaFields.sepaAccountIbanLabel":"IBAN",
    "orderClearing.sepaFields.sepaAccountBicLabel":"BIC",
    "orderClearing.sepaFields.sepaAccountHolderLabel":"Kontoinhaber",
    "orderClearing.validateCretidCard.demoCard":"Wirecard Demo Card",
    "orderClearing.validateCretidCard.dateFormat":"Ungültiges Format",
    "orderClearing.validateCretidCard.dateOk":"Karte abgelaufen",
    "orderClearing.validateCretidCard.creditCardNumber":"Karte abgelaufen",
    "orderClearing.textFields.creditCardNumber":"Kartennummer",
    "orderClearing.textFields.creditCardHolder":"Karteninhaber",
    "orderClearing.textFields.creditCardExpiry":"Gültig bis (MM/JJJJ)",
    "orderClearing.numberState":"Ungültige Kartennummer",
    "orderClearing.demoCreditCard":"Wirecard Demo Card",
    "orderClearing.validationFormat":"Ungültiges Format",
    "orderClearing.validationCart":"Karte abgelaufen",

    "orderClearingFailure.orderStatus.errorMessage":"Ihre Zahlung ist leider fehlgeschlagen. Bitte versuchen Sie es später erneut. Sollte das\n" +
    "Problem weiterhin bestehen, wenden " +
    "Sie sich bitte an den clesy clean Support.",
    "orderClearingFailure.orderStatus.button":"Erneut versuchen",
    "orderClearingFailure.orderStatus.a":"Rechnung ansehen",
    "orderClearingFailure.pendingHeader":"Bestellung wird geladen",
    "orderClearingFailure.fulfilled":"Bestellung {orderNumber}",
    "orderClearingFailure.buttonCancel":"Zur Übersicht",


    "orderCommon.pinLabel":"PIN:",
    "orderCommon.pinInfo":"Geben Sie den PIN Code einfach am Touch Bildschirm ihrer nächsten\n" +
    "clesyclean Servicestation ein um ihre Kleidung abzugeben.",
    "orderCommon.pinInfoOther":"Geben Sie den PIN Code einfach am Touch Bildschirm der clesyclean\n" +
    "Servicestation ein und das Fach mit ihrer sauberen Kleidung öffnet sich.",
    "orderCommon.newerVersion.message":"Achtung: Dies ist eine ältere Fassung Ihrer Bestellung.",
    "orderCommon.newerVersion.button":"Zur neueren Version",
    "orderCommon.orderDetails.table.name":"Bezeichnung",
    "orderCommon.orderDetails.table.quantity":"Menge",
    "orderCommon.orderDetails.table.total":"Total",
    "orderCommon.orderDetails.table.voucher":"abzüglich Gutscheine",
    "orderCommon.orderDetails.table.sum":"Summe:",
    "orderCommon.orderDetails.table.tax":"inkl.",
    "orderCommon.orderDetails.table.vat":"USt:",
    "orderCommon.orderDetails.table.net":"Summe exkl. USt:",
    "orderCommon.orderDetails.date":"Vorrausichtliches Lieferdatum",
    "orderCommon.orderDetails.dateTwo":"Garantiertes Lieferdatum",
    "orderCommon.orderDetails.attentionMessage":"Das garantierte Lieferdatum können wir nur einhalten, wenn sie die Kleidung bis spätestens 09:00 an der Servicestation abgeben haben.",
    "orderCommon.orderDetails.customerOrderId":"Bestellnummer / Zeichen",
    "orderCommon.orderDetails.supportComment":"Kommentar vom clesyclean Support",
    "orderCommon.orderDetails.customerComment":"Ihre Anmerkungen zur Bestellung",
    "orderCommon.reOrder":"Nachbestellung",
    "orderCommon.notification.title":"Benachrichtigungen",
    "orderCommon.notification.closeBtn":"Schließen",

    "orderCreate.h2":"Neue Bestellung",
    "orderCreate.buttonCancel":"Zur Übersicht",
    "orderCreate.labelRight":"Ändern",
    "orderCreate.accountH3":"Verrechnungskonto",
    "orderCreate.siteH3":"Servicestation",
    "orderCreate.articleH3":"Artikel",
    "orderCreate.buttonAddArticle":"Artikel hinzufügen",
    "orderCreate.voucherH3":"Gutschein einlösen",
    "orderCreate.voucherP":"Wählen Sie einen Gutschein an, um Ihn für die aktuelle Bestellung einzulösen.",
    "orderCreate.textArea":"Kommentar, Anmerkung",
    "orderCreate.textField":"Ihre Bestellnummer / Ihr Zeichen",
    "orderCreate.needsSepa":"  und den Einzug per SEPA-Lastschrift",
    "orderCreate.linkPdfName":"Bestimmungen zum Wiederrufsrecht lt. Fernabsatzgesetz",
    "orderCreate.linkPdf2Name":"vorzeitigen Beginn der Erbringung der Dienstleistung",
    "orderCreate.terms":"Ich akzeptiere die {linkAgb}{needsSepa}. " +
    "Insbesondere akzeptiere ich die {revocation}. " +
    "Weiters fordere ich clesygroup GmbH zum {earlyStart} auf.",
    "orderCreate.buttonOrderCreate":"Jetzt bestellen",
    "orderCreate.vacation":"Urlaub/Feiertag von {fromStart} bis {fromEnd}",
    "orderCreate.pending":"Wird geladen...",
    "orderCreate.dateDeliveryWait":"Erwartetes Lieferdatum:",
    "orderCreate.dateDeliveryGua":"Garantiertes Lieferdatum:",
    "orderCreate.nothingSelectedMessage":"Noch nichts ausgewählt.",
    "orderCreate.totalItem":"Summe",
    "orderCreate.taxItem":"inkl. 20% MwSt:",
    "orderCreate.remove":"Entfernen",
    "orderCreate.remainingCredit":"Verbleibendes Guthaben",

    "orderList.title":"Meine Bestellungen",
    "orderList.buttonAdd":"Neue Bestellung",
    "orderList.noOrdersDiv":"Sie haben noch keine Bestellungen. ",
    "orderList.orderCreateLink":"Jetzt loslegen!",
    "orderList.orderTotal":"Bestellwert",
    "orderList.buttonToCreate":"Öffnen",
    "orderList.buttonNeedsPayment":"Bezahlen",
    "orderList.payment":"Bezahlen",

    "orderView.viewHeader":"Bestellung wird geladen",
    "orderView.viewHeaderOrderNumber":"Bestellung",
    "orderView.viewHeaderButtonCancel":"Zur Übersicht",
    "orderView.cardLink":"Rechnung ansehen",
    "orderView.buttonPay":"Bezahlen",
    "orderView.buttonRepeatOrder":"Artikel Wiederbestellen",
    "orderView.requirePaymentHint": "Ihre Bestellung muss bezahlt werden, damit sie abgeholt werden kann. Bitte klicken Sie auf \"Jetzt bezahlen\", um fortzufahren.",

    "referralView.cardHead":"Gratisreinigung",
    "referralView.cardContent":"Vielen Dank, wir haben Ihre Empfehlungen versendet.",
    "referralView.cardContentTwo":"Sie erhalten eine E-Mail, wenn eine Gratisreinigung für Sie bereit steht.",
    "referralView.buttonRecommendation":"Weitere Empfehlungen versenden.",
    "referralView.cardContentP1":"Nutzen Sie unsere «Kunde wirbt Kunde» Aktion und sichern Sie sich und den Geworbenen eine Gratisreinigung.",
    "referralView.cardContentP2":"Bitte geben Sie die E-Mail Adressen Ihrer Freunde oder Kollegen an:",
    "referralView.cardContentP3":"Mit dem Absenden akzeptieren Sie die {url}",
    "referralView.agbLink":"AGBs für «Kunde wirbt Kunde» Aktion",
    "referralView.buttonSend":"Absenden",

    "resetPasswordView.cardHeader":"Passwort zurücksetzen",
    "resetPasswordView.buttonCancel":"Zum Login",
    "resetPasswordView.successMessage":"Vielen Dank! Ihr Passwort wurde geändert.",
    "resetPasswordView.buttonLogin":"Jetzt anmelden",
    "resetPasswordView.hundleSubmit":"Bitte geben Sie Ihr neues Passwort ein:",
    "resetPasswordView.fieldPasswordOne":"Neues Passwort",
    "resetPasswordView.fieldPasswordTwo":"Bestätigung",
    "resetPasswordView.buttonSubmit":"Passwort ändern",

    "settingsView.viewHeader":"Einstellungen",
    "settingsView.viewContentHeader":"Benutzerdaten",
    "settingsView.viewContentName":"Name",
    "settingsView.viewContentMail":"E-Mail",
    "settingsView.viewContentPhone":"Telefon",
    "settingsView.viewContentAddress":"Adresse",
    "settingsView.cardHeader":"Passwort ändern",
    "settingsView.p1":"Geben Sie aus Sicherheitsgründen hier Ihr bisheriges Passwort an:",
    "settingsView.p2":"Geben Sie hier Ihr neues Passwort ein und bestätigen Sie es:",
    "settingsView.oldPassword":"Bisheriges Passwort",
    "settingsView.newPasswordOne":"Neues Passwort",
    "settingsView.newPasswordTwo":"Passwort bestätigen",
    "settingsView.successChangeMessage":"Vielen Dank, Ihr Passwort wurde geändert.",
    "settingsView.successUserDataChangeMessage":"Vielen Dank, Ihre Benutzerdaten wurde geändert. Bitte drucken Sie noch einmal das Namensschild aus.",
    "settingsView.successAccountIbanChangeMessage":"Vielen Dank, Ihre Konto IBAN wurde geändert.",
    "settingsView.button":"Passwort ändern",
    "settingsView.changeData":"Daten ändern",
    "settingsView.changeBankAccount":"Bank Konto ändern",
    "settingsView.back":"Zurück",
    "settingsView.paymentCheckbox":"Ich akzeptiere hiermit die Zahlung via Bankeinzug von meinem Konto.",
    "settingsView.fieldIban":"IBAN",
    "settingsView.fieldAccountHolder":"Kontoinhaber",

    "signUpView.confirmValid":"Bestätigung stimmt nicht überein.",
    "signUpView.info":"Füllen Sie untenstehendes Formular aus, um künftig den clesyclean\n" +
    "Premium-Reinigungsservice nutzen zu können.",
    "signUpView.helloText":"Anrede",
    "signUpView.female":"Frau",
    "signUpView.male":"Herr",
    "signUpView.fieldTitle":"Titel",
    "signUpView.fieldFirstName":"Vorname",
    "signUpView.fieldSurname":"Nachname",
    "signUpView.fieldMail":"E-Mail",
    "signUpView.fieldPhone":"Telefon",
    "signUpView.fieldCompany":"Firma",
    "signUpView.fieldStreet":"Straße",
    "signUpView.fieldNumber":"Nr.",
    "signUpView.fieldPostcode":"PLZ",
    "signUpView.fieldPlace":"Ort",
    "signUpView.fieldPassword":"Passwort",
    "signUpView.fieldPasswordConfirm":"Passwort bestätigen",
    "signUpView.messagePasswordInvalid":"Bestätigung stimmt nicht überein.",
    "signUpView.cardHeaderReg":"Registrierung",
    "signUpView.cardRegInfo":"Füllen Sie untenstehendes Formular aus, um künftig den clesyclean\n" +
    "Premium-Reinigungsservice nutzen zu können.",
    "signUpView.personalDataHeader":"Persönliche Daten",
    "signUpView.contactHeader":"Kontaktdaten",
    "signUpView.passwordHeader":"Passwort",
    "signUpView.bankDetailsHeader":"Bankverbindung",
    "signUpView.paymentDetails":"Für Kunden an Unternehmensstandorten bieten wir die bequeme Bezahlung per\n" +
    "monatlicher Lastschrift an. Das hat sich seit Jahren bewährt. Wir verwenden\n" +
    "SSL-Verschlüsselung und garantieren für eine ordnungsgemäße Abwicklung.",
    "signUpView.paymentCheckbox":"Ich akzeptiere hiermit die Zahlung via Bankeinzug von meinem Konto.",
    "signUpView.fieldIban":"IBAN",
    "signUpView.fieldAccountHolder":"Kontoinhaber",
    "signUpView.bagHeader":"Kleidersack",
    "signUpView.bagInfo":"Der Kleidersack ist Voraussetzung für die Nutzung des clesyclean Service. Bitte\n" +
    "wählen Sie eine Variante, wie Sie den Kleidersack kaufen möchten:",
    "signUpView.welcomePageMain":"1 Stk. Welcome Paket um € 22,50 inkl. USt.",
    "signUpView.welcomePageSubField":"enthält 1 Stk. clesyclean Kleidersack PRO und\n" +
    "einen Textilreinigungsgutschein im Wert von € 15,-",
    "signUpView.suitBagField":"1 Stk. clesyclean Kleidersack PRO um € 17,50\n" +
    "inkl. USt.",
    "signUpView.promoCodeHeader":"Promocode",
    "signUpView.promoCodePage":"Sie sind als Kunde geworben worden? Geben sie hier Ihren «Kunde wirbt Kunde»-, Gutschein- oder\n" +
    "Aktions-Code ein und sichern Sie sich Ihr Geschenk:",
    "signUpView.promoCodeField":"Promocode",
    "signUpView.termsOfServiceHeader":"Allgemeine Geschäftsbedingungen",
    "signUpView.checkBoxFieldAccept":"Ich akzeptiere hiermit die {agbUrl} der clesygroup GmbH.",
    "signUpView.textFieldComment":"Allfällige Anmerkungen",
    "signUpView.submitButton":"Registrieren",
    "signUpView.renderCompleteHeader":"Vielen Dank für Ihre Registrierung",
    "signUpView.completeText":"Unsere Kunden an Firmenstandorten genießen einige Vorteile, wie zum Beispiel\n" +
    "Aktionen und die Möglichkeit der bequemen Zahlung per Monatsrechnung. Aus diesem\n" +
    "Grund werden Registrierungen an Firmenstandorten von uns händisch geprüft. Wir\n" +
    "melden uns sofort, wenn Ihr Zugang freigeschaltet ist.",
    "signUpView.completeTextPin":"Ihren persönlichen Kleidersack erhalten Sie entweder per Post (direkt in Ihr\n" +
    "Unternehmen) oder im Falle dass Sie einen clesy cube in Ihrem Unternehmen haben,\n" +
    "erhalten Sie in Kürze einen PIN-Code, mit dem Sie Ihr Welcome Paket direkt beim\n" +
    "clesy cube abholen können.",
    "signUpView.successMessage":"Vielen Dank! Ihre Registrierung wurde erfolgreich abgeschlossen.",
    "signUpView.emailConfirmation":"Bitte bestätigen Sie Ihre E-Mail-Adresse durch Öffnen des Links, den wir Ihnen zugesendet haben.",
    "signUpView.continueToShop":"Weiter zum Shop",
    "signUpView.suitBagHeaderSpan":"Dürfen wir vorstellen?",
    "signUpView.suitBagHeader":"Ihr Kleidersack",
    "signUpView.suitBagImgAlt":"Ihr Kleidersack",
    "signUpView.suitBagIntroTextOne":"Ihr persönlicher Kleidersack wartet bereits auf Sie! Er ist ab sofort der\n" +
    "persönliche Begleiter Ihrer Lieblingsstücke und sorgt dafür, dass Ihre Kleidung während des\n" +
    "Transports geschont wird.",
    "signUpView.suitBagIntroTextTwo":"Der clesyclean Kleidersack ist Vorraussetzung für die Nutzung unseres\n" +
    "Premium-Kleiderreingungsservice.\n" +
    "Hängen Sie Ihre erste Reinigungsbestellung einfach mit Ihrem Namen gekennzeichet in die\n" +
    "Servicestation und wir liefern\n" +
    "Ihre saubere Wäsche in Ihrem neuen Kleidersack zurück.",
    "signUpView.textCenter":"Wir freuen uns auf ihren ersten Auftrag!",
    "signUpView.signature":"Ihr clesyclean Team",
    "signUpView.ps":"PS: Ihr Kleidersack ist auch ein wertvoller Begleiter auf Reisen!",
    "signUpView.buttonAdd":"Jetzt Bestellen!",
    "signUpView.companyHeader":"Unternehmen",
    "signUpView.companyAddress":"Geben Sie bitte die Adresse Ihres Unternehmens an:",
    "signUpView.companyNameLabel":"Firma (Dienstgeber)",
    "signUpView.companyLocation":"Bürocenter",
    "signUpView.buttonCancel":"Zum Login",
    "signupView.passwordFieldMessage":"Bestätigung stimmt nicht überein",
    "signUpView.selectSite": "Servicestation auswählen",
    "signUpView.selectSiteInfo": "Bitte geben Sie, an welchem Standort Sie clesyclean nutzen möchten. Geben Sie dazu Suchbegriffe in das Textfeld ein, wie z.B. Name der Firma, Name des Bürocenters, Name des Einkaufszentrums, Straße, PLZ oder Ort.",
    "signUpView.tooManyResults": "Zu viele Ergebnisse gefunden. Bitte präzisieren Sie Ihre Suchbegriffe.",
    "signUpView.selectSiteSearchLabel": "Standortsuche",
    "signUpView.serviceStation": "Servicestation",
    "signUpView.publicSitePaymentMethodInfo": "An dieser Servicestation erfolgt die Bezahlung nach Rücklieferung in den clesy cube mit folgenden Bezahlmöglichkeiten:",

    "passwordResetStore.passwordFieldMessage":"Bestätigung stimmt nicht überein",

    "userDataStore.requiredFieldMessage":"Alle Felder mit (*) müssen ausgefüllt werden.",

    "orderModel.format.Ordered":"Bestellt",
    "orderModel.format.Cleaning":"Wird gereinigt",
    "orderModel.format.Returned":"Wurde gereinigt",
    "orderModel.format.Billed":"Bezahlung erforderlich",
    "orderModel.format.Cleared":"Zur Abholung bereit",
    "orderModel.format.Canceled":"Storniert",

    "orderModel.formatOffice.Returned":"In Bearbeitung",
    "orderModel.formatOffice.Billed":"Verrechnet",
    "orderModel.formatOffice.Cleared":"Abgeschlossen",
    "orderModel.formatOffice.Canceled":"Storniert",

    "itemModel.unitType.Piece":"Stk",
    "itemModel.unitType.Kilogram":"kg",
    "itemModel.unitType.UnitType":"m²",
    "itemModel.unitType.Pair":"Paar",
    "itemModel.unitType.Set":"Set",
    "itemModel.unitType.Hours":"h",

    "itemModel.itemPricing.Highlight":"Aktion",
    "itemModel.itemPricing.PermanentAgreement":"Daueraktion",
    "itemModel.itemPricing.PromoDiscount":"Poweraktion",
    "itemModel.itemPricing.Incentive":"Incentive",

    "clearingOptions.paymentTypeCard":"Kreditkarte",
    "clearingOptions.paymentTypeSepa":"SEPA-Lastschrift",
    "clearingOptions.paymentTypePayPal":"PayPal",
    "clearingOptions.paymentTypeSofort":"Sofortüberweisung",

    "app.label":"Angemeldet als:",
    "app.shoppingCart":"Bestellen",
    "app.previewBadge":"Namensschild",
    "app.giftCard":"Gratisreinigung",
    "app.settings":"Einstellungen",
    "app.logOut":"Abmelden",
    "app.linkToContacts":"Impressum",
    "app.agb":"AGB",
    "app.orderList":"Meine Bestellungen",
    "app.copy":"2020 clesygroup GmbH",

    "selectSiteDialog.header":"Servicestation auswählen",
    "selectSiteDialog.closeButton":"Abbrechen",

    "selectAccountDialog.header":"Konto auswählen",
    "selectAccountDialog.closeButton":"Abbrechen",

    "bicField.inValidMessage":"Ungültiges Format",
    "bicField.moreChars":"Mehr Zeichen...",

    "checkBoxField.titleRequired":"Pflichtfeld",

    "badge.header":"Namensschild drucken",
    "badge.field":"Namensschild",
    "badge.button.print":"Drucken",
    "badge.select-site":"Bitte wählen Sie eine Servicestation",
    "badge.mobile":"Mobil:",
    "badge.number":"Kd.Nr:",
    "badge.header.preview":"Vorschau",

    "confirmEmail.error": "Fehler beim Bestätigen der E-Mail-Adresse.",
    "confirmEmail.success": "Ihre E-Mail-Adresse wurde bestätigt.",
    "confirmEmail.continueToShop": "Weiter zum Shop",

    "order.payment.start": "Jetzt bezahlen",
}
