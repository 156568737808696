import * as React from "react";
import {InputField} from "./InputField";

export namespace TextField {
    export interface Props extends InputField.Props {
        defaultValue?: string;
        type?: string;
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
        onBlur?: React.FocusEventHandler<HTMLInputElement>;
        onChangeValue?: (name: string, newValue: string) => void;
        onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
        required?: boolean;
        className?: string;
        value?: string | number;
        message?: string;
        messageIcon?: JSX.Element;
        input?: (instance: HTMLInputElement) => void;
        pattern?: string;
        inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search";
        minLength?: number;
        maxLength?: number;
        disabled?: boolean;
    }

    export interface State {
        hasValue: boolean;
    }
}

export class TextField extends React.Component<TextField.Props, TextField.State> {

    private input: HTMLInputElement;

    constructor(props: TextField.Props) {
        super(props);

        this.state = {hasValue: !!props.defaultValue};
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        if (this.props.onChangeValue) {
            this.props.onChangeValue(e.target.name, e.target.value);
        }

        this.updateHasValue();
    }

    private handleRef(instance: HTMLInputElement) {
        this.input = instance;
        if (this.props.input) {
            this.props.input(instance);
        }
    }

    private updateHasValue() {
        let newHasValue = !!this.input.value;

        if (this.state.hasValue != newHasValue) {
            this.setState({hasValue: newHasValue});
        }
    }

    componentDidUpdate() {
        this.updateHasValue();
    }

    render() {
        return (
            <InputField className={this.props.className}
                        name={this.props.name}
                        label={this.props.label}
                        required={this.props.required}
                        messageIcon={this.props.messageIcon}
                        message={this.props.message}>

                <input type={this.props.type || "text"}
                       name={this.props.name}
                       id={this.props.name}
                       defaultValue={this.props.defaultValue}
                       value={this.props.value}
                       pattern={this.props.pattern}
                       inputMode={this.props.inputMode}
                       ref={instance => this.handleRef(instance)}
                       required={this.props.required}
                       minLength={this.props.minLength}
                       maxLength={this.props.maxLength}
                       className={this.state.hasValue ? "not-empty" : "empty"}
                       onChange={e => this.handleChange(e)}
                       onKeyPress={this.props.onKeyPress}
                       onBlur={this.props.onBlur}
                       disabled={this.props.disabled}

                />

                {this.props.children}

            </InputField>
        );
    };
}