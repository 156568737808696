import {observer} from "mobx-react";
import * as React from "react";
import {Component} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {style} from "typestyle";
import * as masterCardLogo from "../../../images/payment/Mastercard.svg";
import * as payPalLogo from "../../../images/payment/PayPal.svg";
import * as sofortLogo from "../../../images/payment/Sofort.svg";
import * as visaLogo from "../../../images/payment/Visa.svg";
import {Signup, SignupOrder} from "../../model/Signup";
import {SiteData} from "../../model/Site";
import {clesyService} from "../../services/ClesyService";
import {RootStore} from "../../stores/RootStore";
import {createCustomEvent} from "../../util/createCustomEvent";
import {Alert} from "../controls/Alert";
import {Button} from "../controls/Button";
import {Card, CardContent, CardHeader} from "../controls/Card";
import {CheckboxField} from "../controls/CheckboxField";
import {Clickable} from "../controls/Clickable";
import {Field} from "../controls/Field";
import {FormRow} from "../controls/FormRow";
import {IbanField} from "../controls/IbanField";
import {List} from "../controls/List";
import {Logo} from "../controls/Logo";
import {RadioField} from "../controls/RadioField";
import {SelectField} from "../controls/SelectField";
import {SiteItem} from "../controls/SiteItem";
import {TextField} from "../controls/TextField";
import {View} from "../controls/View";
import InjectedIntl = ReactIntl.InjectedIntl;


interface SignupViewProps {
    active: boolean;
    onCancel: () => void;
    store: RootStore;
    intl: InjectedIntl;
}

interface SignupViewState {
    success?: boolean;
}

@observer
export class SignupView extends React.Component<SignupViewProps, SignupViewState> {

    constructor(props: SignupViewProps) {
        super(props);
        this.state = {
            success: false
        };
    }

    render() {
        return (
            <View className="clesy-register-view" active={this.props.active}>
                <div className="signup-header">
                    <h2 className={style({margin: "0.67em 0"})}>
                        <FormattedMessage id="signUpView.cardHeaderReg"/>
                    </h2>
                    <Logo/>
                </div>
                <div className="signup-content">
                    {this.renderContent()}
                </div>
            </View>
        );
    }

    renderContent() {
        const {intl, store} = this.props;
        const {siteId, promoCode} = store.signup;
        const {success} = this.state;

        if (success) {
            return <SignupSuccess onShowShop={this.handleShowShop}/>;
        }
        else if (siteId) {
            return <SignupForm intl={intl} siteId={siteId} promoCode={promoCode}
                               onSignupComplete={this.handleSignupComplete}
                               onSignupStarted={this.handleSignupStarted}
                               onSignupFailed={this.handleSignupFailed}/>;
        }
        else {
            return <SiteSearch intl={intl} onSelect={this.handleSiteSelect}/>;
        }
    }

    private handleSignupStarted = () => {
        this.props.store.signupEvents.dispatchEvent(createCustomEvent("started"));
    };

    private handleSignupFailed = () => {
        this.props.store.signupEvents.dispatchEvent(createCustomEvent("failed"));
    };

    private handleSignupComplete = (signup: Signup) => {
        const {auth, signupEvents} = this.props.store;

        signupEvents.dispatchEvent(createCustomEvent("succeeded"));

        auth.setProps({
            username: signup.email,
            password: signup.password
        });
        this.setState({success: true});
    };

    private handleSiteSelect = (site: SiteData) => {
        this.props.store.showRegister(site.siteId.toString());
    };

    private handleShowShop = () => {
        const {auth} = this.props.store;
        auth.login();
        this.props.store.showOrderList();
    };
}

//
// SignupSuccess
//

interface SignupSuccessProps {
    onShowShop: () => void;
}

class SignupSuccess extends React.Component<SignupSuccessProps> {
    render() {
        return (
            <Card>
                <CardContent>
                    <Alert type="success">
                        <FormattedMessage id="signUpView.successMessage"/>
                    </Alert>
                    <p><FormattedMessage id="signUpView.emailConfirmation"/></p>
                </CardContent>
            </Card>
        );
    }
}

//
// SignupForm
//

interface SignupFormProps {
    siteId: number;
    promoCode?: string;
    onSignupStarted: () => void;
    onSignupFailed: () => void;
    onSignupComplete: (signup: Signup) => void;
    intl: any;
}

interface SignupFormState extends Signup {
    pending?: boolean;
    site?: SiteData;
    error?: any;
}

class SignupForm extends Component<SignupFormProps, SignupFormState> {

    constructor(props: SignupFormProps) {
        super(props);

        this.state = {
            ...signupDefaults,
            promoCode: props.promoCode,
            siteId: props.siteId,
        };
    }

    private handleSalutationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({salutation: e.currentTarget.value});
    };
    private handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({title: e.currentTarget.value});
    };
    private handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({firstName: e.currentTarget.value});
    };
    private handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({lastName: e.currentTarget.value});
    };
    private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({email: e.currentTarget.value});
    };
    private handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({phone: e.currentTarget.value});
    };
    private handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({street: e.currentTarget.value});
    };
    private handleHouseNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({houseNo: e.currentTarget.value});
    };
    private handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({zip: e.currentTarget.value});
    };
    private handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({city: e.currentTarget.value});
    };
    private handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({company: e.currentTarget.value});
    };
    private handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({password: e.currentTarget.value});
    };
    private handlePasswordConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({passwordConfirm: e.currentTarget.value});
    };
    private handleAccountNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({accountNumber: e.currentTarget.value});
    };
    private handleAccountHolderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({accountHolder: e.currentTarget.value});
    };
    private handleAcceptDirectDebitChange = (name: string, checked: boolean) => {
        this.setState({acceptDirectDebit: checked});
    };
    private handleOrderChange = (name: string, value: string) => {
        this.setState({order: value as SignupOrder});
    };
    private handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({promoCode: e.currentTarget.value});
    };
    private handleAcceptTermsChange = (name: string, checked: boolean) => {
        this.setState({acceptTerms: checked});
    };
    private handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({comment: e.currentTarget.value});
    };

    private passwordConfirmInput?: HTMLInputElement;

    private getPasswordConfirmValidity() {
        const {intl} = this.props;
        return this.passwordConfirmValid ? "" : intl.formatMessage({id: "signUpView.messagePasswordInvalid"});
    }

    private get passwordConfirmValid() {
        const {password, passwordConfirm} = this.state;
        return !password || password == passwordConfirm;
    }

    componentDidUpdate() {
        if (this.passwordConfirmInput) {
            this.passwordConfirmInput.setCustomValidity(this.getPasswordConfirmValidity());
        }
    }

    async componentDidMount() {
        try {
            const site = await clesyService.getSite(this.props.siteId);
            this.setState({site});
        }
        catch (error) {
            alert("Error fetching site information. Please try again later.");
        }
    }

    render() {
        const {intl} = this.props;
        const state = this.state;
        const {site, pending, error} = state;

        const isPublicSite = site && site.publicSite;

        return (
            <div className="signup-form">

                <Card processing={!site || pending}>
                    <CardContent>
                        <h3><FormattedMessage id="signUpView.serviceStation"/></h3>
                        {site && (
                            <div>
                                <SiteItem site={site}/>
                                {isPublicSite && (
                                    <div>
                                        <p><small><FormattedMessage
                                            id="signUpView.publicSitePaymentMethodInfo"/></small></p>
                                        <p>
                                            <img className="signup-payment-logo" src={masterCardLogo}
                                                 alt="Master Card"/>
                                            <img className="signup-payment-logo" src={visaLogo} alt="Visa"/>
                                            <img className="signup-payment-logo" src={payPalLogo} alt="PayPal"/>
                                            <img className="signup-payment-logo" src={sofortLogo}
                                                 alt="Sofortüberweisung"/>
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}

                        <form onSubmit={e => this.handleSubmit(e)}>
                            <h3><FormattedMessage id="signUpView.personalDataHeader"/></h3>
                            <FormRow>
                                <SelectField name="salutation" className="salutation-field"
                                             label={intl.formatMessage({id: "signUpView.helloText"})} required
                                             value={state.salutation}
                                             onChange={this.handleSalutationChange}>
                                    <option value=""></option>
                                    <option value={intl.formatMessage({id: "signUpView.male"})}>
                                        {intl.formatMessage({id: "signUpView.male"})}
                                    </option>
                                    <option value={intl.formatMessage({id: "signUpView.female"})}>
                                        {intl.formatMessage({id: "signUpView.female"})}
                                    </option>
                                </SelectField>
                                <TextField name="title" className="title-field"
                                           label={intl.formatMessage({id: "signUpView.fieldTitle"})}
                                           value={state.title}
                                           onChange={this.handleTitleChange}
                                />
                            </FormRow>

                            <FormRow>
                                <TextField name="firstName" className="first-name-field"
                                           label={intl.formatMessage({id: "signUpView.fieldFirstName"})} required
                                           value={state.firstName}
                                           onChange={this.handleFirstNameChange}
                                />
                                <TextField name="lastName" className="last-name-field"
                                           label={intl.formatMessage({id: "signUpView.fieldSurname"})} required
                                           value={state.lastName}
                                           onChange={this.handleLastNameChange}
                                />
                            </FormRow>

                            <h3><FormattedMessage id="signUpView.contactHeader"/></h3>
                            <FormRow>
                                <TextField name="email" className="email-field"
                                           label={intl.formatMessage({id: "signUpView.fieldMail"})} required
                                           value={state.email}
                                           onChange={this.handleEmailChange}
                                />
                                <TextField name="phone" className="phone-field"
                                           label={intl.formatMessage({id: "signUpView.fieldPhone"})} required
                                           value={state.phone}
                                           onChange={this.handlePhoneChange}
                                />
                                <Field className="street-nr-field">
                                    <TextField name="street" label={intl.formatMessage({id: "signUpView.fieldStreet"})}
                                               required
                                               value={state.street}
                                               onChange={this.handleStreetChange}
                                    />
                                    <TextField name="houseNr" label={intl.formatMessage({id: "signUpView.fieldNumber"})}
                                               required
                                               value={state.houseNo}
                                               onChange={this.handleHouseNoChange}
                                    />
                                </Field>
                                <Field className="zip-city-field">
                                    <TextField name="zip" className="zip-field"
                                               label={intl.formatMessage({id: "signUpView.fieldPostcode"})} required
                                               value={state.zip}
                                               onChange={this.handleZipChange}
                                    />
                                    <TextField name="city" className="city-field"
                                               label={intl.formatMessage({id: "signUpView.fieldPlace"})} required
                                               value={state.city}
                                               onChange={this.handleCityChange}
                                    />
                                </Field>
                                <TextField name="company"
                                           label={intl.formatMessage({id: "signUpView.fieldCompany"})}
                                           required={!isPublicSite}
                                           value={state.company}
                                           onChange={this.handleCompanyChange}/>
                            </FormRow>
                            <FormRow>

                            </FormRow>

                            <h3><FormattedMessage id="signUpView.fieldPassword"/></h3>
                            <FormRow>
                                <TextField type="password" name="password" className="password-field"
                                           label={intl.formatMessage({id: "signUpView.fieldPassword"})} required
                                           value={state.password}
                                           onChange={this.handlePasswordChange}
                                />
                                <TextField type="password" name="passwordConfirm" className="password-field"
                                           label={intl.formatMessage({id: "signUpView.fieldPasswordConfirm"})}
                                           required
                                           input={input => this.passwordConfirmInput = input}
                                           value={state.passwordConfirm}
                                           onChange={this.handlePasswordConfirmChange}
                                           message={this.getPasswordConfirmValidity()}
                                />
                            </FormRow>

                            {!isPublicSite && (
                                <div>
                                    <h3><FormattedMessage id="signUpView.bankDetailsHeader"/></h3>
                                    <p><FormattedMessage id="signUpView.paymentDetails"/></p>
                                    <FormRow>
                                        <IbanField name="accountNumber" className="account-number-field"
                                                   label={intl.formatMessage({id: "signUpView.fieldIban"})}
                                                   required={true}
                                                   value={state.accountNumber}
                                                   onChange={this.handleAccountNumberChange}
                                        />
                                        <TextField name="accountHolder" className="account-holder-field"
                                                   label={intl.formatMessage({id: "signUpView.fieldAccountHolder"})}
                                                   required={true}
                                                   value={state.accountHolder}
                                                   onChange={this.handleAccountHolderChange}
                                        />
                                        <CheckboxField name="acceptDirectDebit"
                                                       intl={intl}
                                                       required={true}
                                                       checked={state.acceptDirectDebit}
                                                       onChangeValue={this.handleAcceptDirectDebitChange}
                                        >
                                            <FormattedMessage id="signUpView.paymentCheckbox"/>
                                        </CheckboxField>
                                    </FormRow>
                                    <h3><FormattedMessage id="signUpView.bagHeader"/></h3>
                                    <p><FormattedMessage id="signUpView.bagInfo"/></p>
                                    <FormRow>
                                        <RadioField name="companyOrder"
                                                    required={true}
                                                    onChangeValue={this.handleOrderChange}
                                        >
                                            <RadioField.Option value="WelcomePackage"
                                                               checked={state.order == SignupOrder.WelcomePackage}>
                                                <div className="option">
                                                    <div className="main"><FormattedMessage
                                                        id="signUpView.welcomePageMain"/>
                                                    </div>
                                                    <div className="sub"><FormattedMessage
                                                        id="signUpView.welcomePageSubField"/>
                                                    </div>
                                                </div>
                                            </RadioField.Option>
                                            <RadioField.Option value="SuitBag"
                                                               checked={state.order == SignupOrder.SuitBag}>
                                                <div className="option">
                                                    <div className="main"><FormattedMessage
                                                        id="signUpView.suitBagField"/>
                                                    </div>
                                                </div>
                                            </RadioField.Option>
                                        </RadioField>
                                    </FormRow>
                                </div>
                            )}


                            <h3><FormattedMessage id="signUpView.promoCodeHeader"/></h3>
                            <p><FormattedMessage id="signUpView.promoCodePage"/></p>
                            <div className="field-container">
                                <TextField name="promocode" className="promocode-field"
                                           label={intl.formatMessage({id: "signUpView.promoCodeHeader"})}
                                           value={state.promoCode || ""}
                                           onChange={this.handlePromoCodeChange}/>
                            </div>

                            <h3><FormattedMessage id="signUpView.termsOfServiceHeader"/></h3>
                            <FormRow>
                                <CheckboxField name="acceptTerms" required
                                               intl={intl}
                                               checked={state.acceptTerms}
                                               onChangeValue={this.handleAcceptTermsChange}
                                >
                                    <FormattedMessage id="signUpView.checkBoxFieldAccept" values={{
                                        agbUrl: <a href="http://www.clesyclean.com/agb/" target="_blank">AGBs</a>
                                    }}/>

                                </CheckboxField>
                            </FormRow>
                            <FormRow>
                                <TextField name="comment" className="comment-field"
                                           label={intl.formatMessage({id: "signUpView.textFieldComment"})}
                                           value={state.comment}
                                           onChange={this.handleCommentChange}
                                />
                            </FormRow>

                            {error && (
                                <Alert type="error">{error.toString()}</Alert>
                            )}

                            <Field className="submit-button">
                                <Button name="submit" label={intl.formatMessage({id: "signUpView.submitButton"})}
                                        primary={true}/>
                            </Field>
                        </form>
                    </CardContent>
                </Card>

            </div>
        );
    }

    private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const {onSignupStarted, onSignupComplete, onSignupFailed} = this.props;
        const {site, pending, error, ...signup} = this.state;

        try {
            onSignupStarted();
            this.setState({pending: true});
            await clesyService.signup(signup);
            onSignupComplete(signup);
        }
        catch (error) {
            console.log("Signup error", error);
            this.setState({error, pending: false});
            onSignupFailed();
        }
    };

}

const signupDefaults: Signup = {
    salutation: "",
    title: "",
    firstName: "",
    lastName: "",
    street: "",
    houseNo: "",
    zip: "",
    city: "",
    country: "AT",
    email: "",
    phone: "",
    accountNumber: "",
    accountHolder: "",
    acceptDirectDebit: false,
    comment: "",
    acceptTerms: false,
    order: undefined,
    password: "",
    passwordConfirm: "",
    promoCode: "",
    siteId: undefined,
};

//
// SiteSearch
//

interface SiteSearchProps {
    intl: any;
    onSelect: (site: SiteData) => void;
}

interface SiteSearchState {
    searchTerm: string;
    sites?: SiteData[];
    pending?: boolean;
    tooManyResults?: boolean;
}

class SiteSearch extends Component<SiteSearchProps, SiteSearchState> {

    constructor(props: SiteSearchProps) {
        super(props);
        this.state = {
            searchTerm: "",
        };
    }

    render() {
        const {searchTerm, sites, tooManyResults} = this.state;
        const {intl, onSelect} = this.props;
        return (
            <Card>
                <CardHeader className="signup-select-site-header">
                    <h2><FormattedMessage id="signUpView.selectSite"/></h2>
                    <FormattedMessage id="signUpView.selectSiteInfo"/>

                    <form onSubmit={this.handleSiteSearchSubmit}>
                        <FormRow>
                            <TextField label={intl.formatMessage({id: "signUpView.selectSiteSearchLabel"})}
                                       value={searchTerm}
                                       onChangeValue={this.handleSearchTermChangeValue}/>

                            <Button type="submit" primary icon="search" disabled={searchTerm.length < 3}>
                                Suchen
                            </Button>
                        </FormRow>
                    </form>

                </CardHeader>
                <CardContent>
                    {sites && (
                        <List>
                            {sites.map(site => (
                                <SiteSearchResultItem key={site.siteId} site={site} onClick={onSelect}/>
                            ))}
                        </List>
                    )}
                    {tooManyResults && (
                        <Alert type="error">
                            <FormattedMessage id="signUpView.tooManyResults"/>
                        </Alert>
                    )}
                </CardContent>
            </Card>
        );
    }

    private handleSiteSearchSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const {searchTerm} = this.state;
            this.setState({pending: true, tooManyResults: false});
            const sites = await clesyService.getSites(searchTerm);
            this.setState({sites, pending: false});
        }
        catch (e) {
            const tooManyResults = e.status == 416;
            if (!tooManyResults) {
                alert("Error while searching sites. Please try again later.");
            }
            this.setState({pending: false, tooManyResults});
        }
    };

    private handleSearchTermChangeValue = (name: string, searchTerm: string) => this.setState({searchTerm});
}

interface SiteSearchResultItemProps {
    site: SiteData;
    onClick: (site: SiteData) => void;
}

class SiteSearchResultItem extends Component<SiteSearchResultItemProps> {

    render() {
        return (
            <Clickable onClick={this.handleClick}>
                <SiteItem site={this.props.site} iconRight="chevron_right"/>
            </Clickable>
        );
    }

    private handleClick = () => this.props.onClick(this.props.site);
}

export default injectIntl(SignupView);