import * as React from "react";
import {TextField} from "../controls/TextField";
import {Button} from "../controls/Button";
import {View} from "../controls/View";
import {Logo} from "../controls/Logo";
import {RootStore} from "../../stores/RootStore";
import {observer} from "mobx-react";
import {ClassList} from "../controls/Base";
import {Spinner} from "../controls/Spinner";
import {FormattedMessage, injectIntl} from 'react-intl';

export namespace Login {
    export interface Props {
        active:boolean;
        onRegister:React.EventHandler<React.SyntheticEvent<HTMLButtonElement>>;
        store:RootStore;
        intl: any;
    }
}

@observer
export class Login extends React.Component<Login.Props, undefined> {

    private handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.store.auth.login();
    };

    private handleForgotPassword = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.props.store.showLostPassword();
    };

    render() {
        let auth = this.props.store.auth;
        let classList = new ClassList("clesy-login");
        if (auth.loginState && auth.loginState.pending) {
            classList.add('pending');
        }

        const {intl} = this.props;
        return (
            <View className="clesy-login" active={this.props.active}>

                <Logo />

                { auth.loginState && auth.loginState.rejected && (
                    <div className="error-message">
                        <FormattedMessage id="login.errorMessage" />
                    </div>
                )}

                { auth.logoutState && auth.logoutState.fulfilled && (
                    <div className="logout-message">
                        <FormattedMessage id="login.logoutMessage" />
                    </div>
                )}

                { !auth.logoutState && auth.sessionExpired && (
                    <div className="error-message">
                        <FormattedMessage id="login.sessionTimeout" />
                    </div>
                )}

                <div className="form-container">
                    <Spinner/>
                    <form onSubmit={e => this.handleSubmit(e)}>
                        <TextField label={intl.formatMessage({id:"login.textField.username", values:{ value: auth.username, name: "username" }})}
                                   type="email" required
                                   value={auth.username}
                                   onChange={e => auth.setProps({username: e.target.value})}/>
                        <TextField label={intl.formatMessage({id:"login.textField.password", values:{value: auth.password, name: "password"}})}
                                   type="password" required
                                   value={auth.password}
                                   onChange={e => auth.setProps({password: e.target.value})} />
                        <div className="button-container">
                            <Button name="submit" label={intl.formatMessage({id:"login.button.submit"})} primary={true} />
                        </div>
                        <div className="forgot-password">
                            <Button label={intl.formatMessage({id:"login.button.forgotPassword"})} onClick={this.handleForgotPassword} primary={false} />
                        </div>
                    </form>
                </div>
                <div className="secondary-actions">
                    <div className="register">
                        <div className="text">
                            <FormattedMessage id="login.register.text" />
                        </div>
                        <Button name="register" label={intl.formatMessage({id:"login.button.register"})} onClick={this.props.onRegister} primary={false} />
                    </div>
                </div>
            </View>
        );
    }
}

export default injectIntl(Login)