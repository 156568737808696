import {observable} from "mobx";
import {StoreBase} from "og-spa-state";
import {clesyService} from "../services/ClesyService";

export interface ConfirmEmailProps {
}

export interface ConfirmEmailState {
    pending: boolean;
    error: any;
}

export class ConfirmEmailStore extends StoreBase<ConfirmEmailProps, ConfirmEmailState> {

    @observable
    readonly pending = false;

    @observable
    readonly error:any = undefined;

    async confirm(token: string) {
        this.setState({pending: true});
        try {
            await clesyService.confirm(token);
            this.setState({pending: false, error: undefined});
        }
        catch (error) {
            this.setState({pending: false, error});
        }
    }
}