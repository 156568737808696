import {StoreBase} from "og-spa-state";
import {observable} from "mobx";
import {AddressData} from "../model/Address";

export class AddressStore extends StoreBase<AddressData, {}> implements Readonly<AddressData> {
    @observable
    readonly street: string;

    @observable
    readonly houseNo: string;

    @observable
    readonly zip: string;

    @observable
    readonly city: string;

    @observable
    readonly country: string = "AT";

    reset() {
        this.setProps({
            street: "",
            houseNo: "",
            zip: "",
            city: "",
            country: "AT"
        });
    }
}