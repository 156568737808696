import * as React from "react";

export class ItemDescription extends React.Component<{}, {}> {
    render() {
        return (
            <div className="description">
                {this.props.children}
            </div>
        );
    }
}