import {route, routeParam} from "decorouter";
import EventTarget from "event-target-shim";
import {action, observable, reaction} from "mobx";
import {SimpleStore, StoreBase} from "og-spa-state";
import {QueryString} from "../QueryString";
import {clesyService} from "../services/ClesyService";
import {AuthStore} from "./AuthStore";
import {CartStore} from "./CartStore";
import {ConfirmEmailStore} from "./ConfirmEmailStore";
import {OrderClearingStore} from "./OrderClearingStore";
import {OrderListStore} from "./OrderListStore";
import {PasswordChangeStore} from "./PasswordChangeStore";
import {PasswordLostStore} from "./PasswordLostStore";
import {PasswordResetStore} from "./PasswordResetStore";
import {ReferralStore} from "./ReferralStore";
import {UserDataStore} from "./UserDataStore";
import {ViewOrderStore} from "./ViewOrderStore";
import {AccountIbanStore} from "./AccountIbanStore";

export enum View {
    OrderList,
    OrderCreate,
    Order,
    OrderClearing,
    OrderClearingFailure,
    Settings,
    Referral,
    Signup,
    SignupComplete,
    ConfirmEmail,
    PasswordLost,
    PasswordReset,
    NoMatch,
    UserBadge,
    PublicBadge
}

interface State {
    view:View;
    needsLogin:boolean;
    navOpen:boolean;
}

@route('/')
export class RootStore extends StoreBase<{}, State> implements Readonly<State> {

    constructor() {
        super();

        reaction(() => this.auth.authenticated, authenticated => {
            if (authenticated) {
                this.passwordChange.setProps({userId: authenticated.userId });
            }
        });
    }

    @observable
    readonly auth = new AuthStore();

    @observable
    readonly cart = new CartStore(clesyService, this.auth);

    @observable
    readonly viewOrder = new ViewOrderStore(clesyService, this.auth);

    @observable
    readonly orderClearing = new OrderClearingStore(clesyService, this.auth);

    @observable
    readonly orderList = new OrderListStore(clesyService, this.auth, this.cart);

    @observable
    readonly signup = SimpleStore.fromProps({
        siteId: undefined,
        promoCode: undefined,
    });

    readonly signupEvents = new SignupEventTarget();

    @observable
    readonly referral = new ReferralStore(clesyService, this.auth);

    @observable
    readonly userData = new UserDataStore(clesyService, this.auth, this.cart);

    @observable
    readonly accountIban = new AccountIbanStore(clesyService, this.auth, this.cart);

    @observable
    readonly passwordChange = new PasswordChangeStore(clesyService);

    @observable
    readonly passwordLost = new PasswordLostStore(clesyService);

    @observable
    readonly passwordReset = new PasswordResetStore(clesyService);

    @observable
    readonly confirmEmail = new ConfirmEmailStore();

    readonly publicBadge = SimpleStore.fromProps({
        userKey: undefined as string|undefined,
    });

    @action
    public toggleNav() {
        this.setState({ navOpen: !this.navOpen })
    }

    @route('')
    public showIndex() {
        this.showOrderList();
    }

    @route('badge')
    public showPreviewBadge() {
      this.setState({view:View.UserBadge, needsLogin: true, navOpen:false})
    }

    @route('badges/:userKey')
    public showPublicBadge(@routeParam('userKey') userKey: string) {
        this.publicBadge.setProps({userKey});
        this.setState({view:View.PublicBadge, needsLogin: false, navOpen:false})
    }

    @route('orders/:id/pay(/:authKey)')
    public showOrderClearing(@routeParam('id') id:string, @routeParam('authKey') authKey?:string) {
        this.orderClearing.initClearing(Number.parseInt(id), authKey);
        this.setState({view:View.OrderClearing, needsLogin: !authKey, navOpen:false});
    }

    @route('orders/:id/failure')
    public showOrderClearingFailure(@routeParam('id') id:string) {
        this.viewOrder.fetchOrder(id, null);
        this.setState({view:View.OrderClearingFailure, needsLogin: true, navOpen:false});
    }


    @route('orders/:id(/:authKey)')
    public showOrder(@routeParam('id') id:string|number, @routeParam('authKey') authKey?:string) {
        this.viewOrder.fetchOrder(id, authKey);
        this.setState({view:View.Order, needsLogin: !authKey, navOpen:false });
    }

    @route('orders')
    public showOrderList() {
        this.setState({view:View.OrderList, needsLogin: true, navOpen:false});
    }

    @route('referral')
    public showReferral() {
        this.setState({view:View.Referral, needsLogin: true, navOpen:false});
    }

    @route('settings')
    public showSettings() {
        this.setState({view:View.Settings, needsLogin: true, navOpen:false});
    }

    @route('lost-password')
    public showLostPassword() {
        this.setState({view:View.PasswordLost, needsLogin: false, navOpen:false});
    }

    @route('passwordReset')
    public showResetPassword() {
        this.passwordReset.setProps({
            username: QueryString.get('username'),
            token: QueryString.get('token')
        });
        this.setState({view:View.PasswordReset, needsLogin: false, navOpen:false});
    }

    @route('register(/sites/:siteId)(/promo/:promoCode)')
    public showRegister(@routeParam('siteId') siteId?:string, @routeParam('promoCode') promoCode?:string) {
        if (this.auth.authenticated) {
            this.auth.logout();
        }
        this.signup.setProps({
            siteId: toInt(siteId),
            promoCode
        });
        this.setState({view:View.Signup, needsLogin: false, navOpen:false});
    }

    @route('confirm/:token')
    public showConfirm(@routeParam("token") token: string) {
        this.confirmEmail.confirm(token);
        this.setState({view:View.ConfirmEmail, needsLogin: false, navOpen: false});
    }

    @route('cart')
    public showOrderCreate() {
        this.setState({view:View.OrderCreate, needsLogin: true, navOpen:false});
    }

    @route('*path')
    public showNotFound(@routeParam('path') path:string) {
        this.setState({view:View.NoMatch, needsLogin: false, navOpen:false});
    }

    @observable
    readonly view:View;

    @observable
    readonly needsLogin:boolean;

    @observable
    readonly navOpen:boolean;

    public confirm(confirmToken:string):void {
        clesyService.confirm(confirmToken);
    }
}

function toInt(s?: string) {
    return s && Number.parseInt(s);
}

type SignupEvents = {
    started: CustomEvent;
    succeeded: CustomEvent;
    failed: CustomEvent;
}

class SignupEventTarget extends EventTarget<SignupEvents, {}> {


}