import * as React from "react";
import {Component} from "react";
import {Site} from "../../model/Site";
import {Account} from "../../model/Account";
import {User} from "../../model/User";
import {clesyService} from "../../services/ClesyService";
import {RootStore} from "../../stores/RootStore";
import {Alert} from "../controls/Alert";
import {Spinner} from "../controls/Spinner";
import {BadgeView} from "./BadgeView";
import {InjectedIntl, injectIntl} from "react-intl";

interface PublicBadgeViewProps {
    store: RootStore;
    intl: InjectedIntl;
}

interface PublicBadgeViewState {
    user?: User;
    account?: Account;
    site?: Site;
    loading?: boolean;
    error?: any;
}

class _PublicBadgeView extends Component<PublicBadgeViewProps, PublicBadgeViewState> {

    constructor(props: PublicBadgeViewProps) {
        super(props);
        this.state = {
            loading: true
        };
    }

    async componentDidMount() {
        const {userKey} = this.props.store.publicBadge;

        try {
            this.setState({loading: true});
            const user = await clesyService.getUser(userKey);
            this.setState({loading: false, user});
        }
        catch (error) {
            this.setState({loading: false, error});
        }
    }

    componentDidUpdate(prevProps: Readonly<PublicBadgeViewProps>, prevState: Readonly<PublicBadgeViewState>, snapshot?: any): void {
        const {account, site, user} = this.state;
        if (!account && user && user.accounts.length == 1) {
            this.setState({account: user.accounts[0]});
        }
        if (!site && account && account.sites.length == 1) {
            this.setState({site: account.sites[0]});
        }
    }

    render() {
        const {intl} = this.props;
        const {user, account, site, loading, error} = this.state;

        if (error) {
            return <Alert type="error">{`${error}`}</Alert>;
        }

        if (loading) {
            return <Spinner/>;
        }

        return (
            <BadgeView active={true}
                       user={user}
                       account={account}
                       site={site}
                       availableAccounts={user?.accounts || []}
                       availableSites={account?.sites || []}
                       onChangeAccount={this.handleChangeAccount}
                       onChangeSite={this.handleChangeSite}
                       enableFormats
                       intl={intl}/>
        );
    }

    private handleChangeAccount = (account: Account) => this.setState({account});
    private handleChangeSite = (site: Site) => this.setState({site});
}

export const PublicBadgeView = injectIntl(_PublicBadgeView);
