import * as React from "react";
import {Alert} from "../controls/Alert";
import {Button} from "../controls/Button";
import {View, ViewMain} from "../controls/View";
import {Card, CardContent, CardHeader} from "../controls/Card";
import {RootStore} from "../../stores/RootStore";
import {TextField} from "../controls/TextField";
import {observer} from "mobx-react";
import {Logo} from "../controls/Logo";
import {FormattedMessage, injectIntl} from 'react-intl';

export interface LostPasswordViewProps {
    active: boolean;
    store: RootStore;
    intl:any;
}
export interface LostPasswordViewState {
}

@observer
export class LostPasswordView extends React.Component<LostPasswordViewProps, LostPasswordViewState> {

    private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.store.passwordLost.submit();
    };

    render() {

        return (
            <View className="clesy-lost-password-view" active={this.props.active}>
                <ViewMain className="lost-password-form">
                    <Logo />
                    <Card>
                        <CardHeader>
                            <h2><FormattedMessage id="lostPassword.form.h2"/></h2>
                        </CardHeader>
                        <CardContent>
                            { this.renderContent() }
                        </CardContent>
                    </Card>
                </ViewMain>
            </View>
        );
    }

    private renderContent() {
        let passwordResetRequest = this.props.store.passwordLost;

        if (passwordResetRequest.state && passwordResetRequest.state.fulfilled) {
            return this.renderSuccess();
        }
        else {
            return this.renderForm();
        }
    }

    private renderSuccess() {
        return (
            <Alert type="success">
                <FormattedMessage id="lostPassword.successMessage"/>
            </Alert>
        );
    }

    private renderForm() {
        let passwordResetRequest = this.props.store.passwordLost;
        const {intl, store} = this.props;
        return (
            <form onSubmit={this.handleSubmit}>
                <p><FormattedMessage id="lostPassword.form.p"/></p>

                { passwordResetRequest.state && passwordResetRequest.state.rejected && (
                    <Alert type="error">
                        {passwordResetRequest.state.reason.toString()}
                    </Alert>
                )}

                <div className="row">
                   <TextField label={intl.formatMessage({id:"lostPassword.form.mailLabel", values:{value: passwordResetRequest.username}})}
                              type="email" required
                              onChange={e => passwordResetRequest.setProps({username: e.target.value})}/>
                </div>

                <div className="buttons">
                  <div id="left">
                    <Button primary label={intl.formatMessage({id:"lostPassword.button"})}/>
                  </div>

                  <div id="right">
                    <Button
                      label={intl.formatMessage({id:"lostPassword.backButton"})}
                      onClick={() => store.showOrderList()}
                    />
                  </div>
                </div>
            </form>

        );
    }
}

export default injectIntl(LostPasswordView)
