import {PromiseState, StoreBase} from "og-spa-state";
import {ClesyService} from "../services/ClesyService";
import {action, computed, observable} from "mobx";
import {CartStore} from "./CartStore";
import {Valid} from "./PasswordChangeStore";
import {AuthStore} from "./AuthStore";


interface AccountIbanStoreProps {
    acceptDirectDebit: boolean;
    iban: string;
    accountOwner: string;
}

interface AccountIbanStoreState {
    state:PromiseState<void>;
}

export class AccountIbanStore extends StoreBase<AccountIbanStoreProps, AccountIbanStoreState> implements Readonly<AccountIbanStoreProps> {

    constructor(private service: ClesyService, private readonly auth:AuthStore, private readonly cart:CartStore) {
        super();
    }

    @observable
    readonly iban: string;

    @observable
    readonly accountOwner: string;

    @observable
    readonly acceptDirectDebit: boolean;

    @observable
    readonly state: PromiseState<void>;

    @computed
    get isValid(): string {
        return (this.iban != '' && this.accountOwner != '' && this.acceptDirectDebit)
            ? Valid
            : 'userDataStore.requiredFieldMessage';
    }

    @action
    execute() {
        let validity = this.isValid;
        if (validity != Valid) {
            throw new Error(validity);
        }

        const request = {
            userId: this.auth.user?.userId,
            accountId: this.cart.account?.accountId,
            iban: this.iban,
            accountOwner: this.accountOwner
        };

        const promise = this.service.changeAccountIban(request)
            .then(() => this.auth.updateUser());
        this.setState({
            state: new PromiseState(promise)
        });
    }
}