
let queryParams = new Map<string, string>();

let match,
    pl     = /\+/g,  // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = (s:string) => decodeURIComponent(s.replace(pl, " ")),
    query  = window.location.search.substring(1);

while (match = search.exec(query)) {
    queryParams.set(decode(match[1]), decode(match[2]));
}

export class QueryString {
    static has(param:string) {
        return queryParams.has(param);
    }

    static get(param:string):string {
        return queryParams.get(param);
    }
}