//
// first of all, pull ES6 polyfills
//
import "core-js/es";
import "whatwg-fetch";
import "intl";
import "intl/locale-data/jsonp/de.js"


import * as React from "react";
import * as ReactDOM from "react-dom";
import {App} from "./components/App";
import {RootStore} from "./stores/RootStore";
import {PathLocationProvider, router} from "decorouter";
import {track} from "./analytics";
import {QueryString} from "./QueryString"
import * as en from 'react-intl/locale-data/en';
import * as de from 'react-intl/locale-data/de';
import {addLocaleData, IntlProvider} from 'react-intl'
import * as translations from './locales'

// TODO: onTouchTap might be needed later
// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
//import * as injectTapEventPlugin from 'react-tap-event-plugin';
//injectTapEventPlugin();

//
// Create react root
//
let root = document.createElement('div');
root.id = 'root';
document.body.appendChild(root);

//
// Initialize store
//
let store = new RootStore();

addLocaleData([...en, ...de])

const navigator = window.navigator
let language = (navigator['languages'] && navigator['languages'][0]) ||  navigator['language'] || navigator['userLanguage']

if(!language.includes('en') && (!language.includes('de') || !language.includes('gn'))) {
    language = 'de'
}

const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0]
const messages = translations[languageWithoutRegionCode] || translations[language] || translations.de

//
// Render app
//

ReactDOM.render(
  <IntlProvider
    locale={language}
    defaultLocale="de"
    messages={messages}>
    <App store={store} />
  </IntlProvider>,
  root
);

//
// Handle query string
//
if (QueryString.has('confirmToken')) {
    store.confirm(QueryString.get('confirmToken'));
}

// handle demo auth token
if(location.host != 'shop.clesyclean.com' && QueryString.has('authToken')) {
    let cred = QueryString.get('authToken').split(':');
    if (cred.length == 2) {
        store.auth.setProps({ username: cred[0], password: cred[1] });
        store.auth.login();
    }
}

//
// fixes height bug in IE
// can be removed after finally dropping support for IE (probably ~2030)
//
const userAgent = navigator.userAgent;
if (userAgent.indexOf("MSIE") || userAgent.indexOf("Trident")) {
    const fixIeHeight = () => {
        const element = document.getElementById("clesy-app-main");
        element.style.height = window.innerHeight + "px";
    };

    window.addEventListener('resize', fixIeHeight);
    fixIeHeight();
}

//
// Init Google Analytics
//
track(store);

//
// Init router
//
router.init(new PathLocationProvider());

//
// Hide static content
//
document.getElementById('static-content').style.display = 'none';
