import * as React from "react";
import {Order} from "../../model/Order";
import {CardContent, CardHeader} from "../controls/Card";
import {ItemUnit} from "../../model/Item";
import {StringUtil} from "../../util/StringUtil";
import {Button} from "../controls/Button";
import {FormattedMessage} from 'react-intl';

export const OrderStatusCardHeader = (props: { order: Order }) => {
    return (
        <CardHeader className="order-status">
            <div className="order-state">{ <FormattedMessage id={props.order.formattedState()}/> }{ props.order.createdOnCleaning && (
                <span> / <FormattedMessage id="orderCommon.reOrder"/></span>)}</div>
            <div className="order-date">{ props.order.orderDateShort }</div>
        </CardHeader>
    );
};

export const OrderPinCardContent = (props: { order: Order }) => {
    return (
        <CardContent className="cube-pin-card-content">
            <div className="cube-pin">
                <div className="label"><FormattedMessage id="orderCommon.pinLabel"/></div>
                <div className="value">{props.order.cubePinDigits.map((digit, index) => <span
                    key={index}>{digit}</span>)}</div>
                { props.order.state == 'Ordered' && (
                    <div className="pinInfo"><FormattedMessage id="orderCommon.pinInfo"/></div>
                ) }
                { props.order.state != 'Ordered' && (
                    <div className="pinInfo"><FormattedMessage id="orderCommon.pinInfoOther"/> </div>
                ) }
            </div>
        </CardContent>
    )
};

export const OrderNewerVersionContent = (props: { order:Order, onShowOrder:(orderId:number) => void }) => (
    <CardContent className="order-newer-version">
        <div className="message">
            <FormattedMessage id="orderCommon.newerVersion.message"/>
        </div>
        <div className="actions">
            <Button primary onClick={() => props.onShowOrder(props.order.related.next)}><FormattedMessage id="orderCommon.newerVersion.button"/></Button>
        </div>
    </CardContent>
);

export const OrderDetailsCardContent = (props: { order: Order }) => (
    <CardContent className="order-details">
        <table>
            <thead>
            <tr>
                <th className="name"><FormattedMessage id="orderCommon.orderDetails.table.name"/></th>
                <th className="quantity"><FormattedMessage id="orderCommon.orderDetails.table.quantity"/></th>
                <th className="total"><FormattedMessage id="orderCommon.orderDetails.table.total"/></th>
            </tr>
            </thead>
            <tbody>
            {props.order.items.map((item, index) => (
                <tr key={index}>
                    <td className="name">
                        <div className="" dangerouslySetInnerHTML={{ __html: item.name }}/>
                        <div className="options">
                            {item.options.map(option => {
                                <div>{option.name}</div>
                            })}
                        </div>
                    </td>
                    <td className="quantity">{item.quantity} <FormattedMessage id={ItemUnit.format(item.unit)}/></td>
                    <td className="total">{StringUtil.formatCurrency(item.lineTotal)}</td>
                </tr>
            ))}
            {props.order.reedemedVouchersTotal > 0 && (
                <tr key="voucher">
                    <td className="name"><FormattedMessage id="orderCommon.orderDetails.table.voucher"/></td>
                    <td className="total" colSpan={2}>
                        - {StringUtil.formatCurrency(props.order.reedemedVouchersTotal)}</td>
                </tr>
            )}
            </tbody>
            <tfoot>
            <tr>
                <td className="name"><FormattedMessage id="orderCommon.orderDetails.table.sum"/></td>
                <td className="total" colSpan={2}>{StringUtil.formatCurrency(props.order.gross)}</td>
            </tr>
            {props.order.tax > 0 && (
                <tr className="tax">
                        <td className="name"><FormattedMessage id="orderCommon.orderDetails.table.tax"/> {props.order.taxRate} % <FormattedMessage id="orderCommon.orderDetails.table.vat"/></td>
                    <td className="total" colSpan={2}>{StringUtil.formatCurrency(props.order.tax)}</td>
                </tr>
            )}
            {props.order.net > 0 && (
                <tr className="tax">
                    <td className="name"><FormattedMessage id="orderCommon.orderDetails.table.net"/></td>
                    <td className="total" colSpan={2}>{StringUtil.formatCurrency(props.order.net)}</td>
                </tr>
            )}
            </tfoot>
        </table>
        <div className="order-meta">
            <div className="delivery-dates">
                { props.order.expectedDeliveryDateShort && (
                <div className="date">
                    <div className="label"><FormattedMessage id="orderCommon.orderDetails.date"/></div>
                    <div className="value">{props.order.expectedDeliveryDateShort}</div>
                </div>
                )}
                { props.order.guaranteedDeliveryDateShort && (
                <div className="date">
                    <div className="label"><FormattedMessage id="orderCommon.orderDetails.dateTwo"/></div>
                    <div className="value">{props.order.guaranteedDeliveryDateShort}</div>
                </div>
                )}
                <div className="attention">
                    <FormattedMessage id="orderCommon.orderDetails.attentionMessage"/>
                </div>
            </div>
            { props.order.customerOrderId && (
                <div className="customer-order-id">
                    <div className="label"><FormattedMessage id="orderCommon.orderDetails.customerOrderId"/></div>
                    <div className="value">{props.order.customerOrderId}</div>
                </div>
            )}
            { props.order.supportComment && (
                <div className="clesy-comment">
                    <div className="label"><FormattedMessage id="orderCommon.orderDetails.supportComment"/></div>
                    <div className="value">{props.order.supportComment}</div>
                </div>
            )}
            { props.order.customerComment && (
                <div className="customer-comment">
                    <div className="label"><FormattedMessage id="orderCommon.orderDetails.customerComment"/></div>
                    <div className="value">{props.order.customerComment}</div>
                </div>
            )}
        </div>
    </CardContent>
);
