import * as React from 'react';
import {InputField} from "./InputField";

namespace SelectField {
    export interface Props extends InputField.Props {
        value?:string;
        type?:string;
        onChangeValue?:(name:string, newValue:string) => void;
        onChange?:(e:React.ChangeEvent<HTMLSelectElement>) => void;
        required?:boolean;
        className?:string;
    }

    export interface State {
        value:string;
    }
}

export class SelectField extends React.Component<SelectField.Props, SelectField.State> {

    constructor(props:SelectField.Props) {
        super(props);

        this.state = { value: props.value };
    }

    private handleChange(e:React.ChangeEvent<HTMLSelectElement>):void {
        this.props.onChangeValue && this.props.onChangeValue(e.target.name, e.target.value);
        this.props.onChange && this.props.onChange(e);
        this.setState({...this.state, value: e.target.value});
    }

    render() {
        return <InputField name={this.props.name} className={this.props.className} label={this.props.label} required={this.props.required}>
            <select name={this.props.name}
                   id={this.props.name}
                   value={this.state.value}
                   required={this.props.required}
                   className={this.state.value ? 'not-empty' : 'empty'}
                   onChange={e => this.handleChange(e)}>
                {this.props.children}
            </select>
        </InputField>;
    };
}