import {Order, OrderData} from "../model/Order";
import {PromisedValue, StoreBase} from "og-spa-state";
import {action, computed, observable, reaction} from "mobx";
import {AuthStore} from "./AuthStore";
import {CartStore} from "./CartStore";

export interface OrderListProps {
}

interface OrderListState {
    _orders:Order[];
    fetchState:PromisedValue<void>;
}

export interface OrderListService {
    fetchOrders(offset:number, max:number):Promise<OrderData[]>;
}

export class OrderListStore extends StoreBase<OrderListProps, OrderListState> {

    constructor(private service:OrderListService, private authStore:AuthStore, private cartStore:CartStore) {
        super();

        reaction(() => authStore.user, user => {
            if (user) {
                this.fetchOrders();
            }
            else {
                this.setState({_orders:[]});
            }
        });
        reaction(() => cartStore.orderState && cartStore.orderState.fulfilled, orderFulfilled => {
            this.setState({_orders:[]});
            this.fetchOrders();
        });
    }

    @observable
    readonly fetchState:PromisedValue<void>;

    @observable
    private readonly _orders:Order[] = [];

    @computed
    get orders():ReadonlyArray<Readonly<Order>> {
        let orders = [...this._orders];
        orders.sort((a, b) => {
            return b.orderDate - a.orderDate;
        });
        return orders;
    }

    private fetchOrders() {
        let ordersPerCall = 10;
        const loadMoreOrders = (offset:number) => {
            let promise = this.service.fetchOrders(offset, ordersPerCall)
                .then(orders => {

                    let map = new Map<number, Order>();
                    this._orders.forEach(order => map.set(order.orderId, order));
                    orders.forEach(order => map.set(order.orderId, new Order(order)));

                    this.setState({
                        _orders: Array.from(map.values())
                    });
                    if (orders.length == ordersPerCall) {
                        loadMoreOrders(offset + ordersPerCall);
                    }
                });
            this.setState({
                fetchState: new PromisedValue(promise)
            });
        };
        loadMoreOrders(0);
    }
}