import {action, observable} from "mobx";
import {observer} from "mobx-react";
import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Person} from "../../model/Person";
import {AccountIbanStore} from "../../stores/AccountIbanStore";
import {RootStore} from "../../stores/RootStore";
import {AccountItem} from "../controls/AccountItem";
import {Button} from "../controls/Button";
import {Card, CardContent, CardHeader} from "../controls/Card";
import {CheckboxField} from "../controls/CheckboxField";
import {Clickable} from "../controls/Clickable";
import {Field} from "../controls/Field";
import {FormRow} from "../controls/FormRow";
import {TextField} from "../controls/TextField";
import {View, ViewContent, ViewHeader, ViewMain} from "../controls/View";
import {SelectAccountDialog} from "../dialogs/SelectAccountDialog";

@observer
export class Settings extends React.Component<Settings.Props, undefined> {

    private passwordConfirmationInput: HTMLInputElement;

    constructor(props: Settings.Props) {
        super(props);
    }

    componentDidUpdate() {
        if (this.passwordConfirmationInput) {
            this.passwordConfirmationInput.setCustomValidity(this.props.store.passwordChange.newPasswordConfirmationValidity);
        }
    }

    @observable
    private showPasswordForm: boolean = false;

    @observable
    private showDataForm: boolean = false;

    @observable
    private showIbanForm: boolean = false;

    @observable
    private accountIban: AccountIbanStore;

    @action
    private togglePasswordForm() {
        this.showPasswordForm = !this.showPasswordForm;
    }

    @action
    private toggleDataForm() {
        const {userData, cart} = this.props.store;
        this.showDataForm = !this.showDataForm;
        if (this.showDataForm) {
            const account = cart.account;
            if (account) {
                userData.setProps({
                    email: account.email,
                    phone: account.phone
                });
                userData.person.setProps({ ...account.person });
                userData.address.setProps({ ...account.address });
            }
        }
    }

    @action
    private toggleIbanForm() {
        this.showIbanForm = !this.showIbanForm;
        if (this.showIbanForm) {
            const {cart, accountIban} = this.props.store;
            const account = cart.account;
            if (account) {
                accountIban.setProps({
                    iban: "",
                    accountOwner: "",
                    acceptDirectDebit: false
                });
            }
        }
    }

    @observable
    private showAccountSelection: boolean = false;

    @action
    private toggleAccountSelection() {
        this.showAccountSelection = !this.showAccountSelection;
    }

    render() {
        const {store, intl} = this.props;
        const {auth, cart, passwordChange, userData, accountIban} = store;
        const account = cart.account;
        const user = auth.user;
        return (
            <View className="clesy-settings-view" active={this.props.active}>
                {
                    account &&
                    <ViewMain>
                        <ViewHeader>
                            <h1><FormattedMessage id="settingsView.viewHeader"/></h1>
                        </ViewHeader>
                        <ViewContent>
                            { (!this.showPasswordForm && !this.showDataForm && !this.showIbanForm) &&
                                <Card>
                                <CardHeader>
                                    <h2><FormattedMessage id="settingsView.viewContentHeader"/></h2>
                                </CardHeader>
                                <CardContent>
                                    { user && user.accounts && user.accounts.length > 1 && (
                                        <div className="account">
                                            <h3><FormattedMessage id="orderCreate.accountH3"/></h3>
                                            <Field>
                                                <Clickable onClick={ () => this.toggleAccountSelection()}>
                                                    <AccountItem account={account} iconRight="settings" labelRight={intl.formatMessage({id:"orderCreate.labelRight"})}/>
                                                </Clickable>
                                            </Field>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div>
                                            <div className="label"><FormattedMessage id="settingsView.viewContentName"/></div>
                                            <div className="value">{ Person.formatPerson(account.person) }</div>
                                        </div>
                                    </div>
                                    <div className="row my-1">
                                        <div>
                                            <div className="label"><FormattedMessage id="settingsView.viewContentAddress"/></div>
                                            <div className="value">{ `${account.address.street} ${account.address.houseNo}, ${account.address.zip} ${account.address.city}` }</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div>
                                            <div className="label"><FormattedMessage id="settingsView.viewContentMail"/></div>
                                            <div className="value">{ account.email }</div>
                                        </div>
                                        <div>
                                            <div className="label"><FormattedMessage id="settingsView.viewContentPhone"/></div>
                                            <div className="value">{ account.phone }</div>
                                        </div>
                                    </div>
                                    <div className="my-1"/>
                                    { !account.companyAccount && (
                                        <Button label={intl.formatMessage({id:"settingsView.changeData"})} onClick={() => this.toggleDataForm()}/>
                                    )}
                                    {
                                        account.legacySepaDebit &&
                                        <div>
                                            <hr className="my-1"/>
                                            <div className="row">
                                                <div>
                                                    <div className="label"><FormattedMessage id="settingsView.fieldIban"/></div>
                                                    <div className="value">{ account.legacySepaDebit.maskedAccountNumber }</div>
                                                </div>
                                                <div>
                                                    <div className="label"><FormattedMessage id="settingsView.fieldAccountHolder"/></div>
                                                    <div className="value">{ account.legacySepaDebit.ownerName }</div>
                                                </div>
                                            </div>
                                            <div className="my-1"/>
                                            { !account.companyAccount && (
                                                <Button  label={intl.formatMessage({id:"settingsView.changeBankAccount"})} onClick={() => this.toggleIbanForm()}/>
                                            )}
                                        </div>
                                    }
                                    <hr className="my-1"/>
                                    <div className="my-1"/>
                                    <Button label={intl.formatMessage({id:"settingsView.button"})} onClick={() => this.togglePasswordForm()}/>
                                </CardContent>
                            </Card>
                            }

                            { this.showDataForm && (
                                <Card processing={userData.state && userData.state.pending}>
                                    <CardHeader>
                                        <h2><FormattedMessage id="settingsView.changeData"/></h2>
                                    </CardHeader>
                                    <CardContent>
                                        <form onSubmit={e => {
                                            e.preventDefault();
                                            userData.execute();
                                        }}>
                                            <h3><FormattedMessage id="signUpView.personalDataHeader"/></h3>
                                            <FormRow>
                                                <TextField name="firstname" className="title-field" label={intl.formatMessage({id:"signUpView.fieldFirstName"})}
                                                           defaultValue={userData.person.firstname}
                                                           onChange={e => userData.person.setProps({ firstname: e.target.value })}
                                                />
                                                <TextField name="lastname" className="title-field" label={intl.formatMessage({id:"signUpView.fieldSurname"})}
                                                           defaultValue={userData.person.lastname}
                                                           onChange={e => userData.person.setProps({ lastname: e.target.value })}
                                                />
                                            </FormRow>
                                            <h3><FormattedMessage id="signUpView.contactHeader"/></h3>
                                            <FormRow>
                                                <TextField name="email" className="email-field" label={intl.formatMessage({id:"signUpView.fieldMail"})} required
                                                           defaultValue={userData.email}
                                                           onChange={e => userData.setProps({email: e.target.value})}
                                                />
                                                <TextField name="phone" className="phone-field" label={intl.formatMessage({id:"signUpView.fieldPhone"})} required
                                                           defaultValue={userData.phone as any}
                                                           onChange={e => userData.setProps({phone: e.target.value})}
                                                />
                                                <Field className="street-nr-field">
                                                    <TextField name="street" label={intl.formatMessage({id:"signUpView.fieldStreet"})} required
                                                               defaultValue={userData.address.street}
                                                               onChange={e => userData.address.setProps({street: e.target.value})}
                                                    />
                                                    <TextField name="houseNr" label={intl.formatMessage({id:"signUpView.fieldNumber"})} required
                                                               defaultValue={userData.address.houseNo}
                                                               onChange={e => userData.address.setProps({houseNo: e.target.value})}
                                                    />
                                                </Field>
                                                <Field className="zip-city-field">
                                                    <TextField name="zip" className="zip-field" label={intl.formatMessage({id:"signUpView.fieldPostcode"})} required
                                                               defaultValue={userData.address.zip}
                                                               onChange={e => userData.address.setProps({zip: e.target.value})}
                                                    />
                                                    <TextField name="city" className="city-field" label={intl.formatMessage({id:"signUpView.fieldPlace"})} required
                                                               defaultValue={userData.address.city}
                                                               onChange={e => userData.address.setProps({city: e.target.value})}
                                                    />
                                                </Field>
                                            </FormRow>
                                            <div className="buttons">
                                                <Button primary label={intl.formatMessage({id:"settingsView.changeData"})}/>
                                                &nbsp;
                                                <Button onClick={() => this.toggleDataForm()} label={intl.formatMessage({id:"settingsView.back"})}/>
                                            </div>
                                            { userData.state && userData.state.rejected && (
                                                <div className="error-message">
                                                    {userData.state.reason.toString()}
                                                </div>
                                            )}
                                            { userData.state && userData.state.fulfilled && (
                                                <div className="success-message">
                                                    <FormattedMessage id="settingsView.successUserDataChangeMessage"/>
                                                </div>
                                            )}
                                        </form>
                                    </CardContent>
                                </Card>
                            )}

                            { this.showIbanForm && (
                                <Card processing={accountIban.state && accountIban.state.pending}>
                                    <CardHeader>
                                        <h2><FormattedMessage id="settingsView.changeBankAccount"/></h2>
                                    </CardHeader>
                                    <CardContent>
                                        <form onSubmit={e => {
                                            e.preventDefault();
                                            accountIban.execute();
                                        }}>
                                            <FormRow>
                                                <TextField name="iban" label={intl.formatMessage({id:"settingsView.fieldIban"})} required
                                                           onChange={e => accountIban.setProps({iban: e.target.value})}
                                                />
                                                <TextField name="accountOwner" label={intl.formatMessage({id:"settingsView.fieldAccountHolder"})} required
                                                           onChange={e => accountIban.setProps({accountOwner: e.target.value})}
                                                />
                                                <CheckboxField name="acceptDirectDebit"
                                                               intl={intl}
                                                               required
                                                               checked={accountIban.acceptDirectDebit}
                                                               onChangeValue={(name, value) => accountIban.setProps({acceptDirectDebit: value})}
                                                >
                                                    <FormattedMessage id="signUpView.paymentCheckbox"/>
                                                </CheckboxField>
                                            </FormRow>
                                            <div className="buttons">
                                                <Button primary label={intl.formatMessage({id:"settingsView.changeData"})}/>
                                                &nbsp;
                                                <Button onClick={() => this.toggleIbanForm()} label={intl.formatMessage({id:"settingsView.back"})}/>
                                            </div>
                                            { accountIban.state && accountIban.state.rejected && (
                                                <div className="error-message">
                                                    {accountIban.state.reason.toString()}
                                                </div>
                                            )}
                                            { accountIban.state && accountIban.state.fulfilled && (
                                                <div className="success-message">
                                                    <FormattedMessage id="settingsView.successAccountIbanChangeMessage"/>
                                                </div>
                                            )}
                                        </form>
                                    </CardContent>
                                </Card>
                            )}

                            { this.showPasswordForm && (
                                <Card processing={passwordChange.state && passwordChange.state.pending}>
                                    <CardHeader>
                                        <h2><FormattedMessage id="settingsView.cardHeader"/></h2>
                                    </CardHeader>
                                    <CardContent>
                                        <form onSubmit={e => {
                                            e.preventDefault();
                                            passwordChange.execute()
                                        }}>
                                            <p><FormattedMessage id="settingsView.p1"/></p>
                                            <div className="row">
                                                <TextField type="password" label={intl.formatMessage({id:"settingsView.oldPassword"})} required
                                                           value={passwordChange.oldPassword || ''}
                                                           onChange={e => passwordChange.setProps({oldPassword: e.target.value})}
                                                />
                                            </div>
                                            <p><FormattedMessage id="settingsView.p2"/></p>
                                            <div className="row">
                                                <TextField type="password" label={intl.formatMessage({id:"settingsView.newPasswordOne"})} required
                                                           value={passwordChange.newPassword || ''}
                                                           onChange={e => passwordChange.setProps({newPassword: e.target.value})}/>
                                                <TextField type="password" label={intl.formatMessage({id:"settingsView.newPasswordTwo"})} required
                                                           value={passwordChange.newPasswordConfirmation || ''}
                                                           message={passwordChange.newPasswordConfirmationValidity !== '' ? intl.formatMessage({id:passwordChange.newPasswordConfirmationValidity}) : passwordChange.newPasswordConfirmationValidity}
                                                           onChange={e => passwordChange.setProps({newPasswordConfirmation: e.target.value})}
                                                           input={input => this.passwordConfirmationInput = input}
                                                />
                                            </div>
                                            { passwordChange.state && passwordChange.state.rejected && (
                                                <div className="error-message">
                                                    {passwordChange.state.reason.toString()}
                                                </div>
                                            )}
                                            { passwordChange.state && passwordChange.state.fulfilled && (
                                                <div className="success-message">
                                                    <FormattedMessage id="settingsView.successChangeMessage"/>
                                                </div>
                                            )}
                                            <div className="buttons">
                                                <Button primary label={intl.formatMessage({id:"settingsView.button"})}/>
                                                &nbsp;
                                                <Button onClick={() => this.togglePasswordForm()} label={intl.formatMessage({id:"settingsView.back"})}/>
                                            </div>
                                        </form>
                                    </CardContent>
                                </Card>
                            )}
                        </ViewContent>
                    </ViewMain>
                }


                <SelectAccountDialog active={this.showAccountSelection}
                                     onSelect={(a) => {
                                         cart.setProps({ account: a });
                                         this.toggleAccountSelection();
                                     }}
                                     onClose={() => this.toggleAccountSelection()}
                                     accounts={user ? user.accounts : []}/>

            </View>
        );
    }
}

export namespace Settings {
    export interface Props {
        active: boolean;
        store: RootStore;
        intl:any;
    }


}
export default injectIntl(Settings)

