import {ItemUnitType} from "./Item";

export interface OrderItemData {
    itemId:number;
    name:string;
    description:string;
    articleNumber:string;
    quantity:number;
    unit:ItemUnitType;
    price:number;
    options:OrderItemOptionData[];
}

export interface OrderItemOptionData {
    itemOptionId:number;
    articleNumber:string;
    name:string;
    unit:ItemUnitType;
    price:number;
}

export interface BillData {
    documentUrl:string;
}

export interface OrderData {
    orderId:number;
    orderNumber:string;
    state:string;
    cubePin:string;
    cubeHostname:string;
    orderDate:number;
    orderDateShort:string;
    expectedDeliveryDate:null;
    expectedDeliveryDateShort:null;
    guaranteedDeliveryDate:null;
    guaranteedDeliveryDateShort:null;
    items:OrderItemData[];
    total:number;
    bill:BillData;
    related:RelatedOrderData;
    customerComment:string;
    customerOrderId:string;
    supportComment:string;
    createdOnCleaning:boolean;
    cubeOrderState?: CubeOrderStatus;
}

export enum CubeOrderStatus {
    /** Customer created the order */
    Ordered = "Ordered",

    /** Clothes were stored by customer and are waiting cleaner pickup */
    WaitingPickup = "WaitingPickup",

    /** Currently at cleaner */
    Cleaning = "Cleaning",

    /** Cleaner has returned and clothes, are waiting for pickup by customer */
    WaitingReturn = "WaitingReturn",

    /** Customer picked up the clothes */
    Completed = "Completed",
}

export interface RelatedOrderData {
    previous:number;
    information:string;
    next:number;
}

export class OrderItem implements OrderItemData {

    constructor(orderItem?:OrderItemData) {
        if (orderItem) {
            Object.assign(this, orderItem);
        }
    }

    name:string;
    itemId:number;
    description:string;
    articleNumber:string;
    quantity:number;
    unit:ItemUnitType;
    price:number;
    options:OrderItemOptionData[];

    public get lineTotal():number {
        return this.price;
    }
}

export class OrderState {
    private static instances:Map<string, OrderState> = new Map<string, OrderState>();

    public static readonly Ordered = new OrderState('Ordered');    // customer has ordered but the order is not processed yet
    public static readonly Cleaning = new OrderState('Cleaning');  // cleaner has picked it up
    public static readonly Returned = new OrderState('Returned');  // cleaner has returned the order
    public static readonly Billed = new OrderState('Billed');	   // order is on a bill
    public static readonly Cleared = new OrderState('Cleared');    // user has cleared the bill
    public static readonly Canceled = new OrderState('Canceled');  // the order was canceled

    public static get All():OrderState[] {
        let all:OrderState[] = [];
        this.instances.forEach(state => all.push(state));
        return all;
    }

    private constructor(private _id:string) {
        OrderState.instances.set(this._id, this);
    }

    public get id():string {
        return this._id;
    }

    public static fromString(state:string):OrderState {
        if (OrderState.instances.has(state)) {
            return OrderState.instances.get(state);
        }
        else {
            throw new Error('Invalid order state: ' + state);
        }
    }

    public format():string {
        switch (this) {
            case OrderState.Ordered: return 'orderModel.format.Ordered';
            case OrderState.Cleaning: return 'orderModel.format.Cleaning';
            case OrderState.Returned: return 'orderModel.format.Returned';
            case OrderState.Billed: return 'orderModel.format.Billed';
            case OrderState.Cleared: return 'orderModel.format.Cleared';
            case OrderState.Canceled: return 'orderModel.format.Canceled';
        }
    }

    public formatOffice():string {
        switch(this) {
            case OrderState.Ordered:
            case OrderState.Cleaning:
            case OrderState.Returned: return 'orderModel.formatOffice.Returned';
            case OrderState.Billed: return 'orderModel.formatOffice.Billed';
            case OrderState.Cleared: return 'orderModel.formatOffice.Cleared';
            case OrderState.Canceled: return 'orderModel.formatOffice.Canceled';
        }
    }

    public needsPayment():boolean {
        return (this === OrderState.Billed);
    }
}

export class Order implements OrderData {

    constructor(order?:OrderData) {
        if (order) {
            Object.assign(this, order);
            // special treatment for items
            if (order.items) {
                this.items = order.items.map(item => new OrderItem(item));
            }
        }
    }

    orderId:number;
    orderNumber:string;
    cubePin:string;
    cubeHostname:string;
    state:string;
    orderDate:number;
    orderDateShort:string;
    expectedDeliveryDate:null;
    expectedDeliveryDateShort:null;
    guaranteedDeliveryDate:null;
    guaranteedDeliveryDateShort:null;
    items:OrderItem[] = [];

	reedemedVouchersTotal:number;
	
	net:number;
	taxRate:number;
	tax:number;
	gross:number;

    total:number;
    bill:BillData;
    related:RelatedOrderData;

    customerComment:string;
    customerOrderId:string;
    supportComment:string;
    createdOnCleaning:boolean;
    cubeOrderState?: CubeOrderStatus;

    get stateObject():OrderState {
        return OrderState.fromString(this.state);
    }

    set stateObject(state:OrderState) {
        this.state = state.id;
    }

    public formattedState():string {
        if(this.cubePin) {
            return this.stateObject.format();
        }else{
            return this.stateObject.formatOffice();
        }
    }

    get cubePinDigits():string[] {
        if (this.cubePin) {
            let pinDigits = [];
            for (let digit of this.cubePin) {
                pinDigits.push(digit);
            }
            return pinDigits;
        }
        return [];
    }

    public needsPayment():boolean {
        return (this.related && !this.related.next) && this.cubePin && this.stateObject.needsPayment();
    }

    public isAwaitingCubeAction():boolean {
        return this.cubePin && (this.cubeOrderState == CubeOrderStatus.Ordered || (this.cubeOrderState == CubeOrderStatus.WaitingReturn && !this.needsPayment()));
    }

    public hasNewerVersion():boolean {
        return !!this.related.next;
    }
}
