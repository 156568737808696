import {PromisedValue, StoreBase} from "og-spa-state";
import {action, observable, reaction} from "mobx";
import {Order, OrderData} from "../model/Order";
import {AuthStore} from "./AuthStore";

export interface ViewOrderProps {
}

interface ViewOrderState {
    orderId:number;
    authKey:string;
    order:PromisedValue<Order>;
}

export interface ViewOrderService {
    fetchOrder(id:number, authKey?:string):Promise<OrderData>;
}

export class ViewOrderStore extends StoreBase<ViewOrderProps, ViewOrderState> {

    constructor(private service:ViewOrderService, private auth:AuthStore) {
        super();

        reaction(() => auth.authenticated, authenticated => { this.fetchOrder(this.orderId, this.authKey) });
    }

    @observable
    private readonly orderId:number;

    @observable
    private readonly authKey:string;

    @observable
    readonly order:PromisedValue<Order>;

    @action
    public fetchOrder(orderId:string|number, authKey:string) {
        if (typeof orderId === 'string') {
            orderId = Number.parseInt(orderId);
        }

        let promisedOrder = null;
        if (orderId) {
            if (this.auth.authenticated) {
                let promise = this.service.fetchOrder(orderId, authKey)
                    .then(orderData => new Order(orderData));

                promisedOrder = new PromisedValue(promise);
            }
        }
        this.setState({orderId: orderId, authKey: authKey, order: promisedOrder});
    }
}