

import {Order, OrderData} from "./Order";
import {PromisedValue} from "og-spa-state";

export interface PaymentType {
    BANCONTACT_MISTERCASH:string;
    CCARD:string;
    CCARDMOTO:string;
    EKONTO:string;
    SEPADD:string;
    EPS:string;
    GIROPAY:string;
    IDL:string;
    INSTALLMENT:string;
    INVOICE:string;
    MAESTRO:string;
    MONETA:string;
    PRZELEWY24:string;
    PAYPAL:string;
    PBX:string;
    POLI:string;
    PSC:string;
    QUICK:string;
    SKRILLWALLET:string;
    SOFORTUEBERWEISUNG:string;
    TRUSTLY:string;
    VOUCHER:string;
}

export type PaymentTypeType = keyof PaymentType;


export namespace PaymentType {
    export const CCARD:PaymentTypeType = 'CCARD';
    export const SEPADD:PaymentTypeType = 'SEPADD';
    export const PAYPAL:PaymentTypeType = 'PAYPAL';
    export const SOFORTUEBERWEISUNG:PaymentTypeType = 'SOFORTUEBERWEISUNG';

    export function label(paymentType:PaymentTypeType) {
        switch (paymentType) {
            case PaymentType.CCARD: return 'clearingOptions.paymentTypeCard';
            case PaymentType.SEPADD: return 'clearingOptions.paymentTypeSepa';
            case PaymentType.PAYPAL: return 'clearingOptions.paymentTypePayPal';
            case PaymentType.SOFORTUEBERWEISUNG: return 'clearingOptions.paymentTypeSofort';
            default: return 'Unimplemented payment type ' + paymentType;
        }
    }
}

export interface ClearingProvider {
    Wirecard:string;
    Dummy:string;
}

export type ClearingProviderType = keyof ClearingProvider;

export namespace ClearingProvider {
    export const Wirecard:ClearingProviderType = 'Wirecard';
    export const Dummy:ClearingProviderType = 'Dummy';
}

export interface ClearingOptionsData {
    order:OrderData;
    clearingUuid:string;
    clearingAllowed:boolean;
    monthlySepaDebitAllowed:boolean;
    options:ClearingOptionData[];
}

export interface ClearingOptionData {
    clearingOptionUuid:string;
    ownerName:string;
    maskedAccountNumber:string;
    paymentType:PaymentTypeType;
    financialInstitution:string;
    expiryYear:string;
    expiryMonth:string;
    init:InitClearingInformationData;
}

export interface InitClearingInformationData {
    storageId:string;
    javascriptUrl:string;
    clearingProvider:ClearingProviderType;
}

export class ClearingOptions implements ClearingOptionsData {
    constructor(data?:ClearingOptionsData) {
        if (data) {
            Object.assign(this, data);

            this.order = new Order(data.order);
        }
    }

    order:Order;
    clearingUuid:string;
    clearingAllowed:boolean;
    monthlySepaDebitAllowed:boolean;
    options:ClearingOptionData[];

    getOption(clearingOptionUuid:string) {
        for (let option of this.options) {
            if (option.clearingOptionUuid == clearingOptionUuid) {
                return option;
            }
        }
        return null;
    }
}

export class PromisedClearingOptions extends PromisedValue<ClearingOptions> {
    constructor(public readonly id:string, promise:Promise<ClearingOptions>) {
        super(promise);
    }
}
