import {action, observable, reaction} from "mobx";
import {PromisedValue, StoreBase} from "og-spa-state";
import {Referral} from "../model/Referral";
import {AuthStore} from "./AuthStore";

export interface ReferralProps {
}

interface ReferralState {
    emails:string[];
    state:PromisedValue<void>;
}

export interface ReferralService {
    refer(referral:Referral):Promise<void>;
}

export class ReferralStore extends StoreBase<ReferralProps, ReferralState> {

    constructor(private service:ReferralService, private auth:AuthStore) {
        super();

        reaction(() => auth.authenticated, authenticated =>{
            if (!authenticated) {
                this.clear();
            }
        })
    }

    @observable
    readonly emails:ReadonlyArray<string> = [];

    @observable
    readonly state:PromisedValue<void>;

    @action
    setEmail(index:number, email:string) {
        let newEmails = [...this.emails];
        newEmails[index] = email;
        ReferralStore.compactEmails(newEmails, false)
        this.setState({
            emails: newEmails
        });
    }

    private static compactEmails(emails:string[], all:boolean) {
        // delete empty entries
        for (let i=emails.length-1; i >= 0; i--) {
            if (!emails[i]) {
                emails.splice(i, 1);
            }
            else if (!all) {
                break;
            }
        }
    }

    public clear() {
        this.setState({
            emails:[],
            state:null
        });
    }

    @action
    submit() {
        let emails = [...this.emails];
        ReferralStore.compactEmails(emails, true);

        let promise = this.service.refer({
            referrals: emails.map(email => {
                return {
                    person: null,
                    email: email
                }
            })
        });

        this.setState({
            state: new PromisedValue(promise)
        });
    }
}