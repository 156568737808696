import {Item, ItemData} from "./Item";

export interface CategoryData {
    categoryId:number;
    name:string;
    description:string;
    items:ItemData[];
}

export class Category implements CategoryData {
    categoryId:number;
    name:string;
    description:string;
    items:Item[];

    constructor(categoryData?:CategoryData) {
        if (categoryData) {
            Object.assign(this, categoryData);

            if (categoryData.items) {
                this.items = categoryData.items.map(itemData => new Item(itemData));
            }
        }
    }
}
