import * as React from "react";
import {View, ViewMain, ViewContent} from "../controls/View";
import {RootStore} from "../../stores/RootStore";
import {Card, CardContent, CardHeader} from "../controls/Card";
import {observer} from "mobx-react";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    OrderDetailsCardContent, OrderNewerVersionContent, OrderPinCardContent,
    OrderStatusCardHeader
} from "./OrderCommon";
import {
    ClearingOptions, ClearingProvider, InitClearingInformationData, PaymentType,
    PaymentTypeType
} from "../../model/ClearingOptions";
import {Button} from "../controls/Button";
import {TextField} from "../controls/TextField";
import * as CreditCardValidator from "card-validator";
import {BicField} from "../controls/BicField";
import {IbanField} from "../controls/IbanField";
import {Spinner} from "../controls/Spinner";
import {PaymentFlow} from "@variocube/payment-lib";
import {OrderState} from "../../model/Order";
import {clesyService} from "../../services/ClesyService";

export namespace OrderClearing {
    export interface Props {
        active:boolean;
        store:RootStore;
        onShowOrders:() => void;
        onShowOrder:(orderId:number) => void;
        intl: any;
    }
    export interface State {
        clearingOptionUuid?:string;
        errors?:DataStorageError[];
        isPending:boolean;
        paymentOpen: boolean;
    }
}

const WirecardDemoCreditCardNumbers = new Set<string>([
    '9500000000000002', // Mastercard without Mastercard SecureCode (SSL only)
    '9500000000000001', // Mastercard with Mastercard SecureCode
    '9400000000000004', // Visa without Verified by Visa (SSL only)
    '9400000000000003', // Visa with Verified by Visa
    '9600000000000005', // Maestro with Maestro SecureCode
    '9100000000000006', // American Express (SSL only)
    '9100000000000005', // American Express with American Express SafeKey
    '9200000000000007', // Diners Club (SSL only)
    '9300000000000008', // JCB (SSL only)
    '9110000000000010', // UATP (SSL only)
    '9090000000000011', // Discover (SSL only)
]);

@observer
export class OrderClearing extends React.Component<OrderClearing.Props, OrderClearing.State> {

    private creditCardPaymentInformation:CreditCardPaymentInformation = {
        pan: '',
        expirationMonth: '',
        expirationYear: ''
    };

    private sepaPaymentInformation:SepaPaymentInformation = {
        accountOwner: '',
        bankAccountIban: '',
        bankBic: ''
    };

    constructor(props:OrderClearing.Props) {
        super(props);

        this.state = {
            isPending: false,
            paymentOpen: false
        };
    }

    private ensureJavascriptLoaded(url:string):Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let found = false;
            for (let script of Array.from(document.getElementsByTagName('script'))) {
                if (script.src == url) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                let element = document.createElement('script');
                element.src = url;
                element.onload = e => resolve();
                document.head.appendChild(element);
            }
            else {
                resolve();
            }
        });
    }

    private initWirecard(paymentType:string):Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let dataStorage = new WirecardCEE_DataStorage();

            switch (paymentType) {
                case PaymentType.CCARD:
                    if(this.creditCardPaymentInformation.pan) {
                        this.creditCardPaymentInformation.pan = this.creditCardPaymentInformation.pan.replace(/[^\d]/g, '');
                    }
                    dataStorage.storeCreditCardInformation(this.creditCardPaymentInformation, (response) => {
                        if (response.getStatus() == 0) {
                            resolve();
                        }
                        else {
                            reject(response.getErrors());
                        }
                    });
                    break;

                case PaymentType.SEPADD:
                    dataStorage.storeSepaDdInformation(this.sepaPaymentInformation, (response) => {
                        if (response.getStatus() == 0) {
                            resolve();
                        }
                        else {
                            reject(response.getErrors());
                        }
                    });
                    break;

                default:
                    // nothing to save
                    resolve();
                    break;
            }

        });

    }

    private initPayment(init:InitClearingInformationData, paymentType:string):Promise<void> {
        let scriptPromise = init.javascriptUrl ? this.ensureJavascriptLoaded(init.javascriptUrl) : Promise.resolve();
        return scriptPromise.then(() => {
            switch (init.clearingProvider) {

                case ClearingProvider.Dummy:
                    return Promise.resolve();

                case ClearingProvider.Wirecard:
                    return this.initWirecard(paymentType);

                default:
                    throw new Error('clearingProvider not implemented');
            }
        });
    }

    handlePaymentConfirmed = () => {
        this.setState({
            paymentOpen: false,
            isPending: true
        });
        const interval = window.setInterval(async () => {
            let promisedClearing = this.props.store.orderClearing.clearing;
            if (promisedClearing.fulfilled) {
                let clearing = promisedClearing.value;
                const clearingOptions = await clesyService.verifyClearing(clearing.order.orderId);
                console.log('New clearing status: ', clearingOptions.order.state);
                if (OrderState.fromString(clearingOptions.order.state) === OrderState.Cleared) {
                    window.clearInterval(interval);
                    window.location.replace('/orders/' + clearing.order.orderId);
                }
            }
        }, 5000);
    };

    handleSubmit(e:React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        let promisedClearing = this.props.store.orderClearing.clearing;
        if (promisedClearing.fulfilled) {
            this.setState({ isPending: true })

            let clearing = promisedClearing.value;
            let option = clearing.getOption(this.state.clearingOptionUuid);
            let initPromise = option.init ? this.initPayment(option.init, option.paymentType) : Promise.resolve();

            initPromise
                .then(() => {
                    this.props.store.orderClearing.perform(this.state.clearingOptionUuid)
                        .then(redirectUrl => window.location.href = redirectUrl)
                        .catch(reason => {
                            let arr: DataStorageError[] = [{
                                errorCode: 0,
                                message: reason,
                                consumerMessage: reason.message
                            }];
                            this.setState({...this.state, errors: arr as DataStorageError[]});
                        });
                })
                .catch(reason => {
                    this.setState({...this.state, errors: reason as DataStorageError[]});
                })
        }
    }

    togglePaymentOpen = () => this.setState({
        paymentOpen: !this.state.paymentOpen
    });

    render() {
        return (
            <View className="clesy-order-clearing" active={this.props.active}>
                <ViewMain>
                    <ViewContent>
                        { this.renderClearing() }
                    </ViewContent>
                </ViewMain>
            </View>
        );
    }

    renderClearing() {
        const {intl} = this.props;
        let promisedClearing = this.props.store.orderClearing.clearing;
        if (promisedClearing) {
            if (promisedClearing.fulfilled) {
                let clearing = promisedClearing.value;

                if (clearing.clearingAllowed) {
                    return (
                        <Card>
                            <OrderStatusCardHeader order={clearing.order} />
                            { clearing.order.hasNewerVersion() &&
                                <OrderNewerVersionContent order={clearing.order} onShowOrder={this.props.onShowOrder}/>
                            }
                            <OrderDetailsCardContent order={clearing.order} />
                            { this.renderClearingForm(clearing) }
                        </Card>
                    );
                }
                else {
                    return (
                        <Card>
                            <OrderStatusCardHeader order={clearing.order} />
                            <CardContent className="clearing-success">
                                <h2><FormattedMessage id="orderClearing.clearingSuccess.h2"/></h2>
                                <p><FormattedMessage id="orderClearing.clearingSuccess.p"/></p>
                            </CardContent>
                            { clearing.order.isAwaitingCubeAction() && <OrderPinCardContent order={clearing.order} /> }
                            <CardContent className="clearing-success">
                                <p>
                                    <Button primary={true} onClick={e => this.props.onShowOrders()}> {intl.formatMessage({id:"orderClearing.clearingSuccess.button"})}</Button>
                                </p>
                            </CardContent>
                        </Card>
                    )
                }
            }
            else if (promisedClearing.pending) {
                return (
                    <Card>
                        <CardHeader>
                            {intl.formatMessage({id:"orderClearing.clearingSuccess.cardHeader"})}
                        </CardHeader>
                    </Card>
                );
            }
            else if (promisedClearing.rejected) {
                return (
                    <Card>
                        <CardHeader>
                            {promisedClearing.reason}
                        </CardHeader>
                    </Card>
                );
            }
        }
        else {
            return (
                <Card>
                    <CardHeader>{intl.formatMessage({id:"orderClearing.clearingSuccess.cardHeaderOther"})}</CardHeader>
                </Card>
            );
        }
    }

    renderClearingForm(clearing:ClearingOptions) {
        const {intl} = this.props;
        if (this.state.isPending) return (
            <CardContent>
                <h3 className="clearing-wait">Der Bezahlvorgang wird vorbereitet ....</h3>
                <div className="clearing-wait">Sie werden in Kürze weitergeleitet.</div>
                <Spinner/>
            </CardContent>
        );
        const {paymentOpen} = this.state;
        return (
            <CardContent>
                <Button primary onClick={this.togglePaymentOpen}>{intl.formatMessage({ id: 'order.payment.start' })}</Button>
                { paymentOpen && (
                    <PaymentFlow paymentId={clearing.clearingUuid}
                                 onSucceeded={this.handlePaymentConfirmed}
                                 onClose={this.togglePaymentOpen}
                                 live={window.location.hostname.indexOf('shop.clesyclean.com') > -1} />
                )}
            </CardContent>
        )
    }

    renderClearingFields(paymentType:PaymentTypeType) {
        switch (paymentType) {
            case PaymentType.CCARD:
                return <CreditCardFields paymentInformation={this.creditCardPaymentInformation} intl={this.props.intl} />;

            case PaymentType.SEPADD:
                return <SepaFields paymentInformation={this.sepaPaymentInformation} intl={this.props.intl} />;

            case PaymentType.PAYPAL:
            case PaymentType.SOFORTUEBERWEISUNG:
                return null;

            default: return (
                <div>Unimplemented payment type: {paymentType}</div>
            );
        }
    }
}

class SepaFields extends React.Component<SepaFields.Props, undefined> {

    render() {
        const {intl} = this.props.intl;
        return (
            <div className="sepa-fields">
                <div className="iban-bic">
                    <IbanField
                        label={intl.formatMessage({id:"orderClearing.sepaFields.sepaAccountIbanLabel"})}
                        name="sepaAccountIban"
                        onChangeValue={(name, value) => this.props.paymentInformation.bankAccountIban = value }
                        required
                    />

                    <BicField
                        label={intl.formatMessage({id:"orderClearing.sepaFields.sepaAccountBicLabel"})}
                        name="sepaAccountBic"
                        onChangeValue={(name, value) => this.props.paymentInformation.bankBic = value }
                        required
                    />
                </div>

                <TextField
                    label={intl.formatMessage({id:"orderClearing.sepaFields.sepaAccountHolderLabel"})}
                    name="sepaAccountHolder"
                    onChangeValue={(name, value) => this.props.paymentInformation.accountOwner = value }
                    required
                />
            </div>
        )
    }
}

namespace SepaFields {
    export interface Props {
        paymentInformation:SepaPaymentInformation;
        intl:any;
    }
}

class CreditCardFields extends React.Component<CreditCardFields.Props, CreditCardFields.State> {

    constructor(props:CreditCardFields.Props) {
        super(props);

        this.state = {
            numberState: { state: null, message:'' },
            expiryState: { state: null, message:'' },
            codeName: 'CCV',
            codeLength: 3
        }
    }

    isNumericOrWhitespace(charCode:number) {
        // character must be a digit (or a space we've automatically inserted)
        return /[\d\s]/.test(String.fromCharCode(charCode));
    }

    isNumericOrSlash(charCode:number) {
        // character must be a digit (or a space we've automatically inserted)
        return /[\d\/]/.test(String.fromCharCode(charCode));
    }

    hasNumericOrWhitespaceChars(value:string) {
        return Array.from(value).map( c => c.charCodeAt(0)).reduce((a, b) => a && this.isNumericOrWhitespace(b), true);
    }

    hasNumericOrSlashChars(value:string) {
        return Array.from(value).map( c => c.charCodeAt(0)).reduce((a, b) => a && this.isNumericOrSlash(b), true);
    }

    formatCardNumber(cardNumber:string) {
        let num = cardNumber.replace(/\D/g, '');
        const card = CreditCardValidator.number(num).card;

        if (!card) {
            return num;
        }

        const upperLength = card.lengths[card.lengths.length - 1];

        num = num.slice(0, upperLength);

        const groups:string[] = [];

        card.gaps.concat(num.length).reduce((a,b) => {
            groups.push(num.slice(a,b));
            return b;
        }, 0);

        return groups.filter(g => g.length > 0).join(' ');
    }

    validateCreditCardNumber(target:HTMLInputElement, strict:boolean) {

        target.value = target.value.replace(/[^\d\s]/g, '');

        let value = target.value;

        const {intl} = this.props;
        let numberState:CreditCardFields.FieldState = { state: 'error', message: intl.formatMessage({id:"orderClearing.numberState"})};
        let codeLength = this.state.codeLength;
        let codeName = this.state.codeName;

        if (this.hasNumericOrWhitespaceChars(value)) {
            let validation = CreditCardValidator.number(value);
            if (validation.isPotentiallyValid) {
                target.value = this.formatCardNumber(value);

                if (validation.isValid) {
                    numberState = { state: 'success', message: validation.card.niceType };
                }
                else if (!strict) {
                    numberState = { state: null, message: validation.card ? validation.card.niceType + '...' : '' };
                }
            }
            if (validation.card) {
                if (validation.card.code) {
                    codeLength = validation.card.code.size;
                    codeName = validation.card.code.name;
                }
            }

            if (WirecardDemoCreditCardNumbers.has(value.replace(/[^\d]/g, ''))) {
                numberState = { state: 'success', message: intl.formatMessage({id:"orderClearing.demoCreditCard"}) };
            }
        }

        target.setCustomValidity(numberState.state != 'success' ? numberState.message : '');
        this.setState({...this.state, numberState: numberState, codeLength:codeLength, codeName:codeName});
    }

    formatCardExpiry(cardExpiry:string) {
        // automatically insert slash, but avoid double slash
        if (cardExpiry.length > 2 && cardExpiry.charAt(2) != '/') {
            cardExpiry = cardExpiry.substr(0, 2) + '/' + cardExpiry.substr(2);
            cardExpiry = cardExpiry.replace('//', '/');
        }
        // automatically prepend leading zero, if month was entered with only one digit
        let parts = cardExpiry.split('/');
        if (parts.length >= 2) {
            if (parts[0].length == 1) {
                cardExpiry = '0' + cardExpiry;
            }
        }
        return cardExpiry;
    }

    validateCreditCardExpiry(target:HTMLInputElement, strict:boolean) {

        target.value = target.value.replace(/[^\d\/]/g, '');
        const {intl} = this.props;
        let state:CreditCardFields.FieldState = { state: null, message: null };

        if (this.hasNumericOrSlashChars(target.value)) {
            if (strict) {
                target.value = this.formatCardExpiry(target.value);
            }
            let validation = CreditCardValidator.expirationDate(target.value);

            if (validation.isValid) {
                state.state = 'success';

                // save data to paymentInformation
                this.props.paymentInformation.expirationMonth = validation.month;
                this.props.paymentInformation.expirationYear = validation.year;
            }
            else if (!validation.isPotentiallyValid) {
                if (!validation.year || !validation.month) {
                    state = { state: 'error', message: intl.formatMessage({id:"orderClearing.validationFormat"}) };
                }
                else {
                    state = { state: 'error', message: intl.formatMessage({id:"orderClearing.validationCart"}) };
                }
            }
        }

        target.setCustomValidity(state.state != 'success' ? state.message : '');
        this.setState({...this.state, expiryState: state});
    }

    handleCreditCardNumberChange(e:React.ChangeEvent<HTMLInputElement>) {
        this.validateCreditCardNumber(e.currentTarget, false);
    }

    handleCreditCardNumberBlur(e:React.FocusEvent<HTMLInputElement>) {
        this.validateCreditCardNumber(e.currentTarget, true);
    }

    handleCreditCardExpiryChange(e:React.ChangeEvent<HTMLInputElement>) {
        this.validateCreditCardExpiry(e.currentTarget, false);
    }

    handleCreditCardExpiryBlur(e:React.FocusEvent<HTMLInputElement>) {
        this.validateCreditCardExpiry(e.currentTarget, true);
    }

    render() {
        const {intl} = this.props;
        return (
            <div className="credit-card-fields">
                <div className="number-holder">
                    <TextField
                        label={intl.formatMessage({id:"orderClearing.textFields.creditCardNumber"})}
                        name="creditCardNumber"
                        required
                        pattern="[\\d\\s]*"
                        inputMode="numeric"
                        onChange={e => this.handleCreditCardNumberChange(e)}
                        onChangeValue={(name, value) => this.props.paymentInformation.pan = value }
                        onBlur={e => this.handleCreditCardNumberBlur(e)}
                        messageIcon={this.getNumberIcon()}
                        message={this.state.numberState.message }
                        className={this.state.numberState.state == 'success' ? 'valid' : ''}
                    />
                    <TextField
                        label={intl.formatMessage({id:"orderClearing.textFields.creditCardHolder"})}
                        name="creditCardHolder" required
                        onChangeValue={(name, value) => this.props.paymentInformation.cardholdername = value }
                    />
                </div>
                <div className="expiry-code">
                    <TextField
                        label={intl.formatMessage({id:"orderClearing.textFields.creditCardExpiry"})}
                        name="creditCardExpiry"
                        required
                        pattern="[\\d\\/]*"
                        inputMode="numeric"
                        maxLength={7}
                        onChange={e => this.handleCreditCardExpiryChange(e)}
                        onBlur={e => this.handleCreditCardExpiryBlur(e)}
                        messageIcon={this.state.expiryState.state == 'error' && <i>error_outline</i>}
                        message={this.state.expiryState.message }
                        className={this.state.expiryState.state == 'success' ? 'valid' : ''}
                    />
                    <TextField
                        label={this.state.codeName}
                        name="creditCardCode"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        minLength={this.state.codeLength}
                        maxLength={this.state.codeLength}
                        onChangeValue={(name, value) => this.props.paymentInformation.cardverifycode = value }
                        required
                    />
                </div>
            </div>
        )
    }

    getNumberIcon() {
        if (this.state.numberState.state == 'success') {
            return <i>check</i>;
        }
        else if (this.state.numberState.state == 'error') {
            return <i>error_outline</i>;
        }
        else {
            return null;
        }
    }

}

namespace CreditCardFields {
    export interface Props {
        paymentInformation:CreditCardPaymentInformation;
        intl: any;
    }
    export interface State {
        numberState:FieldState;
        expiryState:FieldState;
        codeName:string;
        codeLength:number;
    }

    export class FieldState {
        state:'error'|'success';
        message:string;
    }
}
export default injectIntl(OrderClearing)