
import {computed, observable} from "mobx";

export interface ItemUnit {
    Piece:string;
    Kilogram:string;
    SquareMeter:string;
    Pair:string;
    Set:string;
    Hours:string;
}

export type ItemUnitType = keyof ItemUnit;

export namespace ItemUnit {

    export const Piece:ItemUnitType = 'Piece';
    export const Kilogram:ItemUnitType = 'Kilogram';
    export const SquareMeter:ItemUnitType = 'SquareMeter';
    export const Pair:ItemUnitType = 'Pair';
    export const Set:ItemUnitType = 'Set';
    export const Hours:ItemUnitType = 'Hours';

    export function format(unit:ItemUnitType):string {
        switch (unit) {
            case 'Piece': return 'itemModel.unitType.Piece';
            case 'Kilogram': return 'itemModel.unitType.Kilogram';
            case 'SquareMeter': return 'itemModel.unitType.UnitType';
            case 'Pair': return 'itemModel.unitType.Pair';
            case 'Set': return 'itemModel.unitType.Set';
            case 'Hours': return 'itemModel.unitType.Hours';
        }
        return 'Unsupported unit';
    }
}

export interface ItemPricing {
    Standard:string;
    Highlight:string;
    PermanentAgreement:string;
    PromoDiscount:string;
    Incentive:string;
}

export type ItemPricingType = keyof ItemPricing;

export namespace ItemPricing {
    export const Standard:ItemPricingType = 'Standard';
    export const Highlight:ItemPricingType = 'Highlight'
    export const PermanentAgreement:ItemPricingType = 'PermanentAgreement';
    export const PromoDiscount:ItemPricingType = 'PromoDiscount';
    export const Incentive:ItemPricingType = 'Incentive';

    export function className(itemPricing:ItemPricingType) {
        switch (itemPricing) {
            case ItemPricing.Standard: return 'standard';
            case ItemPricing.Highlight: return 'highlight';
            case ItemPricing.PermanentAgreement: return 'permanent-agreement';
            case ItemPricing.PromoDiscount: return 'promo-discount';
            case ItemPricing.Incentive: return 'incentive';
        }
    }

    export function label(itemPricing:ItemPricingType) {
        switch (itemPricing) {
            case ItemPricing.Standard: return null;
            case ItemPricing.Highlight: return 'itemModel.itemPricing.Highlight';
            case ItemPricing.PermanentAgreement: return 'itemModel.itemPricing.PermanentAgreement';
            case ItemPricing.PromoDiscount: return 'itemModel.itemPricing.PromoDiscount';
            case ItemPricing.Incentive: return 'itemModel.itemPricing.Incentive';
        }
    }

}

export interface ItemOptionData {
    itemOptionId:number;
    articleNumber:string;
    name:string;
    unit:ItemUnitType;
    price:number;
}

export interface ItemData {
    itemId:number;
    name:string;
    description:string;
    descriptionLong?:string;
    parts?:number;
    unit:ItemUnitType;
    type:ItemPricingType;
    price?:number;
    relevance?:number;
    category:string;
    minQuantity?:number;
    maxQuantity?:number;
    options:ItemOptionData[];
}


export class Item implements ItemData {

    constructor(itemData?:ItemData) {
        if (itemData) {
            this.assign(itemData);
        }
    }

    @observable
    itemId:number;

    @observable
    name:string;

    @observable
    description:string;

    @observable
    descriptionLong:string;

    @observable
    parts:number;

    @observable
    unit:ItemUnitType;

    @observable
    type:ItemPricingType;

    @observable
    price:number;

    @observable
    relevance:number;

    @observable
    category:string;

    @observable
    minQuantity:number;

    @observable
    maxQuantity:number;

    @observable
    options:ItemOptionData[];

    @computed
    public get hasPrice() {
        return this.price != null;
    }

    public assign(itemData?:ItemData) {
        Object.assign(this, itemData);
    }
}
