import * as React from "react";
import {ClassList} from "./Base";

export interface ItemProps {
    className?: string;
    iconRight?: string;
    labelRight?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export class Item extends React.Component<ItemProps> {
    render() {
        const {className, iconRight, labelRight, onClick, children} = this.props;
        return (
            <div className={ClassList.compute("clesy-item", className)} onClick={onClick}>
                {children}
                <div className="icon-right">
                    {iconRight && (
                        <i>{iconRight}</i>
                    )}
                    {labelRight && (
                        <span>{labelRight}</span>
                    )}
                </div>
            </div>
        );
    }
}