import {PromiseState, StoreBase} from "og-spa-state";
import {ClesyService} from "../services/ClesyService";
import {observable} from "mobx";

export interface PasswordLostProps {
    username:string;
}

export class PasswordLostStore extends StoreBase<PasswordLostProps, {}> {

    constructor(private clesyService:ClesyService) {
        super();
    }

    @observable
    readonly username = '';

    @observable
    readonly state:PromiseState<void>;


    submit() {
        this.setState({
            state: new PromiseState(this.clesyService.requestPasswordReset(this.username))
        });
    }
}