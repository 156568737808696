import * as React from "react";
import {View, ViewContent, ViewHeader, ViewMain} from "../controls/View";
import {RootStore} from "../../stores/RootStore";
import {Card, CardActions, CardHeader} from "../controls/Card";
import {observer} from "mobx-react";
import {Order} from "../../model/Order";
import {
    OrderDetailsCardContent, OrderNewerVersionContent, OrderPinCardContent,
    OrderStatusCardHeader
} from "./OrderCommon";
import {Button} from "../controls/Button";
import {FormattedMessage} from 'react-intl';

@observer
export class OrderView extends React.Component<OrderView.Props, undefined> {

    constructor(props:OrderView.Props) {
        super(props);
    }

    render() {
        let promisedOrder =  this.props.store.viewOrder.order;
        return (
            <View className="clesy-order-view" active={this.props.active}>
                { promisedOrder && (
                    <ViewMain>
                        <ViewHeader>
                            {promisedOrder.pending && <h1><FormattedMessage id="orderView.viewHeader"/></h1>}
                            {promisedOrder.rejected && <h1>{promisedOrder.reason.toString()}</h1>}
                            {promisedOrder.fulfilled && <h1><FormattedMessage id="orderView.viewHeaderOrderNumber"/> {promisedOrder.value.orderNumber}</h1>}
                            <div className="cancel-button">
                                <Button onClick={this.props.onCancel}><FormattedMessage id="orderView.viewHeaderButtonCancel"/></Button>
                            </div>
                        </ViewHeader>
                        <ViewContent>
                            { promisedOrder.fulfilled && this.renderOrder(promisedOrder.value) }
                        </ViewContent>
                    </ViewMain>
                )}
            </View>
        );
    }

    renderOrder(order:Order) {
        return (
            <Card>
                <OrderStatusCardHeader order={order} />
                { order.isAwaitingCubeAction() && <OrderPinCardContent order={order} /> }
                { order.hasNewerVersion() && <OrderNewerVersionContent order={order} onShowOrder={(orderId) => this.props.onShowOrder(orderId)} />}
                <CardActions>
                    { order.needsPayment() && (
                        <div style={{ textAlign: 'center', marginBottom: 15 }}><FormattedMessage id="orderView.requirePaymentHint"/></div>
                    )}
                    { order.needsPayment() && (
                        <Button primary={true} onClick={e => this.props.onPayOrder(order)}><i>payment</i> <FormattedMessage id="orderView.buttonPay"/></Button>
                    )}
                    { order.bill && order.bill.documentUrl && <a href={order.bill.documentUrl} className="button" target="_blank"><i>receipt</i> <FormattedMessage id="orderView.cardLink"/></a> }
                </CardActions>
                <OrderDetailsCardContent order={order} />
                <Button primary={false} onClick={e => this.configureItems(order)}><FormattedMessage id="orderView.buttonRepeatOrder"/></Button>
            </Card>
        );
    }

    private configureItems(order: any) {
        let store = this.props.store;
        for(let item in order.items) {
            store.cart.addItem(order.items[item]);
        }
        store.showOrderCreate()
    }
}

export namespace OrderView {
    export interface Props {
        active:boolean;
        store:RootStore;
        onPayOrder:(order:Order) => void;
        onShowOrder:(orderId:number) => void;
        onCancel:() => void;
    }


}

