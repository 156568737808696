import * as React from "react";
import {Account} from "../../model/Account";
import {Item} from "./Item";
import {ItemTitle} from "./ItemTitle";
import {ItemDescription} from "./ItemDescription";

export interface AccountItemProps {
    account?: Account;
    iconRight?: string;
    labelRight?: string;
}

export class AccountItem extends React.Component<AccountItemProps> {
    render() {
        const {iconRight, labelRight} = this.props;
        return (
            <Item iconRight={iconRight} labelRight={labelRight} className="clesy-account-item">
                {this.renderContent()}
            </Item>
        );
    }

    renderContent() {
        const {account} = this.props;
        if (account) {
            let addressLines = account.address.format();
            return (
                <div className="content">
                    <ItemTitle>
                        {account.person.format()}
                    </ItemTitle>
                    <ItemDescription>
                        {account.email && <div className="email">{account.email}</div>}
                        {account.company && <div className="company">{account.company}</div>}
                        {addressLines && (
                            <div className="address">
                                {addressLines.map((line, index) => (
                                    <div key={index} className="line">{line}</div>
                                ))}
                            </div>
                        )}
                    </ItemDescription>
                </div>
            );
        }
        else {
            return (
                <div className="content">
                    <ItemTitle>
                        Bitte auswählen
                    </ItemTitle>
                </div>
            );
        }
    }
}