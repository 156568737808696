import * as React from "react";
import {ClassList, DivWrapper} from "./Base";

export namespace View {
    export interface Props {
        className?:string;
        active?:boolean;
    }
    export interface State {
    }
}

export class View extends React.Component<View.Props, View.State> {
    render() {
        let classes = new ClassList('clesy-view', this.props.className, this.props.active && 'active');
        return (
            <div className={classes.toString()}>
                {this.props.children}
            </div>
        );
    }
}

export namespace ViewHeader {
    export interface Props {
        className?:string;
        closeIcon?:string;
        onClose?:() => void;
        title?:string;
    }
    export interface State {
    }
}

export class ViewHeader extends React.Component<ViewHeader.Props, ViewHeader.State> {

    render() {
        let classes = ['header'];
        if (this.props.className) {
            classes.push(this.props.className);
        }

        return (
            <div className={classes.join(' ')}>
                {this.props.onClose && this.renderCloseIcon()}
                {this.props.title && <h1>{this.props.title}</h1>}
                {this.props.children}
            </div>
        );
    }

    renderCloseIcon() {
        let iconName = this.props.closeIcon || "chevron_left";
        return (
            <div className="close" onClick={e => this.props.onClose && this.props.onClose()}>
                <i>{iconName}</i>
            </div>
        );
    }
}

export class ViewContent extends DivWrapper {
    render() { return super.render('content'); }
}

export class ViewMain extends DivWrapper {
    render() { return super.render('clesy-view-main'); }
}

export class ViewFooter extends DivWrapper {
    render() { return super.render('footer'); }
}