import {observer} from "mobx-react";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {style} from "typestyle";
import {RootStore} from "../../stores/RootStore";
import {Alert} from "../controls/Alert";
import {Button} from "../controls/Button";
import {Card} from "../controls/Card";
import {View, ViewContent, ViewMain} from "../controls/View";

interface ConfirmEmailViewProps {
    active: boolean;
    store: RootStore;
}

@observer
export class ConfirmEmailView extends React.Component<ConfirmEmailViewProps> {
    render() {
        const {store} = this.props;
        const {pending, error} = store.confirmEmail;
        return (
            <View className="clesy-confirm-email-view" active={this.props.active}>
                <ViewMain>
                    <Card processing={pending}>
                        {error && (
                            <Alert type="error">
                                <FormattedMessage id="confirmEmail.error"/>
                            </Alert>
                        )}
                        {!error && (
                            <div>
                                <Alert type="success">
                                    <FormattedMessage id="confirmEmail.success"/>
                                </Alert>
                                <p className={style({textAlign: "center"})}>
                                    <Button primary onClick={this.handleShowShop}>
                                        <FormattedMessage id="confirmEmail.continueToShop"/>
                                    </Button>
                                </p>
                            </div>
                        )}
                    </Card>
                </ViewMain>
            </View>
        );
    }

    private handleShowShop = () => this.props.store.showOrderList();
}