import * as React from "react";
import {Item} from "./Item";
import {Site} from "../../model/Site";
import {ItemTitle} from "./ItemTitle";
import {ItemDescription} from "./ItemDescription";

export interface SiteItemProps {
    iconRight?: string;
    labelRight?: string;
    site?: Site;
}

export class SiteItem extends React.Component<SiteItemProps> {
    render() {
        const {iconRight, labelRight} = this.props;
        return (
            <Item iconRight={iconRight} labelRight={labelRight}>
                {this.renderContent()}
            </Item>
        );
    }

    renderContent() {
        const {site} = this.props;
        if (site) {
            return (
                <div className="content">
                    <ItemTitle>{site.siteName}</ItemTitle>
                    <ItemDescription>
                        {site.address.street} {site.address.houseNo}<br/>
                        {site.address.country}-{site.address.zip} {site.address.city}
                    </ItemDescription>
                </div>
            );
        }
        else {
            return (
                <div className="content">
                    <ItemTitle>Bitte auswählen</ItemTitle>
                </div>
            );
        }
    }
}