import * as React from "react";
import {Component, CSSProperties} from "react";
import {render} from "react-dom";
import {stylesheet} from "typestyle";
import * as logo from "../../../images/clesyclean-logo-claim.png";
import {Account} from "../../model/Account";
import {Site} from "../../model/Site";
import {User} from "../../model/User";
import {Button} from "./Button";
import {FormRow} from "./FormRow";
import {RadioField} from "./RadioField";
import InjectedIntl = ReactIntl.InjectedIntl;

interface NameBadgeProps {
    user: User;
    site: Site;
    account: Account;
    intl: InjectedIntl;
    enableFormats?: boolean;
}

interface NameBadgeState {
    format: NameBadgeFormat;
}

enum NameBadgeFormat {
    Standard = "Standard",
    Dymo99014 = "Dymo99014",
}

export class NameBadge extends Component<NameBadgeProps, NameBadgeState> {

    constructor(props: NameBadgeProps) {
        super(props);
        this.state = {
            format: NameBadgeFormat.Standard,
        }
    }

    private ref?: HTMLIFrameElement;

    render() {
        const {intl, enableFormats} = this.props;
        const {format} = this.state;
        return (
            <div>
                <iframe frameBorder={0} className={styles.iframe} ref={this.handleRef} onLoad={this.handleFrameLoad}/>
                {enableFormats && (
                    <FormRow>
                        <RadioField name="format" onChangeValue={this.handleChangeFormat}>
                            <RadioField.Option value={NameBadgeFormat.Standard} checked={format == NameBadgeFormat.Standard}>
                                Standard
                            </RadioField.Option>
                            <RadioField.Option value={NameBadgeFormat.Dymo99014} checked={format == NameBadgeFormat.Dymo99014}>
                                DYMO 99014
                            </RadioField.Option>
                        </RadioField>
                    </FormRow>
                )}
                <div className={styles.printButtonContainer}>
                    <Button primary={true}
                            label={intl.formatMessage({id: "badge.button.print"})}
                            onClick={this.handlePrintClick}/>
                </div>
            </div>
        );
    }

    private handleChangeFormat = (name: string, value: string) => this.setState({format: value as NameBadgeFormat});

    private handleRef = (ref) => {
        this.ref = ref;
    };

    private handleFrameLoad = () => {
        // additional hook for Firefox to make sure
        // we init the frame after it finishes loading `about:blank`
        this.initFrame();
    };

    componentDidMount(): void {
        // init the iframe when component is mounted.
        this.initFrame();
    }

    componentDidUpdate() {
        // update frame content whenever props change
        this.updateFrameContents();
    }

    private initFrame() {
        if (this.ref) {
            const {contentDocument} = this.ref;

            const prepareFrame = () => {

                // create a react root within the iframe
                if (!contentDocument.getElementById("react-root")) {
                    const reactRoot = contentDocument.createElement("div");
                    reactRoot.setAttribute("id", "react-root");
                    contentDocument.body.appendChild(reactRoot);
                }

                // copy head elements to iframe
                if (contentDocument.head.childNodes.length == 0) {
                    const headChildNodes = document.head.childNodes;
                    for (let i = 0; i < headChildNodes.length; i++) {
                        const node = headChildNodes[i].cloneNode(true);
                        contentDocument.head.appendChild(node);
                    }
                }

                // override body style
                contentDocument.body.style.backgroundImage = "none";
                contentDocument.body.style.backgroundColor = "white";

                // update frame contents
                this.updateFrameContents();
            };

            // prepare the frame when the document is ready
            if (contentDocument.readyState === "complete" || contentDocument.readyState === "interactive") {
                // call on next available tick
                setTimeout(prepareFrame, 1);
            }
            else {
                contentDocument.addEventListener("DOMContentLoaded", prepareFrame);
            }
        }
    }

    private handlePrintClick = () => this.ref.contentWindow.print();

    private updateFrameContents() {
        if (this.ref) {
            const reactRoot = this.ref.contentDocument.getElementById("react-root");
            if (reactRoot) {
                render(this.renderContent(), reactRoot);
            }
        }
    }

    renderContent() {
        const {user, site, account, intl} = this.props;
        const {format} = this.state;

        const phone = account.phone.trim();
        const customerNumber = user?.customerReference;
        const person = account.person;

        const siteName = site.siteName;
        const address = site.address;

        // Styles within the iframe are inlined to workaround a bug in IE11/Edge
        // that does not apply injected <style> elements.

        const inlineStyles = getInlineStyles(format);

        return (
            <div style={inlineStyles.badge}>
                <img src={logo} style={inlineStyles.logo}/>
                <div style={inlineStyles.content}>
                    <div style={inlineStyles.siteName}>{siteName}</div>
                    <div>{address.street} {address.houseNo}</div>
                    <div>{address.zip} {address.city}</div>
                    {phone && (
                        <div>{intl.formatMessage({id: "badge.mobile"})} {phone}</div>
                    )}
                    <div style={inlineStyles.personName}>
                        {person.title} {person.firstname} {person.lastname}
                    </div>
                    <div style={inlineStyles.customerNumber}>
                        {intl.formatMessage({id: "badge.number"})} {customerNumber}
                    </div>
                </div>
            </div>
        );
    }
}

function getInlineStyles(format: NameBadgeFormat) {
    switch (format) {
        case NameBadgeFormat.Standard: return inlineStylesStandard;
        case NameBadgeFormat.Dymo99014: return inlineStylesDymo99014;
    }
}

const inlineStylesStandard: { [property:string]: CSSProperties} = {
    badge: {
        fontSize: "4mm",
        border: "0.25mm dashed",
        margin: "0 auto",
        width: "105.5mm",
        height: "61.5mm",
        position: "relative",
        boxSizing: "border-box"
    },
    logo: {
        position: "absolute",
        width: "44mm",
        height: "auto",
        top: "6mm",
        right: "6mm"
    },
    content: {
        position: "absolute",
        bottom: "4mm",
        left: "6mm",
    },
    siteName: {
        fontWeight: "bold"
    },
    personName: {
        fontSize: "6mm",
        fontWeight: "bold",
        wordWrap: "break-word",
        margin: "0.25em 0"
    },
    customerNumber: {
        fontSize: "3.25mm"
    }
};

const inlineStylesDymo99014: { [property:string]: CSSProperties} = {
    badge: {
        fontSize: "4mm",
        margin: "0 auto",
        width: "103mm",
        height: "62mm",
        position: "relative",
    },
    logo: {
        position: "absolute",
        width: "44mm",
        height: "auto",
        top: "2mm",
        right: "0"
    },
    content: {
        position: "absolute",
        bottom: "0",
        left: "0",
    },
    siteName: {
        fontWeight: "bold"
    },
    personName: {
        fontSize: "6mm",
        fontWeight: "bold",
        wordWrap: "break-word",
        margin: "0.25em 0"
    },
    customerNumber: {
        fontSize: "3.25mm"
    }
};

const styles = stylesheet({
    iframe: {
        display: "block",
        width: "106mm",
        height: "62mm",
        margin: "0 auto"
    },
    printButtonContainer: {
        margin: "0.5em 0",
        textAlign: "center"
    },
});