import * as React from "react";
import {ClassList, ClassNameProps} from "./Base";
import {Card, CardContent, CardFooter, CardHeader} from "./Card";

export namespace Dialog {

    export interface Props {
        className?:string;
        active?:boolean;
    }

    export interface State {
    }

}

export class Dialog extends React.Component<Dialog.Props, Dialog.State> {

    render() {
        let classes = new ClassList('clesy-dialog');
        if (this.props.className) {
            classes.add(this.props.className);
        }
        if (this.props.active) {
            classes.add('active');
        }
        return (
            <div className={classes.toString()}>
                <Card>
                    {this.props.children}
                </Card>
            </div>
        )
    }
}

export class DialogHeader extends React.Component<ClassNameProps, {}> {
    render() {
        return (
            <CardHeader className={this.props.className}>
                {this.props.children}
            </CardHeader>
        )
    }
}

export class DialogContent extends React.Component<ClassNameProps, {}> {
    render() {
        return (
            <CardContent className={this.props.className}>
                {this.props.children}
            </CardContent>
        )
    }
}

export class DialogFooter extends React.Component<ClassNameProps, {}> {
    render() {
        return (
            <CardFooter className={this.props.className}>
                {this.props.children}
            </CardFooter>
        )
    }
}