import * as React from 'react';
import {InputField} from "./InputField";

namespace TextareaField {
    export interface Props extends InputField.Props {
        defaultValue?:string;
        onChangeValue?:(name:string, newValue:string) => void;
        onChange?:(e:React.ChangeEvent<HTMLTextAreaElement>) => void;
        required?:boolean;
        className?:string;
        value?:string|number;
        message?:string;
        textArea?:(instance: HTMLTextAreaElement) => any;
    }

    export interface State {
        hasValue:boolean;
    }
}

export class TextareaField extends React.Component<TextareaField.Props, TextareaField.State> {

    private textArea:HTMLTextAreaElement;

    constructor(props:TextareaField.Props) {
        super(props);

        this.state = { hasValue: !!props.defaultValue };
    }


    componentDidMount() {
        this.updateHeight();

        this.setState({ hasValue: !!this.textArea.value});
    }

    private handleChange(e:React.ChangeEvent<HTMLTextAreaElement>):void {
        if (this.props.onChangeValue) {
            this.props.onChangeValue(e.target.name, e.target.value);
        }

        this.setState({ hasValue: !!e.target.value})

        this.updateHeight();

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    private updateHeight() {
        this.textArea.style.height = "";
        this.textArea.style.height = this.textArea.scrollHeight + 1 + "px";
    }


    private handleTextAreaRef(textArea:HTMLTextAreaElement) {
        this.textArea = textArea;
        if (this.props.textArea) {
            this.props.textArea(textArea);
        }
    }

    render() {
        return (
            <InputField className={this.props.className}
                        name={this.props.name}
                        label={this.props.label}
                        required={this.props.required}
                        message={this.props.message}>

                <textarea
                       name={this.props.name}
                       id={this.props.name}
                       defaultValue={this.props.defaultValue}
                       value={this.props.value}
                       ref={textArea => this.handleTextAreaRef(textArea)}
                       required={this.props.required}
                       rows={1}
                       className={this.state.hasValue ? 'not-empty' : 'empty'}
                       onChange={e => this.handleChange(e)} />

            </InputField>
        );
    };
}