import * as React from "react";
import {ClassList, ClassNameProps, DivWrapper} from "./Base";
import {Spinner} from "./Spinner";

export namespace Card {
    export interface Props extends ClassNameProps {
        processing?:boolean;
    }
}

export class Card extends React.Component<Card.Props, undefined> {
    render() {
        let classList = new ClassList('clesy-card');
        if (this.props.processing) {
            classList.add('processing');
        }
        return (
            <DivWrapper {...(this.props as ClassNameProps)} className={classList.toString()}>
                {this.props.children}
                <Spinner />
            </DivWrapper>
        )
    }
}

export class CardHeader extends DivWrapper {
    render() { return super.render('clesy-card-header'); }
}

export class CardContent extends DivWrapper {
    render() { return super.render('clesy-card-content'); }
}

export class CardFooter extends DivWrapper {
    render() { return super.render('clesy-card-footer'); }
}

export class CardMenu extends DivWrapper {
    render() { return super.render('clesy-card-menu'); }
}

export class CardActions extends DivWrapper {
    render() { return super.render('clesy-card-actions'); }
}

