import {PromisedValue, StoreBase} from "og-spa-state";
import {action, computed, observable, reaction} from "mobx";
import {Item, ItemOptionData, ItemUnitType} from "../model/Item";
import {Order, OrderData} from "../model/Order";
import {Coupon} from "../model/Coupon";
import {Site} from "../model/Site";
import {Account} from "../model/Account";
import {CreateOrderRequest} from "../model/transfer";
import {DeliveryDates} from "../model/DeliveryDates";
import {AuthStore} from "./AuthStore";
import {StorageUtil} from "../util/StorageUtil";
import {ArticlesService, ArticlesStore} from "./ArticlesStore";

const SiteKey = 'clesy_shop/stores/Store/site';
const AccountKey = 'clesy_shop/stores/Store/account';

export interface CartProps {
    termsAndConditionsAccepted:boolean;
    sepaAccepted:boolean;
    customerOrderId:string;
    comment:string;
    account:Account;
    site:Site;
}

interface CartState {
    items:CartItemStore[];
    orderState:PromisedValue<number>;
    deliveryDates:PromisedValue<DeliveryDates>;
}

export interface CartService extends ArticlesService {
    order(request:CreateOrderRequest):Promise<number>;
    fetchDeliveryDates(siteId:number):Promise<DeliveryDates>;
    fetchVouchers():Promise<Coupon[]>;
}

export class CartStore extends StoreBase<CartProps, CartState> {

    constructor(private service:CartService, private auth:AuthStore) {
        super();

        reaction(() => this.site, site => {
            this.fetchDeliveryDates(site);

            if (site) {
                StorageUtil.writeNumber(SiteKey, site.siteId);
            }

            this.updateArticles();
        });

        reaction(() => this.account, account => {
            if (account) {
                StorageUtil.writeNumber(AccountKey, account.accountId);
            }
            this.updateArticles();
        });

        reaction(() => this.articles.constraint, contraintArticles => {
            contraintArticles.forEach(article => this.ensureConstraints(article));
        });

        reaction(() => this.auth.authenticated, authenticated => {

            if (!authenticated) {
                this.reset();
                this.setProps({
                    account: null,
                    site: null
                });
            }
            else {
                this.service.fetchVouchers()
                    .then(vouchers => this.setAvailableVouchers(vouchers))
                    .catch(error => console.log('error reading vouchers', error));

                let account = null;
                let site = null;

                let accountId = StorageUtil.readNumber(AccountKey);
                let siteId = StorageUtil.readNumber(SiteKey);

                //
                // Set account & site
                //
                let accounts = authenticated.accounts.filter(a => a.accountId == accountId);
                if (accounts && accounts.length) {
                    account = accounts[0];
                }
                else if (authenticated.accounts.length) {
                    account = authenticated.accounts[0];
                }

                if (account != null) {
                    let sites = account.sites.filter(s => s.siteId == siteId);
                    if (sites && sites.length) {
                        site = sites[0];
                    }
                    else if (account.sites.length) {
                        site = account.sites[0];
                    }
                }

                this.setProps({
                    account: account,
                    site: site
                });
            }
        });
    }

    @observable
    readonly articles:ArticlesStore = new ArticlesStore(this.service);

    @observable
    readonly items:ReadonlyArray<CartItemStore> = [];

    @observable
    readonly termsAndConditionsAccepted:boolean = false;

    @observable
    readonly sepaAccepted:boolean = false;

    @observable
    readonly customerOrderId:string;

    @observable
    readonly comment:string;

    @observable
    readonly account:Account;

    @observable
    readonly site:Site;

    @observable
    readonly orderState:PromisedValue<number>;

    @observable
    readonly deliveryDates:PromisedValue<DeliveryDates>;

    @observable
    private readonly _availableVouchers:Map<string, Coupon> = new Map<string, Coupon>();

    @observable
    private readonly _usedVouchers:Map<string, VoucherItem> = new Map<string, VoucherItem>();

    @computed
    public get availableAccounts():Account[] {
        let user = this.auth.authenticated;
        return user ? user.accounts : [];
    }

    @computed
    public get availableSites():Site[] {
        let account = this.account;
        return account ? account.sites : [];
    }

    @action
    public addItem(addItem:Item) {

        let found = this.findCartItem(addItem);
        if (!found) {
            found = new CartItemStore(addItem);
            this.setState({items: [...this.items, found]});
        }
        found.setQuantity(found.quantity + 1);
    }

    @action
    public removeItem(removeItem:Item) {
        let found = this.findCartItem(removeItem);
        if (found) {
            found.setQuantity(found.quantity - 1);

            if (found.quantity <= 0) {
                this.setState({items: this.items.filter(item => item !== found)});
            }
        }
    }

    private fetchDeliveryDates(site:Site) {
        if (site) {
            let promise = this.service.fetchDeliveryDates(site.siteId)
                .then(data => new DeliveryDates(data));
            this.setState({deliveryDates: new PromisedValue(promise)});
        }
        else {
            this.setState({deliveryDates: null});
        }
    }

    private updateArticles() {
        this.articles.fetch(this.account, this.site);
    }

    @action
    order():Promise<number> {
        this.validate();

        // create request
        let createOrderRequest:CreateOrderRequest = {
            acceptSepa: this.sepaAccepted,
            acceptAgb: this.termsAndConditionsAccepted,
            accountId: this.account.accountId,
            serviceStationId: this.site.siteId,
            comment: this.comment,
            customerOrderId: this.customerOrderId,
            items: this.items.map(item => {
                return {
                    articleId: item.item.itemId,
                    quantity: item.quantity,
                    options: item.options.map(option => option.itemOptionId)
                }
            }),
            couponCodes: Array.from(this._usedVouchers.keys())
        };
        // invoke service
        let promise = this.service.order(createOrderRequest)
            .then(orderId => {
                // reset state
                this.reset();

                // return order id
                return orderId;
            })
            .catch(error => {
                return error;
            });

        this.setState({orderState: new PromisedValue<number>(promise)});

        return promise;
    }

    private findCartItem(findItem:Item):CartItemStore {
        let found = null;
        for (let item of this.items) {
            if (item.item.itemId == findItem.itemId) {
                found = item;
                break;
            }
        }
        return found;
    }


    @computed
    public get itemCount():number {
        let itemCount = 0;
        for (let item of this.items) {
            itemCount += item.quantity;
        }
        return itemCount;
    }

    @computed
    public get itemTotal():number {
        let itemTotal = 0;
        for (let item of this.items) {
            if (item.hasSubtotal) {
                itemTotal += item.subtotal;
            }
        }
        return itemTotal;
    }

    @computed
    public get discountTotal():number {
        let voucherTotal = 0;
        this._usedVouchers.forEach((voucherItem) => {
            voucherTotal += voucherItem.amount;
        });
        return voucherTotal;
    }

    @computed
    public get remainingVoucherTotal():number {
        let totalAfterDiscount = this.itemTotal - this.discountTotal;
        return (totalAfterDiscount < 0) ? -totalAfterDiscount : 0;
    }

    @computed
    public get total():number {
        return this.itemTotal - this.discountTotal + this.remainingVoucherTotal;
    }

    @computed
    public get totalTax():number {
        return this.total / 6;
    }

    @computed
    public get voucherItems():VoucherItem[] {
        return Array.from(this._usedVouchers.values());
    }

    @computed
    public get isValid():boolean {
        try {
            this.validate();
            return true;
        }
        catch (ex) {
            return false;
        }
    }

    @computed
    public get needsSepaAcceptance() {
        return this.account ? !!this.account.legacySepaDebit : false;
    }

    private validate() {
        if (this.itemCount == 0) {
            throw new Error('No items selected');
        }
        if (!this.termsAndConditionsAccepted) {
            throw new Error('Terms & conditions not accepted.');
        }
        if (this.site == null) {
            throw new Error('No site selected');
        }
        if (this.account == null) {
            throw new Error('No account selected');
        }
        if (this.needsSepaAcceptance && !this.sepaAccepted) {
            throw new Error('SEPA not accepted.');
        }
    }

    @computed
    public get availableVouchers():Coupon[] {
        return Array.from(this._availableVouchers.values());
    }

    @action
    public ensureConstraints(item:Item) {
        let cartItem = this.findCartItem(item);
        // ensure minimum quantity
        if (item.minQuantity) {
            console.log('Ensuring minimum quantity for ' + item.itemId);
            if (!cartItem) {
                cartItem = new CartItemStore(item);
                this.setState({items: [...this.items, cartItem]});
            }
            cartItem.setQuantity(Math.max(cartItem.quantity, item.minQuantity));
        }
        // ensure maximum quantity
        if (item.maxQuantity) {
            if (cartItem) {
                cartItem.setQuantity(Math.min(cartItem.quantity,  item.maxQuantity));
            }
        }
    }

    @action
    public addVoucher(voucher:Coupon) {
        this._availableVouchers.delete(voucher.code);
        this._usedVouchers.set(voucher.code, new VoucherItem(voucher));
    }

    @action
    public removeVoucher(voucher:Coupon) {
        this._availableVouchers.set(voucher.code, voucher);
        this._usedVouchers.delete(voucher.code);
    }

    @action
    private setAvailableVouchers(vouchers:Coupon[]) {
        this._availableVouchers.clear();
        vouchers.forEach(voucher => this._availableVouchers.set(voucher.code, voucher));
        this._usedVouchers.clear();
    }

    @action
    public reset() {
        this.setProps({
            termsAndConditionsAccepted: false,
            sepaAccepted: false,
            customerOrderId: '',
            comment: ''
        });
        this.setState({
            items:[],
            orderState:null
        });
    }
}

interface CartItemState {
    quantity:number;
    options:ItemOptionData[];
}

export class CartItemStore extends StoreBase<undefined, CartItemState> {

    constructor(item:Item) {
        super();
        this.item = item;
    }

    @observable
    public readonly item:Item;

    @observable
    public readonly quantity:number = 0;

    @observable
    public readonly options:ReadonlyArray<ItemOptionData> = [];

    @computed
    public get canIncrementQuantity():boolean {
        if (this.item.maxQuantity) {
            if (this.quantity >= this.item.maxQuantity) {
                return false;
            }
        }
        return true;
    }

    @computed
    public get canDecrementQuantity():boolean {
        if (this.item.minQuantity) {
            if (this.quantity <= this.item.minQuantity) {
                return false;
            }
        }
        return true;
    }

    @action
    public setQuantity(value:number) {
        this.validateQuantity(value);
        this.setState({quantity: value});
    }

    private validateQuantity(value:number) {
        if (this.item.maxQuantity && value > this.item.maxQuantity) {
            throw new Error('quantity exceeeds maxQuantity of item');
        }
        if (this.item.minQuantity && value < this.item.minQuantity) {
            throw new Error('quantity is below minQuantity of item');
        }
    }

    private createOptionSet() {
        return new Set<ItemOptionData>(Array.from(this.options));
    }

    public isOptionSelected(option:ItemOptionData) {
        return this.createOptionSet().has(option);
    }

    @action
    public setOptionSelected(option:ItemOptionData, selected:boolean) {
        let newOptions = this.createOptionSet();
        if (selected) {
            newOptions.add(option);
        }
        else {
            newOptions.delete(option);
        }
        this.setState({options: Array.from(newOptions)});
    }

    @computed
    public get description():string {
        return this.item.name;
    }

    @computed
    public get unit():ItemUnitType {
        return this.item.unit;
    }

    @computed
    public get price():number {
        let price = this.item.price;
        for (let option of this.options) {
            price += option.price;
        }
        return price;
    }

    @computed
    public get subtotal():number {
        return this.price == null ? null : this.quantity * this.price;
    }

    @computed
    public get hasSubtotal() {
        return this.price != null;
    }
}

export class VoucherItem {

    constructor(voucher:Coupon) {
        this.voucher = voucher;
    }

    @observable
    public readonly voucher:Coupon;

    @computed
    public get description():string {
        return this.voucher.description;
    }

    @computed
    public get code():string {
        return this.voucher.code;
    }

    @computed
    public get amount():number {
        return this.voucher.value;
    }
}

