import * as React from "react";
import {FormattedMessage, InjectedIntl} from "react-intl";
import {stylesheet} from "typestyle";
import * as suitbagImage from "../../../images/suitbag-small.png";
import {Button} from "../controls/Button";
import {Dialog, DialogContent} from "../controls/Dialog";

interface SuitBagDialogProps {
    active: boolean;
    intl: InjectedIntl;
    onShowOrderCreate: () => void;
}

export class SuitBagDialog extends React.Component<SuitBagDialogProps> {
    render() {
        const {active, intl, onShowOrderCreate} = this.props;
        return (
            <Dialog active={active} className={styles.dialog}>
                <DialogContent className={styles.content}>
                    <h2 className={styles.centered}>
                        <div className={styles.suitBagHeaderSpan}>
                            <FormattedMessage id="signUpView.suitBagHeaderSpan"/>
                        </div>
                        <div>
                            <FormattedMessage id="signUpView.suitBagHeader"/>
                        </div>
                    </h2>
                    <div className={styles.suitBagIntro}>
                        <div className={styles.suitBagImageContainer}>
                            <img className={styles.suitBagImage}
                                 src={suitbagImage}
                                 alt={intl.formatMessage({id: "signUpView.suitBagImgAlt"})}/>
                        </div>
                        <div className={styles.suitBagText}>
                            <p>
                                <FormattedMessage id="signUpView.suitBagIntroTextOne"/>
                            </p>
                            <p>
                                <FormattedMessage id="signUpView.suitBagIntroTextTwo"/>
                            </p>
                        </div>
                    </div>

                    <div className={styles.centered}>
                        <p>
                            <FormattedMessage id="signUpView.textCenter"/>
                        </p>
                        <p className={styles.signature}>
                            <FormattedMessage id="signUpView.signature"/>
                        </p>
                        <p className="clesy-typography-caption">
                            <FormattedMessage id="signUpView.ps"/>
                        </p>
                        <p>
                            <Button icon="add" primary onClick={onShowOrderCreate}>
                                <FormattedMessage id="signUpView.buttonAdd"/>
                            </Button>
                        </p>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}

const styles = stylesheet({
    dialog: {
        overflow: "scroll"
    },
    header: {
        display: "block",
        padding: "1em 2em",
    },
    content: {
        padding: "0 1em 1em 1em",
    },
    suitBagHeaderSpan: {
        fontWeight: 300,
        fontStyle: "italic",
    },
    centered: {
        textAlign: "center",
    },
    suitBagIntro: {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
    },
    suitBagImageContainer: {
        flex: 1,
        margin: "0 1em",
        maxWidth: "12em",
    },
    suitBagImage: {
        width: "100%",
        minWidth: "8em",
    },
    suitBagText: {
        flex: 1,
        margin: "0 1em",
    },
    signature: {
        textAlign: "center",
        fontSize: "1.25em",
        fontWeight: 300,
        fontStyle: "italic",
    }
});