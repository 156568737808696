import * as React from "react";
import {View, ViewHeader, ViewContent} from "../controls/View";
import {FormattedMessage, injectIntl} from 'react-intl';

namespace NoMatch {
    export interface Props {
        active:boolean;
        intl:any;
    }
}

/**
 * Displays a message that the route has not been found (aka 404)
 */
export class NoMatch extends React.Component<NoMatch.Props, undefined> {
    render() {
        const {intl} = this.props;
        return (
            <View className="clesy-no-match" active={this.props.active}>
                <ViewHeader title={intl.formatMessage({id:"noMatch.title"})}/>
                <ViewContent>
                    <code>{intl.formatMessage({id:"noMatch.code"})}  ¯\_(ツ)_/¯</code>
                </ViewContent>
            </View>
        );

    }
}
export default injectIntl(NoMatch)