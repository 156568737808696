import EventTarget from "event-target-shim";
import {Account} from "../model/Account";
import {Category} from "../model/Category";
import {ClearingOptions} from "../model/ClearingOptions";
import {Coupon, CouponData} from "../model/Coupon";

import {DeliveryDates, DeliveryDatesData} from "../model/DeliveryDates";
import {Item} from "../model/Item";
import {Order, OrderData} from "../model/Order";
import {Referral} from "../model/Referral";
import {Signup} from "../model/Signup";
import {Site, SiteData} from "../model/Site";
import {
    AccountIbanRequest,
    ArticlesResponse,
    ClearingPerformResponse,
    CreateOrderRequest,
    CreateOrderResponse,
    ShopItemsResponse,
    SignupResponse,
    UserDataRequest,
    UserResponse
} from "../model/transfer";
import {User} from "../model/User";
import {createCustomEvent} from "../util/createCustomEvent";

export interface ClesyService {
    login(username: string, password: string): Promise<void>;

    logout(): Promise<void>;

    user(): Promise<User>;

    fetchArticles(account: Account, station: Site): Promise<ArticlesResponse>;

    order(request: CreateOrderRequest): Promise<number>;

    fetchOrder(id: number, authKey?: string): Promise<Order>;

    fetchOrders(offset: number, max: number): Promise<Order[]>;

    fetchVouchers(): Promise<Coupon[]>;

    signup(signup: Signup): Promise<SignupResponse>;

    refer(referral: Referral): Promise<void>;

    confirm(confirmToken: string): Promise<void>;

    initClearing(orderId: number, authKey?: string): Promise<ClearingOptions>;

    performClearing(id: string, clearingOptionUuid: string): Promise<string>;

    changeUserData(request: UserDataRequest): Promise<void>;

    changeAccountIban(request: AccountIbanRequest): Promise<void>;

    changePassword(userId: number, oldPassword: string, newPassword: string): Promise<void>;

    fetchDeliveryDates(siteId: number): Promise<DeliveryDates>;

    requestPasswordReset(username: string): Promise<void>;

    resetPassword(username: string, token: string, newPassword: string): Promise<void>;

    getSites(searchTerm: string): Promise<SiteData[]>;
}

function getServiceUrl() {
    if (location.hostname == "localhost") {
        return "http://localhost:5000/service";
    }
    else {
        const serviceHost = location.hostname.replace("shop", "service");
        return `https://${serviceHost}/service`
    }
}

const LoginTimeout = 5000;

type ServiceEvents = {
    unauthorized: CustomEvent;
}

export class RestClesyService extends EventTarget<ServiceEvents, {}> implements ClesyService {

    /** The base URL of the clesy service */
    private readonly url: string;

    constructor() {
        super();
        this.url = getServiceUrl();
    }

    private async fetch(url: string, init: RequestInit) {
        const response = await window.fetch(url, init);
        if (response.ok) {
            return response;
        }
        else {
            if (response.status == 401) {
                this.dispatchEvent(createCustomEvent("unauthorized"));
            }
            const errorMessage = await tryExtractErrorMessage(response);
            throw new ClesyServiceError(response.status, `${errorMessage}`);
        }
    }

    private async get(url: string) {
        return this.fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Accept": "application/json",
            }
        });
    }

    private async post(url: string, data: any) {
        return this.fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    private buildUrl(endpoint: string) {
        if (this.url.endsWith("/")) {
            return `${this.url}${endpoint}`;
        }
        return `${this.url}/${endpoint}`;
    }

    login(username: string, password: string): Promise<void> {

        const loginRequest = async () => {
            const formData = new FormData();
            formData.append("username", username);
            formData.append("password", password);
            const response = await window.fetch(this.buildUrl("login"), {
                method: "POST",
                credentials: "include",
                body: formData,
            });
            if (!response.ok) {
                throw new Error("Login failed.");
            }
        };

        const timeout = new Promise<void>((_resolve, reject) => {
            setTimeout(() => {
                reject(new Error(`Service did not complete timeout within ${LoginTimeout} msec`));
            }, LoginTimeout);
        });

        return Promise.race([loginRequest(), timeout]);
    }

    async logout() {
        const response = await window.fetch(this.buildUrl("logout"));
        // treat 401 as success, because it anyway means that the user session is closed.
        if (!response.ok && response.status != 401) {
            throw new Error("Logout failed.");
        }
    }

    async user() {
        const response = await this.get(this.buildUrl("user"));
        const userResponse = await response.json() as UserResponse;
        return new User(userResponse.user);
    }

    async fetchArticles(account: Account, site: Site) {
        const response = await this.get(this.buildUrl(`shop/items/${account.accountId}/${site.siteId}`));
        const shopItemsResponse = await response.json() as ShopItemsResponse;

        const result = [];
        for (let category of shopItemsResponse.categories) {
            for (let item of category.items) {
                item.category = category.name;
                result.push(new Item(item));
            }
        }

        return {
            categories: shopItemsResponse.categories.map(cat => new Category(cat)),
            plainItems: result
        };

    }

    async order(request: CreateOrderRequest) {
        const response = await this.post(this.buildUrl("shop/order"), request);
        const createOrderResponse = await response.json() as CreateOrderResponse;
        return createOrderResponse.orderId;
    }

    async fetchOrders(offset: number, limit: number) {
        const response = await this.get(this.buildUrl(`shop/order?offset=${offset}&limit=${limit}`));
        const orderList = await response.json() as OrderData[];
        return orderList.map(orderData => new Order(orderData));
    }

    async fetchOrder(id: number, authKey?: string) {
        // TODO: support for authKey
        const response = await this.get(this.buildUrl(`shop/order/${id}`));
        return new Order(await response.json() as OrderData);
    }

    async signup(signup: Signup) {
        const response = await this.post(this.buildUrl("user/signup"), signup);
        return await response.json() as SignupResponse;
    }

    async refer(referral: Referral) {
        await this.post(this.buildUrl("user/referral"), referral);
    }

    async fetchVouchers() {
        const response = await this.get(this.buildUrl(`user/vouchers`));
        const couponDataList = await response.json() as CouponData[];
        return couponDataList.map(voucherData => new Coupon(voucherData));
    }

    async confirm(confirmToken: string) {
        await this.get(this.buildUrl("user/signup/confirm?token=" + confirmToken));
    }

    async initClearing(orderId: number, authKey?: string) {
        // TODO: support for authKey
        const response = await this.get(this.buildUrl(`shop/order/${orderId}/clearing/init`));
        return await response.json() as ClearingOptions;
    }

    async verifyClearing(orderId: number) {
        const response = await this.get(this.buildUrl(`shop/order/${orderId}/clearing/verify`));
        return await response.json() as ClearingOptions;
    }

    async performClearing(id: string, clearingOptionUuid: string) {
        const response = await this.get(this.buildUrl(`shop/clearing/${id}/${clearingOptionUuid}/perform`));
        const clearingPerformResponse = await response.json() as ClearingPerformResponse;
        return clearingPerformResponse.redirectUrl;
    }

    async changeUserData(request: UserDataRequest) {
        await this.post(this.buildUrl(`user/data`), {...request});
    }

    async changeAccountIban(request: AccountIbanRequest) {
        await this.post(this.buildUrl(`user/iban`), {...request});
    }

    async changePassword(userId: number, oldPassword: string, newPassword: string) {
        await this.post(this.buildUrl(`user/password`), {userId, oldPassword, newPassword});
    }

    async resetPassword(username: string, token: string, newPassword: string) {
        await this.post(this.buildUrl(`user/lostPasswordReset`), {username, token, newPassword});
    }

    async fetchDeliveryDates(siteId: number) {
        const response = await this.get(this.buildUrl(`shop/notifications/${siteId}`));
        return new DeliveryDates(await response.json() as DeliveryDatesData);
    }

    async requestPasswordReset(username: string) {
        await this.post(this.buildUrl(`user/lostPasswordRequest`), {username});
    }

    async getSite(siteId: number) {
        const response = await this.get(this.buildUrl(`sites/${siteId}`));
        return await response.json() as SiteData;
    }

    async getSites(searchTerm: string) {
        const response = await this.get(this.buildUrl(`sites?search=${searchTerm}`));
        return await response.json() as SiteData[];
    }

    async getUser(userKey: string) {
        const response = await this.get(this.buildUrl(`users/${userKey}`));
        return new User(await response.json());
    }
}

export class ClesyServiceError extends Error {
    constructor(readonly status: number, message?: string) {
        super(message);
    }
}

async function tryExtractErrorMessage(response: Response) {
    try {
        const body = await response.json();
        if (Array.isArray(body.errors)) {
            return body.errors
                .map(error => `${error.field} ${error.defaultMessage}`)
                .join(". ");
        }
        if (typeof body.message == "string") {
            return body.message;
        }
        else {
            return "Http status not ok.";
        }
    }
    catch (error) {
        return "Http status not ok.";
    }
}

export const clesyService = new RestClesyService();
