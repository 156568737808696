import {PromiseState, StoreBase} from "og-spa-state";
import {ClesyService} from "../services/ClesyService";
import {action, computed, observable} from "mobx";

export interface PasswordChangeProps {
    userId: number;
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

export interface PasswordChangeState {
    state: PromiseState<void>;
}

export const Valid = '';

export class PasswordChangeStore extends StoreBase<PasswordChangeProps, PasswordChangeState> {
    constructor(private service: ClesyService) {
        super();
    }

    @observable
    readonly userId: number;

    @observable
    readonly oldPassword: string;

    @observable
    readonly newPassword: string;

    @observable
    readonly newPasswordConfirmation: string;

    @observable
    readonly state: PromiseState<void>;

    @action
    execute() {
        let validity = this.newPasswordConfirmationValidity;
        if (validity != Valid) {
            throw new Error(validity);
        }

        let promise = this.service.changePassword(this.userId, this.oldPassword, this.newPassword)
            .then(() => this.resetProps());

        this.setState({
            state: new PromiseState(promise)
        });
    }

    /**
     * Returns a message indicating why the new password is not valid,
     * or an empty string if the password is valid
     * @returns {string} password validity message
     */
    @computed
    get newPasswordConfirmationValidity(): string {
        return (this.newPassword != this.newPasswordConfirmation)
            ? 'passwordResetStore.passwordFieldMessage'
            : Valid;
    }

    @action
    reset(): void {
        this.resetProps();
        this.setState({
            state: null
        });
    }

    private resetProps(): void {
        this.setProps({
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
        });
    }
}
