import * as React from "react";
import {ClassList} from "./Base";

export namespace Field {
    export interface Props {
        className?:string;
        onChange?:React.ChangeEventHandler<HTMLDivElement>;
    }
}

export class Field extends React.Component<Field.Props, undefined> {

    render() {
        return (
            <div className={ClassList.compute('clesy-field', this.props.className)} onChange={this.props.onChange}>
                {this.props.children}
            </div>
        );
    };
}