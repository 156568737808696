
import * as React from "react";
import {TextField} from "./TextField";

const StrictRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
const LooseRegex = /^([A-Z]{0,6}[A-Z0-9]{0,2}[A-Z0-9]{0,3})/;

namespace BicField {
    export interface State {
        valid?:boolean;
        potentiallyValid?:boolean;
        message?:string;
    }
}

export class BicField extends React.Component<TextField.Props, BicField.State> {

    constructor(props:TextField.Props) {
        super(props);
        this.state = {};
    }

    render() {
        return <TextField
            {...this.props}
            onChange={e => this.handleChange(e)}
            onBlur={e => this.handleBlur(e)}
            message={this.state.message}
            messageIcon={ this.state.valid ? null : <i>error_outline</i> }
        />;
    }

    private validateBic(target:HTMLInputElement, strict:boolean) {

        let message = null;

        // remove all invalid chars
        target.value = target.value.replace(/[^A-Z0-9]/g, '');

        let isValid = StrictRegex.test(target.value);
        let isPotentiallyValid = false;

        if (!isValid) {
            if (strict) {
                message = 'Ungültiges Format';
            }
            else {
                let match = LooseRegex.exec(target.value);
                if (!match || match.index != 0 || match[0].length != target.value.length) {
                    message = 'Ungültiges Format';
                }
                else {
                    message = 'Mehr Zeichen...';
                    isPotentiallyValid = true;
                }
            }
        }
        target.setCustomValidity(!isValid ? message : '');
        this.setState({...this.state, valid: isValid, potentiallyValid: isPotentiallyValid, message: message });
    }

    private handleChange(e:React.ChangeEvent<HTMLInputElement>):void {
        this.validateBic(e.currentTarget, false);
    };

    private handleBlur(e:React.FocusEvent<HTMLInputElement>):void {
        this.validateBic(e.currentTarget, true);
    };
}