import * as React from "react";
import {ClassList, DivWrapper} from "./Base";

export namespace Collapsible {
    export interface Props {
        expanded?:boolean;
        className?:string;
    }
}

export class Collapsible extends React.Component<Collapsible.Props, undefined> {
    render() {
        let classes = new ClassList('clesy-collapsible', this.props.className);
        if (this.props.expanded) {
            classes.add('expanded');
        }
        return DivWrapper.render(classes, this.props.expanded ? this.props.children : null);
    }
}
