
type CouponKind = 'Referral'|'Fairness'|'Complaint,'|'Cleaning'|'Unknown'|'Welcome';

export interface CouponData {
    value: number;
    validity: string;
    code: string;
    description:string,
    kind: CouponKind;
}

export class Coupon implements CouponData {
    constructor(couponData?:CouponData) {
        if (couponData) {
            Object.assign(this, couponData);
        }
    }

    value: number;
    validity: string;
    code: string;
    description:string;
    kind: CouponKind;
}