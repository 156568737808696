
import {RootStore, View} from "./stores/RootStore";
import {reaction} from "mobx";

const EventCategoryAuthentication = 'authentication';
const EventCategorySignup = 'signup';
const EventCategoryOrder = 'order';

let ready = new Promise((resolve, reject) => {
    let gaScript = document.createElement('script');
    gaScript.type = 'text/javascript';
    gaScript.src = 'https://www.google-analytics.com/analytics.js';
    gaScript.addEventListener('load', () => {
        ga('create', 'UA-708028-8', 'auto');
        resolve();
    });
    document.body.appendChild(gaScript);
});

function sendEvent(category:string, action:string, label?:string) {
    ready.then(() => {
        //console.log('sending event', category, action);
        ga('send', 'event', {
            eventCategory: category,
            eventAction: action,
            eventLabel: label
        });
    });
}

function sendTiming(category:string, action:string, value:number) {
    ready.then(() => {
        //console.log('sending timing', category, action);
        ga('send', 'timing', {
            timingCategory: category,
            timingVar: action,
            timingValue: Math.round(value).toFixed(0)
        });
    });
}

function sendPageView(page:string) {
    ready.then(() => {
        //console.log('sending pageview', page);
        ga('set', 'page', page);
        ga('send', 'pageview');
    });
}

export function track(store:RootStore) {

    reaction(() => store.view, view => {
        switch (view) {
            case View.Signup: sendPageView('Signup'); break;
            case View.SignupComplete: sendPageView('Signup complete'); break;
            case View.Order: sendPageView('Order'); break;
            case View.OrderList: sendPageView('Order list'); break;
            case View.OrderCreate: sendPageView('Order create'); break;
            case View.Referral: sendPageView('Referral'); break;
            case View.Settings: sendPageView('Settings'); break;
            case View.NoMatch: sendPageView('No match'); break;
            case View.OrderClearing: sendPageView('Order clearing'); break;
            default:
                sendPageView('View not implemented for tracking!');
        }
    });

    let loginStarted:number;

    reaction(() => store.auth.loginState && store.auth.loginState.pending, loginPending => {
        if (loginPending) {
            sendEvent(EventCategoryAuthentication, 'login-started');
            loginStarted = performance.now();
        }
    });
    reaction(() => store.auth.loginState && store.auth.loginState.rejected, loginRejected => {
        if (loginRejected) {
            sendEvent(EventCategoryAuthentication, 'login-failed', store.auth.username);
            sendTiming(EventCategoryAuthentication, 'login-failed', performance.now() - loginStarted);
        }
    });
    reaction(() => store.auth.loginState && store.auth.loginState.fulfilled, loginFulfilled => {
        if (loginFulfilled) {
            sendEvent(EventCategoryAuthentication, 'login-succeeded');
            sendTiming(EventCategoryAuthentication, 'login-succeeded', performance.now() - loginStarted);
        }
    });

    let logoutStarted:number;

    reaction(() => store.auth.logoutState && store.auth.logoutState.pending, logoutPending => {
        if (logoutPending) {
            sendEvent(EventCategoryAuthentication, 'logout-started');
            logoutStarted = performance.now();
        }
    });
    reaction(() => store.auth.logoutState && store.auth.logoutState.rejected, logoutRejected => {
        if (logoutRejected) {
            sendEvent(EventCategoryAuthentication, 'logout-failed');
            sendTiming(EventCategoryAuthentication, 'logout-failed', performance.now() - logoutStarted);
        }
    });
    reaction(() => store.auth.logoutState && store.auth.logoutState.fulfilled, logoutFulfilled => {
        if (logoutFulfilled) {
            sendEvent(EventCategoryAuthentication, 'logout-succeeded');
            sendTiming(EventCategoryAuthentication, 'logout-succeeded', performance.now() - logoutStarted);
        }
    });

    let signupStarted:number;

    store.signupEvents.addEventListener("started", () => {
        sendEvent(EventCategorySignup, 'signup-started');
        signupStarted = performance.now();
    });

    store.signupEvents.addEventListener("succeeded", () => {
        sendEvent(EventCategorySignup, 'signup-succeeded');
        sendTiming(EventCategorySignup, 'signup-succeeded', performance.now() - signupStarted);
    });

    store.signupEvents.addEventListener("failed", () => {
        sendEvent(EventCategorySignup, 'signup-failed');
        sendTiming(EventCategorySignup, 'signup-failed', performance.now() - signupStarted);
    });


    let orderSubmitted:number;

    reaction(() => store.cart.orderState && store.cart.orderState.pending, orderPending => {
        if (orderPending) {
            sendEvent(EventCategoryOrder, 'order-submit');
            orderSubmitted = performance.now();
        }
    });
    reaction(() => store.cart.orderState && store.cart.orderState.rejected, orderRejected => {
        if (orderRejected) {
            sendEvent(EventCategoryOrder, 'order-failed');
            sendTiming(EventCategoryOrder, 'order-failed', performance.now() - orderSubmitted);
        }
    });
    reaction(() => store.cart.orderState && store.cart.orderState.fulfilled, orderFulfilled => {
        if (orderFulfilled) {
            sendEvent(EventCategoryOrder, 'order-succeeded');
            sendTiming(EventCategoryOrder, 'order-succeeded', performance.now() - orderSubmitted);
        }
    });
}