import {action, computed, observable} from "mobx";
import {PromiseState, StoreBase} from "og-spa-state";
import {ClesyService} from "../services/ClesyService";
import {AddressStore} from "./AddressStore";
import {AuthStore} from "./AuthStore";
import {CartStore} from "./CartStore";
import {Valid} from "./PasswordChangeStore";
import {PersonStore} from "./PersonStore";


interface UserDataStoreProps {
    phone: string;
    email: string;
}

interface UserDataStoreState {
    state:PromiseState<void>;
}

export class UserDataStore extends StoreBase<UserDataStoreProps, UserDataStoreState> implements Readonly<UserDataStoreProps> {

    constructor(private readonly service: ClesyService, private readonly auth:AuthStore, private readonly cart: CartStore) {
        super();
    }

    @observable
    readonly person: PersonStore = new PersonStore();

    @observable
    readonly address: AddressStore = new AddressStore();

    @observable
    readonly phone: string;

    @observable
    readonly email: string;

    @observable
    readonly state: PromiseState<void>;

    @computed
    get isValid(): string {
        return (this.phone != '' && this.email != '' &&
            this.address.street != '' && this.address.houseNo != '' && this.address.zip != '' && this.address.city != '')
            ? Valid
            : 'userDataStore.requiredFieldMessage';
    }

    @action
    execute() {
        let validity = this.isValid;
        if (validity != Valid) {
            throw new Error(validity);
        }

        const usernameChanged = this.auth.user.username !== this.email;
        const request = {
            userId: this.auth.user?.userId,
            accountId: this.cart.account?.accountId,
            person: this.person,
            address: this.address,
            phone: this.phone,
            email: this.email
        };

        let promise = this.service.changeUserData(request)
            .then(() => {
                if(!usernameChanged) {
                    this.auth.updateUser()
                }else {
                    this.auth.logout();
                }
            });
        this.setState({
            state: new PromiseState(promise)
        });
    }
}
