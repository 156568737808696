import {Address, AddressData} from "./Address";

export interface SiteData {
    siteId:number;
    siteName:string;
    publicSite:boolean;
    cubeSite:boolean;
    cubeHostname:string;
    address:AddressData;
}

export class Site implements SiteData {

    constructor(siteData?:SiteData) {
        if (siteData) {
            Object.assign(this, siteData);

            this.address = siteData.address ? new Address(siteData.address) : null;
        }
    }

    siteId:number;
    siteName:string;
    publicSite:boolean;
    cubeSite:boolean;
    cubeHostname:string;
    address:AddressData;
}