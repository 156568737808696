import * as React from "react";

export namespace Spinner {
    export interface Props {

    }
}

export class Spinner extends React.Component<Spinner.Props, undefined> {
    public render() {
        return (
            <div className="clesy-spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        )

    }
}